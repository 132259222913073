var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./com.kubelt.ipfs.spec.js");require("./com.kubelt.ipfs.util.js");require("./com.kubelt.ipfs.v0.js");
'use strict';var BB=new $CLJS.P("cid","codec","cid/codec",2063818772),CB=new $CLJS.P("cid","data","cid/data",-232767359),DB=new $CLJS.P("cid","multibase","cid/multibase",-1948858294),EB=new $CLJS.P("cid","format","cid/format",-1306959388),FB=new $CLJS.P("cid","version","cid/version",425390812),GB=new $CLJS.P("cid","prefix?","cid/prefix?",-2128580898),HB=new $CLJS.P("cid","numeric?","cid/numeric?",-456132356);$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Convert CIDs to Base32 CID version 1.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/cid/base32",$CLJS.oA,new $CLJS.p(null,1,[$CLJS.wB,new $CLJS.p(null,4,[$CLJS.X,"arg",$CLJS.W,"CIDs to convert.",$CLJS.Y,!0,$CLJS.Z,$CLJS.V],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["CidStr",$CLJS.V],null),new $CLJS.S(null,2,5,
$CLJS.T,["ErrorMsg",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["Formatted",$CLJS.V],null)],null)],null));
$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"List available multibase encodings.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/cid/bases",$CLJS.oA,new $CLJS.p(null,2,[GB,new $CLJS.p(null,4,[$CLJS.X,"prefix",$CLJS.W,"Also include single letter prefixes in addition to the code.",$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null),HB,new $CLJS.p(null,4,[$CLJS.X,"numeric",$CLJS.W,"Also include numeric codes.",$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,
["application/json"],null),$CLJS.aA,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Code",$CLJS.WA],null),new $CLJS.S(null,2,5,$CLJS.T,["Name",$CLJS.XA],null)],null)],null)],null));
$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"List available codecs.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/cid/codecs",$CLJS.oA,new $CLJS.p(null,1,[HB,new $CLJS.p(null,4,[$CLJS.X,"numeric",$CLJS.W,"Also include numeric codes.",$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Code",$CLJS.V],
null),new $CLJS.S(null,2,5,$CLJS.T,["Name",$CLJS.V],null)],null)],null)],null));
$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Format and convert a CID in various useful ways.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/cid/format",$CLJS.oA,new $CLJS.p(null,5,[CB,new $CLJS.p(null,4,[$CLJS.X,"arg",$CLJS.W,"CIDs to format.",$CLJS.Y,!0,$CLJS.Z,$CLJS.V],null),EB,new $CLJS.p(null,4,[$CLJS.X,"f",$CLJS.W,"Printf-style format string. Default: %s.",$CLJS.Y,!1,$CLJS.Z,$CLJS.V],null),FB,new $CLJS.p(null,4,[$CLJS.X,"v",$CLJS.W,"CID version to convert to.",
$CLJS.Y,!1,$CLJS.Z,$CLJS.V],null),BB,new $CLJS.p(null,4,[$CLJS.X,"codec",$CLJS.W,"CID codec to convert to.",$CLJS.Y,!1,$CLJS.Z,$CLJS.V],null),DB,new $CLJS.p(null,4,[$CLJS.X,"b",$CLJS.W,"Multibase to display CID in.",$CLJS.Y,!1,$CLJS.Z,$CLJS.V],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["CidStr",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["ErrorMsg",$CLJS.V],null),new $CLJS.S(null,2,5,
$CLJS.T,["Formatted",$CLJS.V],null)],null)],null));
$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"List available multihashes.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/cid/hashes",$CLJS.oA,new $CLJS.p(null,1,[HB,new $CLJS.p(null,4,[$CLJS.X,"numeric",$CLJS.W,"Also include numeric codes.",$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Code",
$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["Name",$CLJS.V],null)],null)],null)],null));