var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./com.kubelt.ipfs.spec.js");require("./com.kubelt.ipfs.util.js");require("./com.kubelt.ipfs.v0.js");
'use strict';var KE=new $CLJS.P("node","all?","node/all?",1105062308),LE=new $CLJS.P("node","version-only?","node/version-only?",490199558),ME=new $CLJS.P("node","repo?","node/repo?",1366512380),NE=new $CLJS.P("ping","count","ping/count",2144348359),OE=new $CLJS.P("node","commit?","node/commit?",1574468159);$CLJS.PE=$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Show IPFS node id info.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/id",$CLJS.oA,new $CLJS.p(null,3,[$CLJS.yB,new $CLJS.p(null,4,[$CLJS.X,"arg",$CLJS.W,"Peer.ID of node to look up.",$CLJS.Y,!1,$CLJS.Z,$CLJS.V],null),new $CLJS.P("output","format","output/format",2116602407),new $CLJS.p(null,4,[$CLJS.X,"format",$CLJS.W,"Optional output format.",$CLJS.Y,!1,$CLJS.Z,$CLJS.V],null),$CLJS.vB,new $CLJS.p(null,4,[$CLJS.X,
"peerid-base",$CLJS.W,"Encoding used for peer IDs. Can either be a multibase encoded CID or a base58btc encoded multihash. Takes b58mh|base36|k|base32|.... Default: b58mh.",$CLJS.Y,!1,$CLJS.Z,$CLJS.MA],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["ID",$CLJS.BA],null),new $CLJS.S(null,2,5,$CLJS.T,["PublicKey",$CLJS.CA],null),new $CLJS.S(null,2,5,$CLJS.T,["Addresses",$CLJS.DA],null),new $CLJS.S(null,
2,5,$CLJS.T,["AgentVersion",$CLJS.EA],null),new $CLJS.S(null,2,5,$CLJS.T,["ProtocolVersion",$CLJS.FA],null),new $CLJS.S(null,2,5,$CLJS.T,["Protocols",$CLJS.GA],null)],null)],null));
$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Send echo request packets to IPFS hosts.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/id",$CLJS.oA,new $CLJS.p(null,2,[$CLJS.yB,new $CLJS.p(null,4,[$CLJS.X,"arg",$CLJS.W,"Peer ID of peer to be pinged.",$CLJS.Y,!0,$CLJS.Z,$CLJS.BA],null),NE,new $CLJS.p(null,4,[$CLJS.X,"count",$CLJS.W,"Number of ping messages to send. Default: 10.",$CLJS.Y,!1,$CLJS.Z,$CLJS.Sj],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],
null),$CLJS.aA,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Success",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["Text",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["Addresses",$CLJS.V],null)],null)],null));
$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Show IPFS version information.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/version",$CLJS.oA,new $CLJS.p(null,4,[LE,new $CLJS.p(null,4,[$CLJS.X,"number",$CLJS.W,"Only show the version number.",$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null),OE,new $CLJS.p(null,4,[$CLJS.X,"commit",$CLJS.W,"Show the commit hash.",$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null),ME,new $CLJS.p(null,4,[$CLJS.X,"repo",$CLJS.W,"Show repo version.",$CLJS.Y,!1,$CLJS.Z,
$CLJS.jj],null),KE,new $CLJS.p(null,4,[$CLJS.X,"all",$CLJS.W,"Show all version information",$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Commit",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["Golang",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["Repo",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["System",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["Version",
$CLJS.V],null)],null)],null));
$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Show information about dependencies used for build.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/version/deps",$CLJS.oA,$CLJS.R,$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Path",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["ReplacedBy",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["Sum",$CLJS.V],null),new $CLJS.S(null,2,5,
$CLJS.T,["Version",$CLJS.V],null)],null)],null));