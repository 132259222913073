var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./clojure.walk.js");require("./cljs.spec.gen.alpha.js");require("./clojure.string.js");
'use strict';var yv=function(a,b){a.sort(b||$CLJS.Qb)},zv=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Qb;yv(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value},Av=function(a){return $CLJS.ee.h(a,$CLJS.Je)?$CLJS.Je:function(b,c){var d=a.h?a.h(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.q(d)?-1:$CLJS.q(a.h?a.h(c,b):a.call(null,c,b))?1:0}},Bv=function(){return function(){function a(g,
h,l){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vu.j?$CLJS.Vu.j(g,h,l):$CLJS.Vu.call(null,g,h,l),$CLJS.me.j?$CLJS.me.j(g,h,l):$CLJS.me.call(null,g,h,l)],null)}function b(g,h){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vu.h?$CLJS.Vu.h(g,h):$CLJS.Vu.call(null,g,h),$CLJS.me.h?$CLJS.me.h(g,h):$CLJS.me.call(null,g,h)],null)}function c(g){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vu.g?$CLJS.Vu.g(g):$CLJS.Vu.call(null,g),$CLJS.me.g?$CLJS.me.g(g):$CLJS.me.call(null,g)],null)}function d(){return new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.Vu.v?$CLJS.Vu.v():$CLJS.Vu.call(null),$CLJS.me.v?$CLJS.me.v():$CLJS.me.call(null)],null)}var e=null,f=function(){function g(l,m,r,t){var u=null;if(3<arguments.length){u=0;for(var x=Array(arguments.length-3);u<x.length;)x[u]=arguments[u+3],++u;u=new $CLJS.Jd(x,0,null)}return h.call(this,l,m,r,u)}function h(l,m,r,t){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ff($CLJS.Vu,l,m,r,t),$CLJS.Ff($CLJS.me,l,m,r,t)],null)}g.I=3;g.N=function(l){var m=$CLJS.H(l);l=$CLJS.I(l);var r=$CLJS.H(l);
l=$CLJS.I(l);var t=$CLJS.H(l);l=$CLJS.Nd(l);return h(m,r,t,l)};g.A=h;return g}();e=function(g,h,l,m){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,g);case 2:return b.call(this,g,h);case 3:return a.call(this,g,h,l);default:var r=null;if(3<arguments.length){r=0;for(var t=Array(arguments.length-3);r<t.length;)t[r]=arguments[r+3],++r;r=new $CLJS.Jd(t,0,null)}return f.A(g,h,l,r)}throw Error("Invalid arity: "+arguments.length);};e.I=3;e.N=f.N;e.v=d;e.g=c;e.h=b;e.j=a;e.A=
f.A;return e}()},Dv=function(a){var b=$CLJS.Aa(Cv);if($CLJS.G(b)){var c=$CLJS.uf.g?$CLJS.uf.g(b):$CLJS.uf.call(null,b);zv(c,Av(a));return $CLJS.se($CLJS.G(c),$CLJS.te(b))}return $CLJS.Md},Fv=function(){$CLJS.q(Ev)||(Ev=function(){for(var a=Dv(function(e,f){return f.length-e.length}),b="";;)if($CLJS.G(a)){var c=$CLJS.I(a),d=[function(){var e=b;return""!==b?[e,"|"].join(""):e}(),$CLJS.v.g($CLJS.H(a))].join("");a=c;b=d}else return[b,"|\\$"].join("")}());return Ev},Gv=function(a){var b=$CLJS.v.g(a);if("_DOT__DOT_"===
b)var c="..";else a:{c=new RegExp(Fv(),"g");var d=b.length-1;d=0<=d&&b.indexOf("$",d)==d;b=$CLJS.q(d)?b.substring(0,b.length-1):b;d="";for(var e=0;;){var f=c.exec(b);if($CLJS.q(f))f=$CLJS.M(f,0,null),d=[d,$CLJS.v.g(b.substring(e,c.lastIndex-f.length)),$CLJS.v.g("$"===f?"/":$CLJS.Pb(Cv,f))].join(""),e=c.lastIndex;else{c=[d,$CLJS.v.g(b.substring(e,b.length))].join("");break a}}}a=a instanceof $CLJS.F?$CLJS.Ei:$CLJS.v;return a.g?a.g(c):a.call(null,c)},Hv=function(a,b){if(null!=a&&null!=a.Bf)a=a.Bf(a,
b);else{var c=Hv[$CLJS.n(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=Hv._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.gc("Spec.with-gen*",a);}return a},Jv=function(a){if($CLJS.kf(a)){var b=$CLJS.w(Iv);a=$CLJS.N.h(b,a);if($CLJS.kf(a))a:for(;;)if($CLJS.kf(a))a=$CLJS.N.h(b,a);else{b=a;break a}else b=a;return b}return a},Kv=function(a){if($CLJS.kf(a)){var b=Jv(a);if($CLJS.q(b))return b;throw Error(["Unable to resolve spec: ",$CLJS.v.g(a)].join(""));}return a},Lv=
function(a){return null!=a&&$CLJS.C===a.yg?a:null},Nv=function(a){var b=Mv.g(a);return $CLJS.q(b)?a:b},Pv=function(a,b){return $CLJS.kf(a)?a:$CLJS.q(Nv(a))?$CLJS.Bi.j(a,Ov,b):null!=a&&(a.B&131072||$CLJS.C===a.zf)?$CLJS.se(a,$CLJS.Bi.j($CLJS.te(a),Ov,b)):null},Qv=function(a){return $CLJS.kf(a)?a:$CLJS.q(Nv(a))?Ov.g(a):null!=a&&(a.B&131072||$CLJS.C===a.zf)?Ov.g($CLJS.te(a)):null},Sv=function(a){var b=function(){var c=(c=$CLJS.kf(a))?Jv(a):c;if($CLJS.q(c))return c;c=Lv(a);if($CLJS.q(c))return c;c=Nv(a);
return $CLJS.q(c)?c:null}();return $CLJS.q(Nv(b))?Pv(Rv(b,null),Qv(b)):b},Tv=function(a){var b=Sv(a);if($CLJS.q(b))return b;if($CLJS.kf(a))throw Error(["Unable to resolve spec: ",$CLJS.v.g(a)].join(""));return null},Uv=function(a){if($CLJS.Ob(null==a?"":String(a)))return null;a=$CLJS.wi.h(Gv,$CLJS.av(a,"$"));if(2<=$CLJS.J(a)&&$CLJS.cg(function(c){return!$CLJS.Ob(null==c?"":String(c))},a)){var b=Bv()(a);a=$CLJS.M(b,0,null);b=$CLJS.M(b,1,null);return $CLJS.Ei.g([$CLJS.Xu(".",a),"/",$CLJS.v.g(b)].join(""))}return null},
Xv=function(a,b){a=Jv(a);return $CLJS.q(Nv(a))?$CLJS.Bi.j(a,Vv,b):Hv(Wv.g(a),b)},Zv=function(a,b){return Yv(a,b,null,null,null)},Yv=function(a,b,c,d,e){if($CLJS.q(Lv(b)))return $CLJS.q(c)?Xv(b,c):b;if($CLJS.q(Nv(b)))return Rv(b,c);if($CLJS.kf(b))return a=Tv(b),$CLJS.q(c)?Xv(a,c):a;if("undefined"===typeof $CLJS.Nf||"undefined"===typeof $v||"undefined"===typeof aw||"undefined"===typeof bw)bw=function(f,g,h,l,m,r){this.form=f;this.cc=g;this.If=h;this.Ag=l;this.ah=m;this.Ei=r;this.B=393216;this.F=0},
bw.prototype.K=function(f,g){return new bw(this.form,this.cc,this.If,this.Ag,this.ah,g)},bw.prototype.J=function(){return this.Ei},bw.prototype.Oc=function(){return this},bw.prototype.Pc=function(){return this},bw.prototype.yg=$CLJS.C,bw.prototype.Bf=function(f,g){return Yv(this.form,this.cc,g,this.Ag,this.ah)},bw.W=!0,bw.V="cljs.spec.alpha/t_cljs$spec$alpha22638",bw.X=function(f){return $CLJS.ad(f,"cljs.spec.alpha/t_cljs$spec$alpha22638")};return new bw(a,b,c,d,e,$CLJS.R)},Cv={_RBRACE_:"}",_COLON_:":",
_BANG_:"!",_QMARK_:"?",_BSLASH_:"\\\\",_SLASH_:"/",_PERCENT_:"%",_PLUS_:"+",_SHARP_:"#",_LBRACE_:"{",_BAR_:"|",_LBRACK_:"[",_EQ_:"\x3d",_:"-",_TILDE_:"~",_RBRACK_:"]",_GT_:"\x3e",_SINGLEQUOTE_:"'",_CIRCA_:"@",_AMPERSAND_:"\x26",_DOUBLEQUOTE_:'\\"',_CARET_:"^",_LT_:"\x3c",_STAR_:"*"},Ev=null,$v={},cw=new $CLJS.F(null,"p1__24920#","p1__24920#",131093219,null),Ov=new $CLJS.P("cljs.spec.alpha","name","cljs.spec.alpha/name",205233570),dw=new $CLJS.P("cljs.spec.alpha","v","cljs.spec.alpha/v",552625740),
ew=new $CLJS.P("cljs.spec.alpha","k","cljs.spec.alpha/k",-1602615178),fw=new $CLJS.F(null,"%","%",-950237169,null),gw=new $CLJS.F("cljs.core","zipmap","cljs.core/zipmap",-1902130674,null),hw=new $CLJS.P("cljs.spec.alpha","kvs-\x3emap","cljs.spec.alpha/kvs-\x3emap",579713455),iw=new $CLJS.F(null,"p1__24919#","p1__24919#",-648146893,null),jw=new $CLJS.F("cljs.core","map","cljs.core/map",-338988913,null),kw=new $CLJS.P("cljs.spec.alpha","unknown","cljs.spec.alpha/unknown",651034818),Mv=new $CLJS.P("cljs.spec.alpha",
"op","cljs.spec.alpha/op",-1269055252),lw=new $CLJS.F("cljs.spec.alpha","conformer","cljs.spec.alpha/conformer",2140085535,null),Vv=new $CLJS.P("cljs.spec.alpha","gfn","cljs.spec.alpha/gfn",-593120375);var aw={},bw,mw,Iv=$CLJS.hg($CLJS.R),ow=function(){function a(d,e){var f=nw[$CLJS.n(null==d?null:d)];if(null!=f)return f.h?f.h(d,e):f.call(null,d,e);f=nw._;if(null!=f)return f.h?f.h(d,e):f.call(null,d,e);throw $CLJS.gc("Specize.specize*",d);}function b(d){var e=nw[$CLJS.n(null==d?null:d)];if(null!=e)return e.g?e.g(d):e.call(null,d);e=nw._;if(null!=e)return e.g?e.g(d):e.call(null,d);throw $CLJS.gc("Specize.specize*",d);}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}(),nw=function nw(a){switch(arguments.length){case 1:return nw.g(arguments[0]);case 2:return nw.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.v.g(arguments.length)].join(""));}};nw.g=function(a){return null!=a&&null!=a.Oc?a.Oc(a):ow(a)};nw.h=function(a,b){return null!=a&&null!=a.Pc?a.Pc(a,b):ow(a,b)};nw.I=2;$CLJS.P.prototype.Oc=function(){return nw(Kv(this))};
$CLJS.P.prototype.Pc=function(){return nw(Kv(this))};$CLJS.F.prototype.Oc=function(){return nw(Kv(this))};$CLJS.F.prototype.Pc=function(){return nw(Kv(this))};$CLJS.bi.prototype.Oc=function(){return Zv(this,this)};$CLJS.bi.prototype.Pc=function(a,b){return Zv(b,this)};$CLJS.fi.prototype.Oc=function(){return Zv(this,this)};$CLJS.fi.prototype.Pc=function(a,b){return Zv(b,this)};
nw._=function(){function a(c){var d=(d=$CLJS.qe(c))?Uv(c.name):d;return $CLJS.q(d)?Zv(d,c):Zv(kw,c)}var b=null;b=function(c,d){switch(arguments.length){case 1:return a.call(this,c);case 2:return Zv(d,c)}throw Error("Invalid arity: "+arguments.length);};b.g=a;b.h=function(c,d){return Zv(d,c)};return b}();
var Wv=function Wv(a){switch(arguments.length){case 1:return Wv.g(arguments[0]);case 2:return Wv.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.v.g(arguments.length)].join(""));}};Wv.g=function(a){var b=Lv(a);return $CLJS.q(b)?b:nw(a)};Wv.h=function(a,b){var c=Lv(a);return $CLJS.q(c)?c:nw(a,b)};Wv.I=2;
var Rv=function Rv(a,b){if("undefined"===typeof $CLJS.Nf||"undefined"===typeof $v||"undefined"===typeof aw||"undefined"===typeof mw)mw=function(d,e,f){this.Yc=d;this.If=e;this.Fi=f;this.B=393216;this.F=0},mw.prototype.K=function(d,e){return new mw(this.Yc,this.If,e)},mw.prototype.J=function(){return this.Fi},mw.prototype.Oc=function(){return this},mw.prototype.Pc=function(){return this},mw.prototype.yg=$CLJS.C,mw.prototype.Bf=function(d,e){return Rv.h?Rv.h(this.Yc,e):Rv.call(null,this.Yc,e)},mw.W=
!0,mw.V="cljs.spec.alpha/t_cljs$spec$alpha24848",mw.X=function(d){return $CLJS.ad(d,"cljs.spec.alpha/t_cljs$spec$alpha24848")};return new mw(a,b,$CLJS.R)},pw=$CLJS.Wl(lw,$CLJS.Wl($CLJS.uk,new $CLJS.S(null,1,5,$CLJS.T,[iw],null),$CLJS.Wl(gw,$CLJS.Wl(jw,ew,iw),$CLJS.Wl(jw,dw,iw))),$CLJS.Wl($CLJS.uk,new $CLJS.S(null,1,5,$CLJS.T,[cw],null),$CLJS.Wl(jw,$CLJS.Wl($CLJS.xk,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wj,$CLJS.vj],null)],null),new $CLJS.p(null,2,[ew,$CLJS.wj,dw,$CLJS.vj],
null)),cw))),qw=Yv($CLJS.Wl(lw,$CLJS.Wl($CLJS.xk,new $CLJS.S(null,1,5,$CLJS.T,[fw],null),$CLJS.Wl(gw,$CLJS.Wl(jw,ew,fw),$CLJS.Wl(jw,dw,fw))),$CLJS.Wl($CLJS.xk,new $CLJS.S(null,1,5,$CLJS.T,[fw],null),$CLJS.Wl(jw,$CLJS.Wl($CLJS.xk,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wj,$CLJS.vj],null)],null),new $CLJS.p(null,2,[ew,$CLJS.wj,dw,$CLJS.vj],null)),fw))),function(a){return $CLJS.qv($CLJS.wi.h(ew,a),$CLJS.wi.h(dw,a))},null,!0,function(a){return $CLJS.wi.h(function(b){var c=$CLJS.M(b,
0,null);b=$CLJS.M(b,1,null);return new $CLJS.p(null,2,[ew,c,dw,b],null)},a)});if(null==qw)$CLJS.Ki.j(Iv,$CLJS.Vl,hw);else{var rw;var sw=Lv(qw);if($CLJS.q(sw))rw=sw;else{var tw=Nv(qw);rw=$CLJS.q(tw)?tw:$CLJS.N.h($CLJS.w(Iv),qw)}var uw=$CLJS.q(rw)?qw:Zv(pw,qw);$CLJS.Ki.R(Iv,$CLJS.Bi,hw,Pv(uw,hw))};