var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./clojure.walk.js");require("./clojure.set.js");require("./cljs.spec.alpha.js");require("./clojure.string.js");require("./weavejester.dependency.js");
'use strict';var vw,xw,zw,ww;vw=function(a){return a+1};$CLJS.Pw=function(a,b,c,d){this.G=0;this.uj=a;this.og=b;this.ji=c;this.Cg=d};xw=function(a){null==ww&&(ww=$CLJS.hg(0));return $CLJS.Ei.g([$CLJS.v.g(a),$CLJS.v.g($CLJS.Ki.h(ww,vw))].join(""))};zw=function(a){return $CLJS.Be(a)?yw(a):a};ww=null;$CLJS.Pw.prototype.na=function(){var a=this.G<this.og;return a?a:this.Cg.na()};
$CLJS.Pw.prototype.next=function(){if(this.G<this.og){var a=$CLJS.fe(this.ji,this.G);this.G+=1;return new $CLJS.$g(a,$CLJS.Nl(this.uj,a),null)}return this.Cg.next()};$CLJS.Pw.prototype.remove=function(){return Error("Unsupported operation")};var Aw=function Aw(a){switch(arguments.length){case 2:return Aw.h(arguments[0],arguments[1]);case 3:return Aw.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.v.g(arguments.length)].join(""));}};
Aw.h=function(a,b){a=$CLJS.ke([a,b]);$CLJS.Ef($CLJS.Ki,$CLJS.Qi(),Aw,a);return null};
Aw.j=function(a,b,c){function d(h,l,m,r,t){return $CLJS.jc.j(function(u,x){return $CLJS.Bi.j(u,x,$CLJS.jc.j($CLJS.O,$CLJS.N.j(t,x,$CLJS.ci),$CLJS.ef(r,t.g?t.g(r):t.call(null,r))))},h,$CLJS.ef(l,m.g?m.g(l):m.call(null,l)))}var e=$CLJS.Ni.g(a),f=$CLJS.Oi.g(a),g=$CLJS.Pi.g(a);if($CLJS.Ie(e.g?e.g(b):e.call(null,b),c))b=null;else{if($CLJS.Ie(g.g?g.g(b):g.call(null,b),c))throw Error([$CLJS.v.g(b),"already has",$CLJS.v.g(c),"as ancestor"].join(""));if($CLJS.Ie(g.g?g.g(c):g.call(null,c),b))throw Error(["Cyclic derivation:",
$CLJS.v.g(c),"has",$CLJS.v.g(b),"as ancestor"].join(""));b=new $CLJS.p(null,3,[$CLJS.Ni,$CLJS.Bi.j($CLJS.Ni.g(a),b,$CLJS.O.h($CLJS.N.j(e,b,$CLJS.ci),c)),$CLJS.Pi,d($CLJS.Pi.g(a),b,f,c,g),$CLJS.Oi,d($CLJS.Oi.g(a),c,g,b,f)],null)}return $CLJS.q(b)?b:a};Aw.I=3;var yw=$CLJS.Li(function(a){var b=[$CLJS.Xu("+",function(){return function m(l){return new $CLJS.mf(null,function(){for(;;){var r=$CLJS.G(l);if(r){if($CLJS.Ce(r)){var t=$CLJS.kd(r),u=$CLJS.J(t),x=$CLJS.pf(u);a:for(var y=0;;)if(y<u){var z=$CLJS.oe(t,y);z=[$CLJS.jf(z),".",$CLJS.hi(z)].join("");x.add(z);y+=1}else{t=!0;break a}return t?$CLJS.sf(x.Ra(),m($CLJS.ld(r))):$CLJS.sf(x.Ra(),null)}x=$CLJS.H(r);return $CLJS.ef([$CLJS.jf(x),".",$CLJS.hi(x)].join(""),m($CLJS.Nd(r)))}return null}},null,null)}(a)}()),
"_"].join("");b=$CLJS.xi.h("integrant.composite",$CLJS.v.g(xw(b)));for(var c=$CLJS.G(a),d=null,e=0,f=0;;)if(f<e){var g=d.ha(null,f);Aw.h(b,g);f+=1}else if(c=$CLJS.G(c))d=c,$CLJS.Ce(d)?(c=$CLJS.kd(d),f=$CLJS.ld(d),d=c,e=$CLJS.J(c),c=f):(c=$CLJS.H(d),Aw.h(b,c),c=$CLJS.I(d),d=null,e=0),f=0;else break;return b});
$CLJS.Zi(function(){var a=$CLJS.hg($CLJS.R),b=$CLJS.hg($CLJS.R),c=$CLJS.hg($CLJS.R),d=$CLJS.hg($CLJS.R),e=$CLJS.N.j($CLJS.R,$CLJS.Xj,$CLJS.Qi.v?$CLJS.Qi.v():$CLJS.Qi.call(null));return new $CLJS.Yi($CLJS.Ei.h("integrant.core","resolve-key"),function(f){return zw(f)},e,a,b,c,d)}(),$CLJS.Xi,function(a,b){return b});
$CLJS.Zi(function(){var a=$CLJS.hg($CLJS.R),b=$CLJS.hg($CLJS.R),c=$CLJS.hg($CLJS.R),d=$CLJS.hg($CLJS.R),e=$CLJS.N.j($CLJS.R,$CLJS.Xj,$CLJS.Qi.v?$CLJS.Qi.v():$CLJS.Qi.call(null));return new $CLJS.Yi($CLJS.Ei.h("integrant.core","prep-key"),function(f){return zw(f)},e,a,b,c,d)}(),$CLJS.Xi,function(a,b){return b});
$CLJS.Bw=function(){var a=$CLJS.hg($CLJS.R),b=$CLJS.hg($CLJS.R),c=$CLJS.hg($CLJS.R),d=$CLJS.hg($CLJS.R),e=$CLJS.N.j($CLJS.R,$CLJS.Xj,$CLJS.Qi.v?$CLJS.Qi.v():$CLJS.Qi.call(null));return new $CLJS.Yi($CLJS.Ei.h("integrant.core","init-key"),function(f){return zw(f)},e,a,b,c,d)}();
$CLJS.Cw=function(){var a=$CLJS.hg($CLJS.R),b=$CLJS.hg($CLJS.R),c=$CLJS.hg($CLJS.R),d=$CLJS.hg($CLJS.R),e=$CLJS.N.j($CLJS.R,$CLJS.Xj,$CLJS.Qi.v?$CLJS.Qi.v():$CLJS.Qi.call(null));return new $CLJS.Yi($CLJS.Ei.h("integrant.core","halt-key!"),function(f){return zw(f)},e,a,b,c,d)}();$CLJS.Zi($CLJS.Cw,$CLJS.Xi,function(){return null});
$CLJS.Zi(function(){var a=$CLJS.hg($CLJS.R),b=$CLJS.hg($CLJS.R),c=$CLJS.hg($CLJS.R),d=$CLJS.hg($CLJS.R),e=$CLJS.N.j($CLJS.R,$CLJS.Xj,$CLJS.Qi.v?$CLJS.Qi.v():$CLJS.Qi.call(null));return new $CLJS.Yi($CLJS.Ei.h("integrant.core","resume-key"),function(f){return zw(f)},e,a,b,c,d)}(),$CLJS.Xi,function(a,b){return $CLJS.Bw.h(a,b)});
$CLJS.Zi(function(){var a=$CLJS.hg($CLJS.R),b=$CLJS.hg($CLJS.R),c=$CLJS.hg($CLJS.R),d=$CLJS.hg($CLJS.R),e=$CLJS.N.j($CLJS.R,$CLJS.Xj,$CLJS.Qi.v?$CLJS.Qi.v():$CLJS.Qi.call(null));return new $CLJS.Yi($CLJS.Ei.h("integrant.core","suspend-key!"),function(f){return zw(f)},e,a,b,c,d)}(),$CLJS.Xi,function(a,b){return $CLJS.Cw.h(a,b)});var Dw,Ew=$CLJS.hg($CLJS.R),Fw=$CLJS.hg($CLJS.R),Gw=$CLJS.hg($CLJS.R),Hw=$CLJS.hg($CLJS.R),Iw=$CLJS.N.j($CLJS.R,$CLJS.Xj,$CLJS.Qi.v?$CLJS.Qi.v():$CLJS.Qi.call(null));
Dw=new $CLJS.Yi($CLJS.Ei.h("integrant.core","pre-init-spec"),zw,Iw,Ew,Fw,Gw,Hw);$CLJS.Zi(Dw,$CLJS.Xi,function(){return null});