var window=global;var $CLJS=require("./cljs_env");
/*

 Copyright The Closure Library Authors.
 SPDX-License-Identifier: Apache-2.0
*/
'use strict';var ea,la,ya,Ca,Ga,Ha,Ia,Na,Oa,Pa,Ra,Ta,Ua,Va,Xa,$a,cb,Wa,hb,ib,eb,gb,ab,Ya,mb,nb,ob,aa,fa,ha;$CLJS.ba=function(a){return function(){return aa[a].apply(this,arguments)}};$CLJS.ca=function(a,b){return aa[a]=b};$CLJS.da=function(){};$CLJS.n=function(a){var b=typeof a;return"object"!=b?b:a?Array.isArray(a)?"array":b:"null"};ea=function(a){var b=typeof a;return"object"==b&&null!=a||"function"==b};$CLJS.ia=function(a){return Object.prototype.hasOwnProperty.call(a,fa)&&a[fa]||(a[fa]=++ha)};
$CLJS.ka=function(a,b){function c(){}c.prototype=b.prototype;a.Zd=b.prototype;a.prototype=new c;a.prototype.constructor=a;a.de=function(d,e,f){for(var g=Array(arguments.length-2),h=2;h<arguments.length;h++)g[h-2]=arguments[h];return b.prototype[e].apply(d,g)}};la=function(a){la[" "](a);return a};$CLJS.na=function(a,b){return new $CLJS.ma(a,b)};
$CLJS.sa=function(a){return 0<a?0x7fffffffffffffff<=a?oa:new $CLJS.ma(a,a/4294967296):0>a?-0x7fffffffffffffff>=a?pa:$CLJS.qa(new $CLJS.ma(-a,-a/4294967296)):$CLJS.ra};$CLJS.qa=function(a){var b=~a.ya+1|0;return $CLJS.na(b,~a.ja+!b|0)};$CLJS.ta=function(a){return 4294967296*a.ja+(a.ya>>>0)};
ya=function(a,b){if($CLJS.ua(b))throw Error("division by zero");if(0>a.ja){if($CLJS.va(a,pa)){if($CLJS.va(b,wa)||$CLJS.va(b,xa))return pa;if($CLJS.va(b,pa))return wa;var c=1;if(0==c)c=a;else{var d=a.ja;c=32>c?$CLJS.na(a.ya>>>c|d<<32-c,d>>c):$CLJS.na(d>>c-32,0<=d?0:-1)}c=ya(c,b).shiftLeft(1);if($CLJS.va(c,$CLJS.ra))return 0>b.ja?wa:xa;d=b.multiply(c);a=a.add($CLJS.qa(d));return c.add(ya(a,b))}return 0>b.ja?ya($CLJS.qa(a),$CLJS.qa(b)):$CLJS.qa(ya($CLJS.qa(a),b))}if($CLJS.ua(a))return $CLJS.ra;if(0>
b.ja)return $CLJS.va(b,pa)?$CLJS.ra:$CLJS.qa(ya(a,$CLJS.qa(b)));for(d=$CLJS.ra;0<=a.compare(b);){c=Math.max(1,Math.floor($CLJS.ta(a)/$CLJS.ta(b)));var e=Math.ceil(Math.log(c)/Math.LN2);e=48>=e?1:Math.pow(2,e-48);for(var f=$CLJS.sa(c),g=f.multiply(b);0>g.ja||0<g.compare(a);)c-=e,f=$CLJS.sa(c),g=f.multiply(b);$CLJS.ua(f)&&(f=wa);d=d.add(f);a=a.add($CLJS.qa(g))}return d};$CLJS.ua=function(a){return 0==a.ya&&0==a.ja};$CLJS.va=function(a,b){return a.ya==b.ya&&a.ja==b.ja};
$CLJS.Aa=function(a){const b=[];let c=0;for(const d in a)b[c++]=d;return b};Ca=function(a,b){let c,d;for(let e=1;e<arguments.length;e++){d=arguments[e];for(c in d)a[c]=d[c];for(let f=0;f<Ba.length;f++)c=Ba[f],Object.prototype.hasOwnProperty.call(d,c)&&(a[c]=d[c])}};$CLJS.Ea=function(a,b){b=(0,$CLJS.Da)(a,b);let c;(c=0<=b)&&Array.prototype.splice.call(a,b,1);return c};Ga=function(a){return-1!=Fa.indexOf(a)};Ha=function(){return Ga("Firefox")||Ga("FxiOS")};
Ia=function(){return(Ga("Chrome")||Ga("CriOS"))&&!Ga("Edge")};$CLJS.Ja=function(a,b){const c=Array.prototype.slice.call(arguments),d=c.shift();if("undefined"==typeof d)throw Error("[goog.string.format] Template required");return d.replace(/%([0\- \+]*)(\d+)?(\.(\d+))?([%sfdiu])/g,function(e,f,g,h,l,m,r,t){if("%"==m)return"%";const u=c.shift();if("undefined"==typeof u)throw Error("[goog.string.format] Not enough arguments");arguments[0]=u;return $CLJS.Ja.tc[m].apply(null,arguments)})};
$CLJS.Ma=function(){0!=$CLJS.Ka&&($CLJS.La[$CLJS.ia(this)]=this);this.Ed=this.Ed;this.sd=this.sd};Na=function(a,b){this.type=a;this.currentTarget=this.target=b;this.defaultPrevented=this.Vd=!1};Oa=function(){return Ga("iPhone")&&!Ga("iPod")&&!Ga("iPad")};
Pa=function(a,b){Na.call(this,a?a.type:"");this.relatedTarget=this.currentTarget=this.target=null;this.button=this.screenY=this.screenX=this.clientY=this.clientX=this.offsetY=this.offsetX=0;this.key="";this.charCode=this.keyCode=0;this.metaKey=this.shiftKey=this.altKey=this.ctrlKey=!1;this.state=null;this.pointerId=0;this.pointerType="";this.Fd=null;a&&this.init(a,b)};
Ra=function(a,b,c,d,e){this.listener=a;this.proxy=null;this.src=b;this.type=c;this.capture=!!d;this.Gc=e;this.key=++Qa;this.Xd=this.fe=!1};$CLJS.Sa=function(a){a.Xd=!0;a.listener=null;a.proxy=null;a.src=null;a.Gc=null};Ta=function(a){this.src=a;this.Jb={};this.$d=0};Ua=function(a,b){var c=b.type;c in a.Jb&&$CLJS.Ea(a.Jb[c],b)&&($CLJS.Sa(b),0==a.Jb[c].length&&(delete a.Jb[c],a.$d--))};Va=function(a,b,c,d){for(var e=0;e<a.length;++e){var f=a[e];if(!f.Xd&&f.listener==b&&f.capture==!!c&&f.Gc==d)return e}return-1};
Xa=function(a,b,c,d,e){if(d&&d.once)Wa(a,b,c,d,e);else if(Array.isArray(b))for(var f=0;f<b.length;f++)Xa(a,b[f],c,d,e);else c=Ya(c),a&&a[Za]?a.Wb.add(String(b),c,!1,ea(d)?!!d.capture:!!d,e):$a(a,b,c,!1,d,e)};
$a=function(a,b,c,d,e,f){if(!b)throw Error("Invalid event type");var g=ea(e)?!!e.capture:!!e,h=ab(a);h||(a[bb]=h=new Ta(a));c=h.add(b,c,d,g,f);if(!c.proxy){d=cb();c.proxy=d;d.src=a;d.listener=c;if(a.addEventListener)db||(e=g),void 0===e&&(e=!1),a.addEventListener(b.toString(),d,e);else if(a.attachEvent)a.attachEvent(eb(b.toString()),d);else if(a.addListener&&a.removeListener)a.addListener(d);else throw Error("addEventListener and attachEvent are unavailable.");fb++}};
cb=function(){function a(c){return b.call(a.src,a.listener,c)}const b=gb;return a};Wa=function(a,b,c,d,e){if(Array.isArray(b))for(var f=0;f<b.length;f++)Wa(a,b[f],c,d,e);else c=Ya(c),a&&a[Za]?a.Wb.add(String(b),c,!0,ea(d)?!!d.capture:!!d,e):$a(a,b,c,!0,d,e)};hb=function(a,b,c,d,e){if(Array.isArray(b))for(var f=0;f<b.length;f++)hb(a,b[f],c,d,e);else d=ea(d)?!!d.capture:!!d,c=Ya(c),a&&a[Za]?a.Wb.remove(String(b),c,d,e):a&&(a=ab(a))&&(b=a.Hf(b,c,d,e))&&ib(b)};
ib=function(a){if("number"!==typeof a&&a&&!a.Xd){var b=a.src;if(b&&b[Za])Ua(b.Wb,a);else{var c=a.type,d=a.proxy;b.removeEventListener?b.removeEventListener(c,d,a.capture):b.detachEvent?b.detachEvent(eb(c),d):b.addListener&&b.removeListener&&b.removeListener(d);fb--;(c=ab(b))?(Ua(c,a),0==c.$d&&(c.src=null,b[bb]=null)):$CLJS.Sa(a)}}};eb=function(a){return a in jb?jb[a]:jb[a]="on"+a};gb=function(a,b){if(a.Xd)a=!0;else{b=new Pa(b,this);var c=a.listener,d=a.Gc||a.src;a.fe&&ib(a);a=c.call(d,b)}return a};
ab=function(a){a=a[bb];return a instanceof Ta?a:null};Ya=function(a){if("function"===typeof a)return a;a[kb]||(a[kb]=function(b){return a.handleEvent(b)});return a[kb]};$CLJS.lb=function(){$CLJS.Ma.call(this);this.Wb=new Ta(this);this.Eh=this;this.Uf=null};mb=function(a,b,c,d){b=a.Wb.Jb[String(b)];if(!b)return!0;b=b.concat();for(var e=!0,f=0;f<b.length;++f){var g=b[f];if(g&&!g.Xd&&g.capture==c){var h=g.listener,l=g.Gc||g.src;g.fe&&Ua(a.Wb,g);e=!1!==h.call(l,d)&&e}}return e&&!d.defaultPrevented};
nb=function(){};ob=function(){};aa=[];$CLJS.pb=$CLJS.pb||{};$CLJS.qb=global;fa="closure_uid_"+(1E9*Math.random()>>>0);ha=0;$CLJS.qb=global;la[" "]=$CLJS.da;var wa,xa,oa,pa;
$CLJS.ma=class{constructor(a,b){this.ya=a|0;this.ja=b|0}isSafeInteger(){var a=this.ja>>21;return 0==a||-1==a&&!(0==this.ya&&-2097152==this.ja)}toString(a){a=a||10;if(2>a||36<a)throw Error("radix out of range: "+a);if(this.isSafeInteger()){var b=$CLJS.ta(this);return 10==a?""+b:b.toString(a)}b=14-(a>>2);var c=Math.pow(a,b),d=$CLJS.na(c,c/4294967296);c=ya(this,d);var e=Math,f=e.abs;d=c.multiply(d);d=this.add($CLJS.qa(d));e=f.call(e,$CLJS.ta(d));f=10==a?""+e:e.toString(a);f.length<b&&(f="0000000000000".substr(f.length-
b)+f);e=$CLJS.ta(c);return(10==a?e:e.toString(a))+f}compare(a){return this.ja==a.ja?this.ya==a.ya?0:this.ya>>>0>a.ya>>>0?1:-1:this.ja>a.ja?1:-1}add(a){var b=this.ja>>>16,c=this.ja&65535,d=this.ya>>>16,e=a.ja>>>16,f=a.ja&65535,g=a.ya>>>16;a=(this.ya&65535)+(a.ya&65535);g=(a>>>16)+(d+g);d=g>>>16;d+=c+f;b=(d>>>16)+(b+e)&65535;return $CLJS.na((g&65535)<<16|a&65535,b<<16|d&65535)}multiply(a){if($CLJS.ua(this))return this;if($CLJS.ua(a))return a;var b=this.ja>>>16,c=this.ja&65535,d=this.ya>>>16,e=this.ya&
65535,f=a.ja>>>16,g=a.ja&65535,h=a.ya>>>16;a=a.ya&65535;var l=e*a;var m=(l>>>16)+d*a;var r=m>>>16;m=(m&65535)+e*h;r+=m>>>16;r+=c*a;var t=r>>>16;r=(r&65535)+d*h;t+=r>>>16;r=(r&65535)+e*g;t=t+(r>>>16)+(b*a+c*h+d*g+e*f)&65535;return $CLJS.na((m&65535)<<16|l&65535,t<<16|r&65535)}and(a){return $CLJS.na(this.ya&a.ya,this.ja&a.ja)}or(a){return $CLJS.na(this.ya|a.ya,this.ja|a.ja)}xor(a){return $CLJS.na(this.ya^a.ya,this.ja^a.ja)}shiftLeft(a){a&=63;if(0==a)return this;var b=this.ya;return 32>a?$CLJS.na(b<<
a,this.ja<<a|b>>>32-a):$CLJS.na(0,b<<a-32)}};$CLJS.ma.prototype.ua=$CLJS.ba(0);$CLJS.ra=$CLJS.na(0,0);wa=$CLJS.na(1,0);xa=$CLJS.na(-1,-1);oa=$CLJS.na(4294967295,2147483647);pa=$CLJS.na(0,2147483648);$CLJS.rb=String.prototype.trim?function(a){return a.trim()}:function(a){return/^[\s\xa0]*([\s\S]*?)[\s\xa0]*$/.exec(a)[1]};var Ba="constructor hasOwnProperty isPrototypeOf propertyIsEnumerable toLocaleString toString valueOf".split(" ");$CLJS.Da=Array.prototype.indexOf?function(a,b){return Array.prototype.indexOf.call(a,b,void 0)}:function(a,b){if("string"===typeof a)return"string"!==typeof b||1!=b.length?-1:a.indexOf(b,0);for(let c=0;c<a.length;c++)if(c in a&&a[c]===b)return c;return-1};var sb;a:{const a=$CLJS.qb.navigator;if(a){const b=a.userAgent;if(b){sb=b;break a}}sb=""}var Fa=sb;$CLJS.tb=String.prototype.repeat?function(a,b){return a.repeat(b)}:function(a,b){return Array(b+1).join(a)};$CLJS.vb=RegExp("^(?:([^:/?#.]+):)?(?://(?:([^\\\\/?#]*)@)?([^\\\\/?#]*?)(?::([0-9]+))?(?\x3d[\\\\/?#]|$))?([^?#]+)?(?:\\?([^#]*))?(?:#([\\s\\S]*))?$");$CLJS.Ja.tc={};$CLJS.Ja.tc.s=function(a,b,c){return isNaN(c)||""==c||a.length>=Number(c)?a:a=-1<b.indexOf("-",0)?a+(0,$CLJS.tb)(" ",Number(c)-a.length):(0,$CLJS.tb)(" ",Number(c)-a.length)+a};
$CLJS.Ja.tc.f=function(a,b,c,d,e){d=a.toString();isNaN(e)||""==e||(d=parseFloat(a).toFixed(e));let f;f=0>Number(a)?"-":0<=b.indexOf("+")?"+":0<=b.indexOf(" ")?" ":"";0<=Number(a)&&(d=f+d);if(isNaN(c)||d.length>=Number(c))return d;d=isNaN(e)?Math.abs(Number(a)).toString():Math.abs(Number(a)).toFixed(e);a=Number(c)-d.length-f.length;0<=b.indexOf("-",0)?d=f+d+(0,$CLJS.tb)(" ",a):(b=0<=b.indexOf("0",0)?"0":" ",d=f+(0,$CLJS.tb)(b,a)+d);return d};
$CLJS.Ja.tc.d=function(a,b,c,d,e,f,g,h){return $CLJS.Ja.tc.f(parseInt(a,10),b,c,d,0,f,g,h)};$CLJS.Ja.tc.i=$CLJS.Ja.tc.d;$CLJS.Ja.tc.u=$CLJS.Ja.tc.d;$CLJS.Ka=0;$CLJS.La={};$CLJS.Ma.prototype.Ed=!1;$CLJS.Ma.prototype.Qc=$CLJS.ba(2);Na.prototype.stopPropagation=function(){this.Vd=!0};Na.prototype.preventDefault=function(){this.defaultPrevented=!0};var db=function(){if(!$CLJS.qb.addEventListener||!Object.defineProperty)return!1;var a=!1,b=Object.defineProperty({},"passive",{get:function(){a=!0}});try{$CLJS.qb.addEventListener("test",$CLJS.da,b),$CLJS.qb.removeEventListener("test",$CLJS.da,b)}catch(c){}return a}();var xb,Ab,Bb,Cb;xb=Ga("Opera");$CLJS.yb=Ga("Trident")||Ga("MSIE");Ab=Ga("Edge");Bb=Ga("Gecko")&&!(-1!=Fa.toLowerCase().indexOf("webkit")&&!Ga("Edge"))&&!(Ga("Trident")||Ga("MSIE"))&&!Ga("Edge");Cb=-1!=Fa.toLowerCase().indexOf("webkit")&&!Ga("Edge");Cb&&Ga("Mobile");Ga("Macintosh");Ga("Windows");Ga("Linux")||Ga("CrOS");var Db=$CLJS.qb.navigator||null;Db&&(Db.appVersion||"").indexOf("X11");Ga("Android");Oa();Ga("iPad");Ga("iPod");Oa()||Ga("iPad")||Ga("iPod");Fa.toLowerCase().indexOf("kaios");
a:{var Fb="",Gb=function(){if(Bb)return/rv:([^\);]+)(\)|;)/.exec(Fa);if(Ab)return/Edge\/([\d\.]+)/.exec(Fa);if($CLJS.yb)return/\b(?:MSIE|rv)[: ]([^\);]+)(\)|;)/.exec(Fa);if(Cb)return/WebKit\/(\S+)/.exec(Fa);if(xb)return/(?:Version)[ \/]?(\S+)/.exec(Fa)}();Gb&&(Fb=Gb?Gb[1]:"");if($CLJS.yb){var Hb,Ib=$CLJS.qb.document;Hb=Ib?Ib.documentMode:void 0;if(null!=Hb&&Hb>parseFloat(Fb)){$CLJS.Eb=String(Hb);break a}}$CLJS.Eb=Fb};$CLJS.ka(Pa,Na);var Jb={2:"touch",3:"pen",4:"mouse"};
Pa.prototype.init=function(a,b){var c=this.type=a.type,d=a.changedTouches&&a.changedTouches.length?a.changedTouches[0]:null;this.target=a.target||a.srcElement;this.currentTarget=b;if(b=a.relatedTarget){if(Bb){a:{try{la(b.nodeName);var e=!0;break a}catch(f){}e=!1}e||(b=null)}}else"mouseover"==c?b=a.fromElement:"mouseout"==c&&(b=a.toElement);this.relatedTarget=b;d?(this.clientX=void 0!==d.clientX?d.clientX:d.pageX,this.clientY=void 0!==d.clientY?d.clientY:d.pageY,this.screenX=d.screenX||0,this.screenY=
d.screenY||0):(this.offsetX=Cb||void 0!==a.offsetX?a.offsetX:a.layerX,this.offsetY=Cb||void 0!==a.offsetY?a.offsetY:a.layerY,this.clientX=void 0!==a.clientX?a.clientX:a.pageX,this.clientY=void 0!==a.clientY?a.clientY:a.pageY,this.screenX=a.screenX||0,this.screenY=a.screenY||0);this.button=a.button;this.keyCode=a.keyCode||0;this.key=a.key||"";this.charCode=a.charCode||("keypress"==c?a.keyCode:0);this.ctrlKey=a.ctrlKey;this.altKey=a.altKey;this.shiftKey=a.shiftKey;this.metaKey=a.metaKey;this.pointerId=
a.pointerId||0;this.pointerType="string"===typeof a.pointerType?a.pointerType:Jb[a.pointerType]||"";this.state=a.state;this.Fd=a;a.defaultPrevented&&Pa.Zd.preventDefault.call(this)};Pa.prototype.stopPropagation=function(){Pa.Zd.stopPropagation.call(this);this.Fd.stopPropagation?this.Fd.stopPropagation():this.Fd.cancelBubble=!0};Pa.prototype.preventDefault=function(){Pa.Zd.preventDefault.call(this);var a=this.Fd;a.preventDefault?a.preventDefault():a.returnValue=!1};var Qa=0;var Za="closure_listenable_"+(1E6*Math.random()|0);Ta.prototype.add=function(a,b,c,d,e){var f=a.toString();a=this.Jb[f];a||(a=this.Jb[f]=[],this.$d++);var g=Va(a,b,d,e);-1<g?(b=a[g],c||(b.fe=!1)):(b=new Ra(b,this.src,f,!!d,e),b.fe=c,a.push(b));return b};Ta.prototype.remove=function(a,b,c,d){a=a.toString();if(!(a in this.Jb))return!1;var e=this.Jb[a];b=Va(e,b,c,d);return-1<b?($CLJS.Sa(e[b]),Array.prototype.splice.call(e,b,1),0==e.length&&(delete this.Jb[a],this.$d--),!0):!1};
Ta.prototype.Hf=function(a,b,c,d){a=this.Jb[a.toString()];var e=-1;a&&(e=Va(a,b,c,d));return-1<e?a[e]:null};var bb="closure_lm_"+(1E6*Math.random()|0),jb={},fb=0,kb="__closure_events_fn_"+(1E9*Math.random()>>>0);$CLJS.ka($CLJS.lb,$CLJS.Ma);$CLJS.lb.prototype[Za]=!0;$CLJS.k=$CLJS.lb.prototype;$CLJS.k.addEventListener=function(a,b,c,d){Xa(this,a,b,c,d)};$CLJS.k.removeEventListener=function(a,b,c,d){hb(this,a,b,c,d)};
$CLJS.k.dispatchEvent=function(a){var b,c=this.Uf;if(c)for(b=[];c;c=c.Uf)b.push(c);c=this.Eh;var d=a.type||a;if("string"===typeof a)a=new Na(a,c);else if(a instanceof Na)a.target=a.target||c;else{var e=a;a=new Na(d,c);Ca(a,e)}e=!0;if(b)for(var f=b.length-1;!a.Vd&&0<=f;f--){var g=a.currentTarget=b[f];e=mb(g,d,!0,a)&&e}a.Vd||(g=a.currentTarget=c,e=mb(g,d,!0,a)&&e,a.Vd||(e=mb(g,d,!1,a)&&e));if(b)for(f=0;!a.Vd&&f<b.length;f++)g=a.currentTarget=b[f],e=mb(g,d,!1,a)&&e;return e};$CLJS.k.Qc=$CLJS.ba(1);
$CLJS.k.Hf=function(a,b,c,d){return this.Wb.Hf(String(a),b,c,d)};$CLJS.Kb=$CLJS.qb;nb.prototype.pg=null;$CLJS.ka(ob,nb);$CLJS.Lb=new ob;Ha();Oa()||Ga("iPod");Ga("iPad");!Ga("Android")||Ia()||Ha()||Ga("Opera")||Ga("Silk");Ia();var Mb=Ga("Safari")&&!(Ia()||Ga("Coast")||Ga("Opera")||Ga("Edge")||Ga("Edg/")||Ga("OPR")||Ha()||Ga("Silk")||Ga("Android"))&&!(Oa()||Ga("iPad")||Ga("iPod"));$CLJS.Nb=Bb||Cb||!Mb&&!$CLJS.yb&&"function"==typeof $CLJS.qb.atob;