var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./com.cognitect.transit.util.js");require("./com.cognitect.transit.eq.js");
'use strict';var XB,YB,ZB,aC,bC,kC,mC,oC,rC,sC,tC,uC,wC,nC;XB=function(a,b){b&=63;if(0==b)return a;var c=a.ja;return 32>b?$CLJS.na(a.ya>>>b|c<<32-b,c>>>b):32==b?$CLJS.na(c,0):$CLJS.na(c>>>b-32,0)};
YB=function(a,b){if(null==a)return null==b;if(a===b)return!0;if("object"===typeof a){if((0,$CLJS.VB)(a)){if((0,$CLJS.VB)(b)&&a.length===b.length){for(var c=0;c<a.length;c++)if(!YB(a[c],b[c]))return!1;return!0}return!1}if(a.Sb)return a.Sb(b);if(null!=b&&"object"===typeof b){if(b.Sb)return b.Sb(a);c=0;var d=(0,$CLJS.UB)(b).length,e;for(e in a)if(a.hasOwnProperty(e)&&(c++,!b.hasOwnProperty(e)||!YB(a[e],b[e])))return!1;return c===d}}return!1};ZB=function(a,b){return a^b+2654435769+(a<<6)+(a>>2)};
aC=function(a){var b=0;if(null!=a.forEach)a.forEach(function(g,h){b=(b+($CLJS.$B(h)^$CLJS.$B(g)))%4503599627370496});else for(var c=(0,$CLJS.UB)(a),d=0;d<c.length;d++){var e=c[d],f=a[e];b=(b+($CLJS.$B(e)^$CLJS.$B(f)))%4503599627370496}return b};bC=function(a){var b=0;if((0,$CLJS.VB)(a))for(var c=0;c<a.length;c++)b=ZB(b,$CLJS.$B(a[c]));else a.forEach&&a.forEach(function(d){b=ZB(b,$CLJS.$B(d))});return b};
$CLJS.$B=function(a){if(null==a)return 0;switch(typeof a){case "number":return a;case "boolean":return!0===a?1:0;case "string":var b=cC[a];if(null!=b)a=b;else{for(var c=b=0;c<a.length;++c)b=31*b+a.charCodeAt(c),b%=4294967296;dC++;256<=dC&&(cC={},dC=1);a=cC[a]=b}return a;case "function":return b=a.transit$hashCode$,b||(b=eC,"undefined"!=typeof Object.defineProperty?Object.defineProperty(a,"transit$hashCode$",{value:b,enumerable:!1}):a.transit$hashCode$=b,eC++),b;default:return a instanceof Date?a.valueOf():
(0,$CLJS.VB)(a)?bC(a):a.Vb?a.Vb():aC(a)}};
$CLJS.fC=function(a,b){if("-"==a.charAt(0))return $CLJS.qa($CLJS.fC(a.substring(1),b));var c=parseInt(a,b||10);if(9007199254740991>=c)return new $CLJS.ma(c%4294967296|0,c/4294967296|0);if(0==a.length)throw Error("number format error: empty string");if(0<=a.indexOf("-"))throw Error('number format error: interior "-" character: '+a);b=b||10;if(2>b||36<b)throw Error("radix out of range: "+b);c=$CLJS.sa(Math.pow(b,8));for(var d=$CLJS.ra,e=0;e<a.length;e+=8){var f=Math.min(8,a.length-e),g=parseInt(a.substring(e,
e+f),b);8>f?(f=$CLJS.sa(Math.pow(b,f)),d=d.multiply(f).add($CLJS.sa(g))):(d=d.multiply(c),d=d.add($CLJS.sa(g)))}return d};$CLJS.gC=function(a,b){this.tag=a;this.rep=b;this.ua=-1};$CLJS.hC=function(a,b){return new $CLJS.gC(a,b)};$CLJS.iC=function(a){this.La=a;this.ua=-1};$CLJS.jC=function(a){this.La=a;this.ua=-1};kC=function(a,b,c){var d="";c=c||b+1;for(var e=8*(7-b),f=(new $CLJS.ma(255,0)).shiftLeft(e);b<c;b++,e-=8,f=XB(f,8)){var g=XB(a.and(f),e).toString(16);1==g.length&&(g="0"+g);d+=g}return d};
$CLJS.lC=function(a,b){this.high=a;this.low=b;this.ua=-1};mC=function(a,b){this.entries=a;this.type=b||0;this.Ca=0};oC=function(a,b){this.map=a;this.type=b||0;this.keys=nC(this.map);this.Ca=0;this.Lc=null;this.Bc=0};
rC=function(a,b){if(a instanceof $CLJS.pC&&(b instanceof $CLJS.qC||b instanceof $CLJS.pC)){if(a.size!==b.size)return!1;for(var c in a.map)for(var d=a.map[c],e=0;e<d.length;e+=2)if(!YB(d[e+1],b.get(d[e])))return!1;return!0}if(a instanceof $CLJS.qC&&(b instanceof $CLJS.qC||b instanceof $CLJS.pC)){if(a.size!==b.size)return!1;a=a.wa;for(e=0;e<a.length;e+=2)if(!YB(a[e+1],b.get(a[e])))return!1;return!0}if(null!=b&&"object"===typeof b&&(e=(0,$CLJS.UB)(b),c=e.length,a.size===c)){for(d=0;d<c;d++){var f=e[d];
if(!a.has(f)||!YB(b[f],a.get(f)))return!1}return!0}return!1};sC=function(a){return null==a?"null":"array"===$CLJS.n(a)?"["+a.toString()+"]":"string"===$CLJS.n(a)?'"'+a+'"':a.toString()};tC=function(a){var b=0,c="TransitMap {";a.forEach(function(d,e){c+=sC(e)+" \x3d\x3e "+sC(d);b<a.size-1&&(c+=", ");b++});return c+"}"};uC=function(a){var b=0,c="TransitSet {";a.forEach(function(d){c+=sC(d);b<a.size-1&&(c+=", ");b++});return c+"}"};
$CLJS.qC=function(a){this.wa=a;this.va=null;this.ua=-1;this.size=a.length/2;this.jg=0};wC=function(a){if(a.va)throw Error("Invalid operation, already converted");if(8>a.size)return!1;a.jg++;return 32<a.jg?(a.va=$CLJS.vC(a.wa,!1,!0),a.wa=[],!0):!1};$CLJS.pC=function(a,b,c){this.map=b||{};this.ed=a||[];this.size=c||0;this.ua=-1};nC=function(a){return null!=a.ed?a.ed:(0,$CLJS.UB)(a.map)};
$CLJS.vC=function(a,b,c){a=a||[];b=!1===b?b:!0;if((!0!==c||!c)&&64>=a.length){if(b){var d=a;a=[];for(b=0;b<d.length;b+=2){var e=!1;for(c=0;c<a.length;c+=2)if(YB(a[c],d[b])){a[c+1]=d[b+1];e=!0;break}e||(a.push(d[b]),a.push(d[b+1]))}}return new $CLJS.qC(a)}d={};e=[];var f=0;for(b=0;b<a.length;b+=2){c=$CLJS.$B(a[b]);var g=d[c];if(null==g)e.push(c),d[c]=[a[b],a[b+1]],f++;else{var h=!0;for(c=0;c<g.length;c+=2)if(YB(g[c],a[b])){g[c+1]=a[b+1];h=!1;break}h&&(g.push(a[b]),g.push(a[b+1]),f++)}}return new $CLJS.pC(e,
d,f)};$CLJS.xC=function(a){this.map=a;this.size=a.size};$CLJS.yC=function(a){a=a||[];for(var b={},c=[],d=0,e=0;e<a.length;e++){var f=$CLJS.$B(a[e]),g=b[f];if(null==g)c.push(f),b[f]=[a[e],a[e]],d++;else{f=!0;for(var h=0;h<g.length;h+=2)if(YB(g[h],a[e])){f=!1;break}f&&(g.push(a[e]),g.push(a[e]),d++)}}return new $CLJS.xC(new $CLJS.pC(c,b,d))};$CLJS.ma.prototype.ua=$CLJS.ca(0,function(){return this.ya^this.ja});var eC=1,cC={},dC=0,zC="undefined"!=typeof Symbol?Symbol.iterator:"@@iterator";
$CLJS.gC.prototype.toString=function(){return"[TaggedValue: "+this.tag+", "+this.rep+"]"};$CLJS.gC.prototype.da=function(a){return YB(this,a)};$CLJS.gC.prototype.equiv=$CLJS.gC.prototype.da;$CLJS.gC.prototype.Sb=function(a){return a instanceof $CLJS.gC?this.tag===a.tag&&YB(this.rep,a.rep):!1};$CLJS.gC.prototype.Vb=function(){-1===this.ua&&(this.ua=ZB($CLJS.$B(this.tag),$CLJS.$B(this.rep)));return this.ua};$CLJS.AC=$CLJS.fC("9007199254740991");$CLJS.BC=$CLJS.fC("-9007199254740991");
$CLJS.ma.prototype.da=function(a){return YB(this,a)};$CLJS.ma.prototype.equiv=$CLJS.ma.prototype.da;$CLJS.ma.prototype.Sb=function(a){return a instanceof $CLJS.ma&&$CLJS.va(this,a)};$CLJS.ma.prototype.Vb=function(){return this.ya};$CLJS.iC.prototype.toString=function(){return":"+this.La};$CLJS.iC.prototype.namespace=function(){var a=this.La.indexOf("/");return-1!=a?this.La.substring(0,a):null};
$CLJS.iC.prototype.name=function(){var a=this.La.indexOf("/");return-1!=a?this.La.substring(a+1,this.La.length):this.La};$CLJS.iC.prototype.da=function(a){return YB(this,a)};$CLJS.iC.prototype.equiv=$CLJS.iC.prototype.da;$CLJS.iC.prototype.Sb=function(a){return a instanceof $CLJS.iC&&this.La==a.La};$CLJS.iC.prototype.Vb=function(){-1===this.ua&&(this.ua=$CLJS.$B(this.La));return this.ua};$CLJS.jC.prototype.namespace=function(){var a=this.La.indexOf("/");return-1!=a?this.La.substring(0,a):null};
$CLJS.jC.prototype.name=function(){var a=this.La.indexOf("/");return-1!=a?this.La.substring(a+1,this.La.length):this.La};$CLJS.jC.prototype.toString=function(){return this.La};$CLJS.jC.prototype.da=function(a){return YB(this,a)};$CLJS.jC.prototype.equiv=$CLJS.jC.prototype.da;$CLJS.jC.prototype.Sb=function(a){return a instanceof $CLJS.jC&&this.La==a.La};$CLJS.jC.prototype.Vb=function(){-1===this.ua&&(this.ua=$CLJS.$B(this.La));return this.ua};
$CLJS.lC.prototype.toString=function(){var a=this.high,b=this.low;var c=kC(a,0,4)+"-";c+=kC(a,4,6)+"-";c+=kC(a,6,8)+"-";c+=kC(b,0,2)+"-";return c+=kC(b,2,8)};$CLJS.lC.prototype.da=function(a){return YB(this,a)};$CLJS.lC.prototype.equiv=$CLJS.lC.prototype.da;$CLJS.lC.prototype.Sb=function(a){return a instanceof $CLJS.lC&&$CLJS.va(this.high,a.high)&&$CLJS.va(this.low,a.low)};$CLJS.lC.prototype.Vb=function(){-1===this.ua&&(this.ua=$CLJS.$B(this.toString()));return this.ua};
Date.prototype.Sb=function(a){return a instanceof Date?this.valueOf()===a.valueOf():!1};Date.prototype.Vb=function(){return this.valueOf()};mC.prototype.next=function(){if(this.Ca<this.entries.length){var a={value:0===this.type?this.entries[this.Ca]:1===this.type?this.entries[this.Ca+1]:[this.entries[this.Ca],this.entries[this.Ca+1]],done:!1};this.Ca+=2;return a}return{value:null,done:!0}};mC.prototype.next=mC.prototype.next;mC.prototype[zC]=function(){return this};
oC.prototype.next=function(){if(this.Ca<this.map.size){null!=this.Lc&&this.Bc<this.Lc.length||(this.Lc=this.map.map[this.keys[this.Ca]],this.Bc=0);var a={value:0===this.type?this.Lc[this.Bc]:1===this.type?this.Lc[this.Bc+1]:[this.Lc[this.Bc],this.Lc[this.Bc+1]],done:!1};this.Ca++;this.Bc+=2;return a}return{value:null,done:!0}};oC.prototype.next=oC.prototype.next;oC.prototype[zC]=function(){return this};$CLJS.qC.prototype.toString=function(){return tC(this)};$CLJS.qC.prototype.inspect=function(){return this.toString()};
$CLJS.qC.prototype.clear=function(){this.ua=-1;this.va?this.va.clear():this.wa=[];this.size=0};$CLJS.qC.prototype.clear=$CLJS.qC.prototype.clear;$CLJS.qC.prototype.keys=function(){return this.va?this.va.keys():new mC(this.wa,0)};$CLJS.qC.prototype.keys=$CLJS.qC.prototype.keys;$CLJS.qC.prototype.Vc=function(){if(this.va)return this.va.Vc();for(var a=[],b=0,c=0;c<this.wa.length;b++,c+=2)a[b]=this.wa[c];return a};$CLJS.qC.prototype.keySet=$CLJS.qC.prototype.Vc;
$CLJS.qC.prototype.entries=function(){return this.va?this.va.entries():new mC(this.wa,2)};$CLJS.qC.prototype.entries=$CLJS.qC.prototype.entries;$CLJS.qC.prototype.values=function(){return this.va?this.va.values():new mC(this.wa,1)};$CLJS.qC.prototype.values=$CLJS.qC.prototype.values;$CLJS.qC.prototype.forEach=function(a){if(this.va)this.va.forEach(a);else for(var b=0;b<this.wa.length;b+=2)a(this.wa[b+1],this.wa[b])};$CLJS.qC.prototype.forEach=$CLJS.qC.prototype.forEach;
$CLJS.qC.prototype.get=function(a,b){if(this.va)return this.va.get(a);if(wC(this))return this.get(a);for(var c=0;c<this.wa.length;c+=2)if(YB(this.wa[c],a))return this.wa[c+1];return b};$CLJS.qC.prototype.get=$CLJS.qC.prototype.get;$CLJS.qC.prototype.has=function(a){if(this.va)return this.va.has(a);if(wC(this))return this.has(a);for(var b=0;b<this.wa.length;b+=2)if(YB(this.wa[b],a))return!0;return!1};$CLJS.qC.prototype.has=$CLJS.qC.prototype.has;
$CLJS.qC.prototype.set=function(a,b){this.ua=-1;if(this.va)this.va.set(a,b),this.size=this.va.size;else{for(var c=0;c<this.wa.length;c+=2)if(YB(this.wa[c],a)){this.wa[c+1]=b;return}this.wa.push(a);this.wa.push(b);this.size++;32<this.size&&(this.va=$CLJS.vC(this.wa,!1,!0),this.wa=null)}};$CLJS.qC.prototype.set=$CLJS.qC.prototype.set;
$CLJS.qC.prototype["delete"]=function(a){this.ua=-1;if(this.va)return a=this.va.delete(a),this.size=this.va.size,a;for(var b=0;b<this.wa.length;b+=2)if(YB(this.wa[b],a))return a=this.wa[b+1],this.wa.splice(b,2),this.size--,a};$CLJS.qC.prototype.clone=function(){var a=$CLJS.vC();this.forEach(function(b,c){a.set(c,b)});return a};$CLJS.qC.prototype.clone=$CLJS.qC.prototype.clone;$CLJS.qC.prototype[zC]=function(){return this.entries()};
$CLJS.qC.prototype.Vb=function(){if(this.va)return this.va.Vb();-1===this.ua&&(this.ua=aC(this));return this.ua};$CLJS.qC.prototype.Sb=function(a){return this.va?rC(this.va,a):rC(this,a)};$CLJS.pC.prototype.toString=function(){return tC(this)};$CLJS.pC.prototype.inspect=function(){return this.toString()};$CLJS.pC.prototype.clear=function(){this.ua=-1;this.map={};this.ed=[];this.size=0};$CLJS.pC.prototype.clear=$CLJS.pC.prototype.clear;
$CLJS.pC.prototype["delete"]=function(a){this.ua=-1;this.ed=null;for(var b=$CLJS.$B(a),c=this.map[b],d=0;d<c.length;d+=2)if(YB(a,c[d]))return a=c[d+1],c.splice(d,2),0===c.length&&delete this.map[b],this.size--,a};$CLJS.pC.prototype.entries=function(){return new oC(this,2)};$CLJS.pC.prototype.entries=$CLJS.pC.prototype.entries;$CLJS.pC.prototype.forEach=function(a){for(var b=nC(this),c=0;c<b.length;c++)for(var d=this.map[b[c]],e=0;e<d.length;e+=2)a(d[e+1],d[e],this)};$CLJS.pC.prototype.forEach=$CLJS.pC.prototype.forEach;
$CLJS.pC.prototype.get=function(a,b){var c=$CLJS.$B(a);c=this.map[c];if(null!=c)for(b=0;b<c.length;b+=2){if(YB(a,c[b]))return c[b+1]}else return b};$CLJS.pC.prototype.get=$CLJS.pC.prototype.get;$CLJS.pC.prototype.has=function(a){var b=$CLJS.$B(a);b=this.map[b];if(null!=b)for(var c=0;c<b.length;c+=2)if(YB(a,b[c]))return!0;return!1};$CLJS.pC.prototype.has=$CLJS.pC.prototype.has;$CLJS.pC.prototype.keys=function(){return new oC(this,0)};$CLJS.pC.prototype.keys=$CLJS.pC.prototype.keys;
$CLJS.pC.prototype.Vc=function(){for(var a=nC(this),b=[],c=0;c<a.length;c++)for(var d=this.map[a[c]],e=0;e<d.length;e+=2)b.push(d[e]);return b};$CLJS.pC.prototype.keySet=$CLJS.pC.prototype.Vc;$CLJS.pC.prototype.set=function(a,b){this.ua=-1;var c=$CLJS.$B(a),d=this.map[c];if(null==d)this.ed&&this.ed.push(c),this.map[c]=[a,b],this.size++;else{c=!0;for(var e=0;e<d.length;e+=2)if(YB(b,d[e])){c=!1;d[e]=b;break}c&&(d.push(a),d.push(b),this.size++)}};$CLJS.pC.prototype.set=$CLJS.pC.prototype.set;
$CLJS.pC.prototype.values=function(){return new oC(this,1)};$CLJS.pC.prototype.values=$CLJS.pC.prototype.values;$CLJS.pC.prototype.clone=function(){var a=$CLJS.vC();this.forEach(function(b,c){a.set(c,b)});return a};$CLJS.pC.prototype.clone=$CLJS.pC.prototype.clone;$CLJS.pC.prototype[zC]=function(){return this.entries()};$CLJS.pC.prototype.Vb=function(){-1===this.ua&&(this.ua=aC(this));return this.ua};$CLJS.pC.prototype.Sb=function(a){return rC(this,a)};$CLJS.xC.prototype.toString=function(){return uC(this)};
$CLJS.xC.prototype.inspect=function(){return this.toString()};$CLJS.xC.prototype.add=function(a){this.map.set(a,a);this.size=this.map.size};$CLJS.xC.prototype.add=$CLJS.xC.prototype.add;$CLJS.xC.prototype.clear=function(){this.map=new $CLJS.pC;this.size=0};$CLJS.xC.prototype.clear=$CLJS.xC.prototype.clear;$CLJS.xC.prototype["delete"]=function(a){a=this.map.delete(a);this.size=this.map.size;return a};$CLJS.xC.prototype.entries=function(){return this.map.entries()};$CLJS.xC.prototype.entries=$CLJS.xC.prototype.entries;
$CLJS.xC.prototype.forEach=function(a){var b=this;this.map.forEach(function(c,d){a(d,b)})};$CLJS.xC.prototype.forEach=$CLJS.xC.prototype.forEach;$CLJS.xC.prototype.has=function(a){return this.map.has(a)};$CLJS.xC.prototype.has=$CLJS.xC.prototype.has;$CLJS.xC.prototype.keys=function(){return this.map.keys()};$CLJS.xC.prototype.keys=$CLJS.xC.prototype.keys;$CLJS.xC.prototype.Vc=function(){return this.map.Vc()};$CLJS.xC.prototype.keySet=$CLJS.xC.prototype.Vc;$CLJS.xC.prototype.values=function(){return this.map.values()};
$CLJS.xC.prototype.values=$CLJS.xC.prototype.values;$CLJS.xC.prototype.clone=function(){var a=$CLJS.yC();this.forEach(function(b){a.add(b)});return a};$CLJS.xC.prototype.clone=$CLJS.xC.prototype.clone;$CLJS.xC.prototype[zC]=function(){return this.values()};$CLJS.xC.prototype.Sb=function(a){if(a instanceof $CLJS.xC){if(this.size===a.size)return YB(this.map,a.map)}else return!1};$CLJS.xC.prototype.Vb=function(){return $CLJS.$B(this.map)};