var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./integrant.core.js");require("./taoensso.timbre.js");require("./com.kubelt.ipfs.client.js");require("./com.kubelt.lib.error.js");require("./com.kubelt.lib.integrant.js");require("./com.kubelt.lib.jwt.js");require("./com.kubelt.lib.promise.js");require("./com.kubelt.lib.storage.js");require("./com.kubelt.lib.vault.js");require("./com.kubelt.lib.wallet.js");require("./com.kubelt.proto.http.js");require("./com.kubelt.lib.http.browser.js");
'use strict';var QF=function(a,b,c){return a.call.apply(a.bind,arguments)},RF=function(a,b,c){if(!a)throw Error();if(2<arguments.length){var d=Array.prototype.slice.call(arguments,2);return function(){var e=Array.prototype.slice.call(arguments);Array.prototype.unshift.apply(e,d);return a.apply(b,e)}}return function(){return a.apply(b,arguments)}},SF=function(a,b,c){SF=Function.prototype.bind&&-1!=Function.prototype.bind.toString().indexOf("native code")?QF:RF;return SF.apply(null,arguments)},UF=function(a){var b=
TF;return Object.prototype.hasOwnProperty.call(b,9)?b[9]:b[9]=a(9)},VF=function(a,b){return a<b?-1:a>b?1:0},WF=function(){return UF(function(){let a=0;const b=(0,$CLJS.rb)(String($CLJS.Eb)).split("."),c=(0,$CLJS.rb)("9").split("."),d=Math.max(b.length,c.length);for(let g=0;0==a&&g<d;g++){var e=b[g]||"",f=c[g]||"";do{e=/(\d*)(\D*)(.*)/.exec(e)||["","","",""];f=/(\d*)(\D*)(.*)/.exec(f)||["","","",""];if(0==e[0].length&&0==f[0].length)break;a=VF(0==e[1].length?0:parseInt(e[1],10),0==f[1].length?0:parseInt(f[1],
10))||VF(0==e[2].length,0==f[2].length)||VF(e[2],f[2]);e=e[3];f=f[3]}while(0==a)}return 0<=a})},XF=function(a,b,c){if("function"===typeof a)c&&(a=SF(a,c));else if(a&&"function"==typeof a.handleEvent)a=SF(a.handleEvent,a);else throw Error("Invalid listener argument");return 2147483647<Number(b)?-1:$CLJS.Kb.setTimeout(a,b||0)},YF=function(a){if(!a.Eg&&"undefined"==typeof XMLHttpRequest&&"undefined"!=typeof ActiveXObject){const b=["MSXML2.XMLHTTP.6.0","MSXML2.XMLHTTP.3.0","MSXML2.XMLHTTP","Microsoft.XMLHTTP"];
for(let c=0;c<b.length;c++){const d=b[c];try{return new ActiveXObject(d),a.Eg=d}catch(e){}}throw Error("Could not create ActiveXObject. ActiveX might be disabled, or MSXML might not be installed");}return a.Eg},ZF=function(a){var b;(b=a.pg)||(b={},YF(a)&&(b[0]=!0,b[1]=!0),b=a.pg=b);return b},$F=function(a){return(a=YF(a))?new ActiveXObject(a):new XMLHttpRequest},aG=function(a){a.ga&&a.cg&&(a.ga.ontimeout=null);a.cf&&($CLJS.Kb.clearTimeout(a.cf),a.cf=null)},bG=function(a){return $CLJS.yb&&WF()&&"number"===
typeof a.timeout&&void 0!==a.ontimeout},cG=function(a){a.Ef||(a.Ef=!0,a.dispatchEvent("complete"),a.dispatchEvent("error"))},dG=function(a,b){if(a.ga){aG(a);const c=a.ga,d=a.jf[0]?$CLJS.da:null;a.ga=null;a.jf=null;b||a.dispatchEvent("ready");try{c.onreadystatechange=d}catch(e){}}},eG=function(a,b){a.Ac=!1;a.ga&&(a.Uc=!0,a.ga.abort(),a.Uc=!1);a.ne=b;cG(a);dG(a)},fG=function(a){return a.ga?a.ga.readyState:0},hG=function(a){if(a.Ac&&"undefined"!=typeof $CLJS.pb)if(a.jf[1]&&4==fG(a)&&2==a.getStatus())a.getStatus();
else if(a.me&&4==fG(a))XF(a.Og,0,a);else if(a.dispatchEvent("readystatechange"),4==fG(a)){a.getStatus();a.Ac=!1;try{const h=a.getStatus();a:switch(h){case 200:case 201:case 202:case 204:case 206:case 304:case 1223:var b=!0;break a;default:b=!1}var c;if(!(c=b)){var d;if(d=0===h){var e=String(a.Kf).match($CLJS.vb)[1]||null;if(!e&&$CLJS.qb.self&&$CLJS.qb.self.location){var f=$CLJS.qb.self.location.protocol;e=f.substr(0,f.length-1)}d=!gG.test(e?e.toLowerCase():"")}c=d}if(c)a.dispatchEvent("complete"),
a.dispatchEvent("success");else{try{var g=2<fG(a)?a.ga.statusText:""}catch(l){g=""}a.ne=g+" ["+a.getStatus()+"]";cG(a)}}finally{dG(a)}}},iG=function(a,b){return{type:b,lengthComputable:a.lengthComputable,loaded:a.loaded,total:a.total}},kG=function(a){$CLJS.lb.call(this);this.headers=new Map;this.kf=a||null;this.Ac=!1;this.jf=this.ga=null;this.ne=this.Kf="";this.Uc=this.Jf=this.me=this.Ef=!1;this.df=0;this.cf=null;this.Yg=jG;this.cg=this.sj=this.dh=!1;this.$f=null},lG=function(a,b){a=String(a);let c=
a.indexOf(".");-1===c&&(c=a.length);const d="-"===a[0]?"-":"";d&&(a=a.substring(1));return d+(0,$CLJS.tb)("0",Math.max(0,b-c))+a},mG=function(a,b,c,d,e){a=new Date(a,b,c);e=e||0;return a.valueOf()+864E5*(((void 0!==d?d:3)-e+7)%7-((a.getDay()+6)%7-e+7)%7)},nG=function(a){var b=["GMT"];b.push(0>=a?"+":"-");a=Math.abs(a);b.push(lG(Math.floor(a/60)%100,2),":",lG(a%60,2));return b.join("")},oG=function(){},pG=function(a,b){b=Date.UTC(b.getUTCFullYear(),b.getUTCMonth(),b.getUTCDate(),b.getUTCHours(),b.getUTCMinutes())/
36E5;for(var c=0;c<a.ef.length&&b>=a.ef[c];)c+=2;return 0==c?0:a.ef[c-1]},qG=function(a){if("number"==typeof a){var b=new oG;b.bf=a;var c=a;if(0==c)c="Etc/GMT";else{var d=["Etc/GMT",0>c?"-":"+"];c=Math.abs(c);d.push(Math.floor(c/60)%100);c%=60;0!=c&&d.push(":",lG(c,2));c=d.join("")}b.Zf=c;c=a;0==c?c="UTC":(d=["UTC",0>c?"+":"-"],c=Math.abs(c),d.push(Math.floor(c/60)%100),c%=60,0!=c&&d.push(":",c),c=d.join(""));a=nG(a);b.ag=[c,c];b.cd={Nj:a,hg:a};b.ef=[];return b}b=new oG;b.Zf=a.id;b.bf=-a.std_offset;
b.ag=a.names;b.cd=a.names_ext;b.ef=a.transitions;return b},rG=function(a){return a.getHours?a.getHours():0},sG=function(a){if(!(a.getHours&&a.getSeconds&&a.getMinutes))throw Error("The date to format has no time (probably a goog.date.Date). Use Date or goog.date.DateTime, or use a pattern without time fields.");},wG=function(a){this.$e=[];this.Ea=tG;"number"==typeof a?uG(this,a):vG(this,a)},vG=function(a,b){for(;b;){const c=b;for(let d=0;d<xG.length;++d){const e=b.match(xG[d]);if(e){let f=e[0];b=
b.substring(f.length);0==d&&("''"==f?f="'":(f=f.substring(1,"'"==e[1]?f.length-1:f.length),f=f.replace(/''/g,"'")));a.$e.push({text:f,type:d});break}}if(c===b)throw Error("Malformed pattern part: "+b);}},uG=function(a,b){let c;if(4>b)c=a.Ea.eg[b];else if(8>b)c=a.Ea.ig[b-4];else if(12>b)c=a.Ea.gh[b-8],c=c.replace("{1}",a.Ea.eg[b-8]),c=c.replace("{0}",a.Ea.ig[b-8]);else{uG(a,10);return}vG(a,c)},yG=function(a,b){{b=String(b);const c=a.Ea||tG;if(void 0!==c.xh){a=[];for(let d=0;d<b.length;d++){const e=
b.charCodeAt(d);a.push(48<=e&&57>=e?String.fromCharCode(c.xh+e-48):b.charAt(d))}b=a.join("")}}return b},zG=function(a,b,c,d,e,f){var g=b.length;switch(b.charAt(0)){case "G":return c=0<d.getFullYear()?1:0,4<=g?a.Ea.ih[c]:a.Ea.jh[c];case "y":return c=d.getFullYear(),0>c&&(c=-c),2==g&&(c%=100),yG(a,lG(c,g));case "Y":return c=(new Date(mG(d.getFullYear(),d.getMonth(),d.getDate(),a.Ea.gg,a.Ea.fg))).getFullYear(),0>c&&(c=-c),2==g&&(c%=100),yG(a,lG(c,g));case "M":a:switch(c=d.getMonth(),g){case 5:g=a.Ea.lh[c];
break a;case 4:g=a.Ea.kh[c];break a;case 3:g=a.Ea.nh[c];break a;default:g=yG(a,lG(c+1,g))}return g;case "k":return sG(e),yG(a,lG(rG(e)||24,g));case "S":return yG(a,(e.getMilliseconds()/1E3).toFixed(Math.min(3,g)).substr(2)+(3<g?lG(0,g-3):""));case "E":return c=d.getDay(),4<=g?a.Ea.wh[c]:a.Ea.ph[c];case "a":return sG(e),g=rG(e),a.Ea.fh[12<=g&&24>g?1:0];case "h":return sG(e),yG(a,lG(rG(e)%12||12,g));case "K":return sG(e),yG(a,lG(rG(e)%12,g));case "H":return sG(e),yG(a,lG(rG(e),g));case "c":a:switch(c=
d.getDay(),g){case 5:g=a.Ea.sh[c];break a;case 4:g=a.Ea.vh[c];break a;case 3:g=a.Ea.uh[c];break a;default:g=yG(a,lG(c,1))}return g;case "L":a:switch(c=d.getMonth(),g){case 5:g=a.Ea.rh[c];break a;case 4:g=a.Ea.qh[c];break a;case 3:g=a.Ea.th[c];break a;default:g=yG(a,lG(c+1,g))}return g;case "Q":return c=Math.floor(d.getMonth()/3),4>g?a.Ea.oh[c]:a.Ea.mh[c];case "d":return yG(a,lG(d.getDate(),g));case "m":return sG(e),yG(a,lG(e.getMinutes(),g));case "s":return sG(e),yG(a,lG(e.getSeconds(),g));case "v":return g=
f||qG(c.getTimezoneOffset()),g.Zf;case "V":return a=f||qG(c.getTimezoneOffset()),2>=g?a.Zf:0<pG(a,c)?void 0!==a.cd.hh?a.cd.hh:a.cd.DST_GENERIC_LOCATION:void 0!==a.cd.hg?a.cd.hg:a.cd.STD_GENERIC_LOCATION;case "w":return c=mG(e.getFullYear(),e.getMonth(),e.getDate(),a.Ea.gg,a.Ea.fg),yG(a,lG(Math.floor(Math.round((c-(new Date((new Date(c)).getFullYear(),0,1)).valueOf())/864E5)/7)+1,g));case "z":return a=f||qG(c.getTimezoneOffset()),4>g?a.ag[0<pG(a,c)?2:0]:a.ag[0<pG(a,c)?3:1];case "Z":return b=f||qG(c.getTimezoneOffset()),
4>g?(g=-(b.bf-pG(b,c)),a=[0>g?"-":"+"],g=Math.abs(g),a.push(lG(Math.floor(g/60)%100,2),lG(g%60,2)),g=a.join("")):g=yG(a,nG(b.bf-pG(b,c))),g;default:return""}},BG=function(){if(!AG){AG={};for(var a="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789".split(""),b=["+/\x3d","+/","-_\x3d","-_.","-_"],c=0;5>c;c++)for(var d=a.concat(b[c].split("")),e=0;e<d.length;e++){var f=d[e];void 0===AG[f]&&(AG[f]=e)}}},CG=function(a,b){function c(l){for(;d<a.length;){var m=a.charAt(d++),r=AG[m];if(null!=
r)return r;if(!$CLJS.Ob(m))throw Error("Unknown base64 encoding at char: "+m);}return l}BG();for(var d=0;;){var e=c(-1),f=c(0),g=c(64),h=c(64);if(64===h&&-1===e)break;b(e<<2|f>>4);64!=g&&(b(f<<4&240|g>>2),64!=h&&b(g<<6&192|h))}},DG=function(a){if($CLJS.Nb)return $CLJS.qb.atob(a);var b="";CG(a,function(c){b+=String.fromCharCode(c)});return b},EG=function(a){"undefined"!==typeof setTimeout&&(a=setTimeout(a,0),$CLJS.Fe.g&&$CLJS.Fe.g(a))},FG=function(){this.l=[]},GG=function(){return function(a){var b=
new FG;return function(){function c(g,h){b.add(h);return 2===b.size()?(h=$CLJS.Eg(b.l),b.clear(),a.h?a.h(g,h):a.call(null,g,h)):g}function d(g){if(!$CLJS.q(b.Md())){var h=$CLJS.Eg(b.l);b.clear();g=$CLJS.Xd(a.h?a.h(g,h):a.call(null,g,h))}return a.g?a.g(g):a.call(null,g)}function e(){return a.v?a.v():a.call(null)}var f=null;f=function(g,h){switch(arguments.length){case 0:return e.call(this);case 1:return d.call(this,g);case 2:return c.call(this,g,h)}throw Error("Invalid arity: "+arguments.length);};
f.v=e;f.g=d;f.h=c;return f}()}},HG=function(a,b){return $CLJS.ao($CLJS.Yn(a),b)},IG=function(a,b,c){if($CLJS.af(c)){var d=$CLJS.Cf($CLJS.Wl,$CLJS.wi.h(a,c));return b.g?b.g(d):b.call(null,d)}return $CLJS.Ag(c)?(d=new $CLJS.$g(function(){var e=$CLJS.Dc(c);return a.g?a.g(e):a.call(null,e)}(),function(){var e=$CLJS.Ec(c);return a.g?a.g(e):a.call(null,e)}(),null),b.g?b.g(d):b.call(null,d)):$CLJS.Rm(c)?(d=$CLJS.bB($CLJS.wi.h(a,c)),b.g?b.g(d):b.call(null,d)):$CLJS.Ae(c)?(d=$CLJS.jc.j(function(e,f){return $CLJS.O.h(e,
a.g?a.g(f):a.call(null,f))},c,c),b.g?b.g(d):b.call(null,d)):$CLJS.Mm(c)?(d=$CLJS.gh.h($CLJS.ne(c),$CLJS.wi.h(a,c)),b.g?b.g(d):b.call(null,d)):b.g?b.g(c):b.call(null,c)},JG=function(){return function(a){var b=$CLJS.kg(!1);return function(){function c(g,h){if($CLJS.q($CLJS.w(b)))return g=a.h?a.h(g," "):a.call(null,g," "),$CLJS.Vd(g)?g:a.h?a.h(g,h):a.call(null,g,h);$CLJS.Jm(b,!0);return a.h?a.h(g,h):a.call(null,g,h)}function d(g){return a.g?a.g(g):a.call(null,g)}function e(){return a.v?a.v():a.call(null)}
var f=null;f=function(g,h){switch(arguments.length){case 0:return e.call(this);case 1:return d.call(this,g);case 2:return c.call(this,g,h)}throw Error("Invalid arity: "+arguments.length);};f.v=e;f.g=d;f.h=c;return f}()}},LG=function(a){null==KG&&(KG=$CLJS.hg($CLJS.ci));EG.call(null,function(){for(var b=$CLJS.G($CLJS.w(KG)),c=null,d=0,e=0;;)if(e<d){var f=c.ha(null,e);try{f.g?f.g(a):f.call(null,a)}catch(g){if(!(g instanceof Error))throw g;}e+=1}else if(b=$CLJS.G(b)){c=b;if($CLJS.Ce(c))b=$CLJS.kd(c),
d=$CLJS.ld(c),c=b,f=$CLJS.J(b),b=d,d=f;else{f=$CLJS.H(c);try{f.g?f.g(a):f.call(null,a)}catch(g){if(!(g instanceof Error))throw g;}b=$CLJS.I(c);c=null;d=0}e=0}else return null})},MG=function(a){var b=$CLJS.ze(a);return b?(a=$CLJS.N.h(a,$CLJS.lj),$CLJS.q(a)?$CLJS.ee.h($CLJS.Kw,a):null):b},NG=function(a,b,c){return $CLJS.Ue(GG(),$CLJS.Te(function(d,e){var f=$CLJS.M(e,0,null);e=$CLJS.M(e,1,null);return a.j?a.j(d,f,e):a.call(null,d,f,e)},$CLJS.Se),b,c)},OG=function(a){return 1<$CLJS.J(a)?$CLJS.Lg(a,1,
$CLJS.J(a)):$CLJS.Dg},QG=function(a,b){return $CLJS.jc.j(function(c,d){return null==d?c:$CLJS.Re(function l(f,g,h){var m=$CLJS.N.j(f,g,PG);if(m===PG)return $CLJS.Bi.j(f,g,h);if($CLJS.q($CLJS.hf.h?$CLJS.hf.h(h,$CLJS.Tx):$CLJS.hf.call(null,h,$CLJS.Tx)))return $CLJS.Vl.h(f,g);if($CLJS.q($CLJS.q($CLJS.Rx)?$CLJS.ze(h)&&$CLJS.ze(m):$CLJS.Rx))return $CLJS.Bi.j(f,g,$CLJS.Re(l,m,h));h=a.h?a.h(m,h):a.call(null,m,h);return $CLJS.q($CLJS.hf.h?$CLJS.hf.h(h,$CLJS.Tx):$CLJS.hf.call(null,h,$CLJS.Tx))?$CLJS.Vl.h(f,
g):$CLJS.Bi.j(f,g,h)},$CLJS.q(c)?c:$CLJS.R,d)},null,b)},RG=function(a){return QG(function(b,c){return c},a)},SG=function(a,b){if($CLJS.q($CLJS.q(" ")?$CLJS.Yl.h(" ",""):" ")){var c=JG(),d=$CLJS.Te($CLJS.oy,$CLJS.v);return $CLJS.q(a)?$CLJS.Ve($CLJS.am.h(a,c),d,b):$CLJS.Ve(c,d,b)}return $CLJS.q(a)?$CLJS.Ve(a,$CLJS.Te($CLJS.oy,$CLJS.v),b):$CLJS.v.g($CLJS.jc.h($CLJS.oy,b))},TG=function(a){return void 0===a||null==a?"nil":a},UG=function(a){var b=$CLJS.dz(a);if($CLJS.q(b))return b;throw $CLJS.dj("Invalid Timbre logging level: should be e/o #{:trace :debug :info :warn :error :fatal :report}",
new $CLJS.p(null,2,[$CLJS.fy,a,$CLJS.sj,$CLJS.fc(a)],null));},VG=function(a,b){return(UG.g?UG.g(a):UG.call(null,a))>=(UG.g?UG.g(b):UG.call(null,b))},WG=function(a,b){a=$CLJS.q($CLJS.qe.g?$CLJS.qe.g(a):$CLJS.qe.call(null,a))?a.g?a.g(b):a.call(null,b):(0,$CLJS.Qz)(a,b);return $CLJS.q(a)?!0:!1},XG=function(a,b,c){b=$CLJS.Be(b)?(0,$CLJS.Rz)(b,c):b;a=$CLJS.q(b)?b:a;return $CLJS.ez.g?$CLJS.ez.g(a):$CLJS.ez.call(null,a)},YG=function(a){if($CLJS.q(a)){var b=$CLJS.di([$CLJS.Dg,$CLJS.ci]);b=b.g?b.g(a):b.call(null,
a);a=$CLJS.q(b)?null:a}else a=null;return a},ZG=function(a,b){a=YG(a);b=YG(b);return $CLJS.q($CLJS.q(a)?a:b)?new $CLJS.p(null,2,[$CLJS.zx,a,$CLJS.Kx,b],null):null},$G=function(a){return SG($CLJS.wi.g(function(b){b=TG(b);return $CLJS.Ae(b)?$CLJS.Di($CLJS.ke([b]),$CLJS.Xb()):b}),a)},aH=function(a){var b=$CLJS.hf.h?$CLJS.hf.h($CLJS.Lx,$CLJS.Lx):$CLJS.hf.call(null,$CLJS.Lx,$CLJS.Lx),c=$CLJS.hf.h?$CLJS.hf.h($CLJS.Ez,$CLJS.ol):$CLJS.hf.call(null,$CLJS.Ez,$CLJS.ol),d=$CLJS.M(a,0,null);if($CLJS.q($CLJS.q(b)?
d instanceof Error:b)){var e=d,f=null,g=OG(a);a=$CLJS.q(c)?$CLJS.M(g,0,null):null;c=$CLJS.q(c)?OG(g):g;return new $CLJS.S(null,4,5,$CLJS.T,[e,f,a,c],null)}f=$CLJS.q(function(){var l=$CLJS.ze(d);return l?$CLJS.Ub.g($CLJS.te(d)):l}())?d:null;e=function(){var l=$CLJS.Cz.g(f);return $CLJS.q(l)?l:$CLJS.q(b)?null:$CLJS.Lx}();var h=$CLJS.Vl.h(f,$CLJS.Cz);g=$CLJS.q(h)?OG(a):a;a=$CLJS.q(c)?$CLJS.M(g,0,null):null;c=$CLJS.q(c)?OG(g):g;return new $CLJS.S(null,4,5,$CLJS.T,[e,h,a,c],null)},bH=function(a,b,c){var d=
$CLJS.q(c)?c:$CLJS.Oz;c=function(){var e=$CLJS.N.h(d,$CLJS.Cy);e=$CLJS.q(e)?e:$CLJS.N.h(d,$CLJS.sy);return XG.j?XG.j(a,e,"com.kubelt.lib.init"):XG.call(null,a,e,"com.kubelt.lib.init")}();return $CLJS.q(VG.h?VG.h(b,c):VG.call(null,b,c))?(b=function(){var e=$CLJS.N.h(d,$CLJS.Fz);if($CLJS.q(e))return e;e=$CLJS.N.h(d,$CLJS.zz);var f=$CLJS.N.h(d,$CLJS.jz);return ZG.h?ZG.h(e,f):ZG.call(null,e,f)}(),$CLJS.q(b)?$CLJS.q(WG.h?WG.h(b,"com.kubelt.lib.init"):WG.call(null,b,"com.kubelt.lib.init"))?!0:!1:!0):!1},
dH=function(a,b,c,d){var e=$CLJS.Oz;if(bH($CLJS.yk,a,e)){var f=new Date;c=$CLJS.w(c);c=aH(c);var g=$CLJS.M(c,0,null),h=$CLJS.M(c,1,null),l=$CLJS.M(c,2,null);c=$CLJS.M(c,3,null);f=$CLJS.O.h($CLJS.q(null)?null:$CLJS.R,$CLJS.co([$CLJS.Dz,$CLJS.wz,$CLJS.tv,$CLJS.By,$CLJS.uz,$CLJS.mz,$CLJS.kz,$CLJS.sy,$CLJS.Gx,$CLJS.Iz,$CLJS.Hx,$CLJS.hz,$CLJS.vy,$CLJS.oz],[f,null,e,c,null,function(){var y=new $CLJS.bi(null,new $CLJS.p(null,2,[$CLJS.Lk,null,$CLJS.Gk,null],null),null);return y.g?y.g(a):y.call(null,a)}(),
"com.kubelt.lib.init",a,g,null,b,new $CLJS.Ii(function(){return g}),h,l]));f=$CLJS.jc.j(function(y,z){y=z.g?z.g(y):z.call(null,y);return null==y?$CLJS.Ud(null):y},f,$CLJS.sz.g(e));if($CLJS.q(f)){c=$CLJS.Lf(f);var m=$CLJS.N.h(c,$CLJS.By);f=$CLJS.Bi.j(f,$CLJS.iz,new $CLJS.Ii(function(){return m}));var r=cH.A(f,$CLJS.Fx,new $CLJS.Ii(function(){if($CLJS.ee.h(null,$CLJS.Ez))return"";if($CLJS.ee.h($CLJS.Ez,$CLJS.Ez))return $G(m);if($CLJS.ee.h($CLJS.ol,$CLJS.Ez)){if("string"!==typeof l)throw $CLJS.dj("Timbre format-style logging call without a format pattern (string)",
new $CLJS.p(null,2,[$CLJS.sy,a,$CLJS.qz,[$CLJS.v.g($CLJS.q("com.kubelt.lib.init")?"com.kubelt.lib.init":$CLJS.q(null)?null:"?"),":",$CLJS.v.g($CLJS.q(b)?b:"?")].join("")],null));var y=$CLJS.q(l)?l:"",z=$CLJS.bm.h(TG,m);return $CLJS.Df($CLJS.Ja,y,z)}throw Error(["No matching clause: ",$CLJS.v.g($CLJS.Ez)].join(""));}),$CLJS.ke([$CLJS.tz,new $CLJS.Ii(function(){return $CLJS.Dd(function(){var y=$CLJS.N.h(h,$CLJS.Jl);if($CLJS.q(y))return new $CLJS.S(null,3,5,$CLJS.T,[y,d,a],null);y=$CLJS.N.h(h,$CLJS.xz);
if($CLJS.q(y))return y;y=$CLJS.N.h(h,$CLJS.iy);return $CLJS.q(y)?new $CLJS.S(null,4,5,$CLJS.T,[y,d,l,a],null):new $CLJS.S(null,4,5,$CLJS.T,[m,d,l,a],null)}())})])),t=$CLJS.Li($CLJS.N.j(e,$CLJS.ty,$CLJS.Nz)),u=$CLJS.O.h($CLJS.Mz,$CLJS.N.h(e,$CLJS.rz)),x=$CLJS.Li(function(y){return new $CLJS.Ii(function(){var z=$CLJS.N.h(r,$CLJS.Dz),A=$CLJS.Lf(y);A=$CLJS.N.h(A,$CLJS.Kz);return $CLJS.q($CLJS.hf.h?$CLJS.hf.h(A,$CLJS.lz):$CLJS.hf.call(null,A,$CLJS.lz))?(new Date(z)).toISOString():(new wG(A)).format(z)})});
$CLJS.Re(function(y,z,A){if($CLJS.q(function(){var U=$CLJS.uy.g(A);return $CLJS.q(U)?bH($CLJS.Yj,a,A):U}())){var D=$CLJS.Ay.g(A);if(function(){var U=$CLJS.we(D);if(U)return U;U=(0,$CLJS.Sz)(z,D);var ja=$CLJS.Ky($CLJS.tz.g(r));U=U.g?U.g(ja):U.call(null,ja);return $CLJS.cc(U)}()){var B=$CLJS.Lf(A);y=$CLJS.N.h(B,$CLJS.Bl);$CLJS.N.h(B,$CLJS.zy);var E=function(){var U=$CLJS.ty.g(A);var ja=(ja=null==U)?ja:$CLJS.hf.h?$CLJS.hf.h(U,$CLJS.wy):$CLJS.hf.call(null,U,$CLJS.wy);return $CLJS.q(ja)?t:U}(),K=function(){var U=
$CLJS.rz.g(A);var ja=(ja=null==U)?ja:$CLJS.hf.h?$CLJS.hf.h(U,$CLJS.wy):$CLJS.hf.call(null,U,$CLJS.wy);return $CLJS.q(ja)?x(u):x($CLJS.O.h(u,U))}(),Q=$CLJS.O.h(r,new $CLJS.p(null,5,[$CLJS.Az,z,$CLJS.vz,A,$CLJS.ty,E,$CLJS.xy,new $CLJS.Ii(function(){var U=$CLJS.Bi.j(r,$CLJS.Jz,K);return E.g?E.g(U):E.call(null,U)}),$CLJS.Jz,K],null));B=function(){var U=$CLJS.yz.g(A);return $CLJS.q(U)?U.g?U.g(Q):U.call(null,Q):Q}();return $CLJS.q(B)?y.g?y.g(B):y.call(null,B):null}}return null},null,$CLJS.Gz.g(e))}}return null},
eH=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.Oz=$CLJS.Df(arguments[0],$CLJS.Oz,1<b.length?new $CLJS.Jd(b.slice(1),0,null):null)},fH=function(a){function b(c){return RG($CLJS.ke([c,a]))}eH.g?eH.g(b):eH.call(null,b)},gH=function(a,b){if(null!=a&&null!=a.Qg)a=a.Qg(a,b);else{var c=gH[$CLJS.n(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=gH._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.gc("ICompletable.-resolve!",
a);}return a},hH=function(a,b){if(null!=a&&null!=a.Pg)a=a.Pg(a,b);else{var c=hH[$CLJS.n(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=hH._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.gc("ICompletable.-reject!",a);}return a},iH=function(){var a={},b=new $CLJS.$z(function(c,d){a.resolve=c;return a.reject=d});b.bk=$CLJS.C;b.Qg=function(c,d){return a.resolve(d)};b.Pg=function(c,d){return a.reject(d)};return b},jH=function(a){var b=iH();try{var c=function(e){return gH(b,
e)},d=function(e){return hH(b,e)};a.h?a.h(c,d):a.call(null,c,d)}catch(e){hH(b,e)}return b},kH=function(a,b){return $CLJS.Vz($CLJS.Zz(a),b)},lH=function(a,b){return $CLJS.Xz($CLJS.Zz(a),b)},mH=function(a,b){b=$CLJS.oB($CLJS.gB(a,b));return $CLJS.nm.A($CLJS.ke([new $CLJS.p(null,1,[$CLJS.lj,$CLJS.tl],null),$CLJS.q($CLJS.Ok.g($CLJS.js.g(a)))?new $CLJS.p(null,1,[$CLJS.Ok,$CLJS.Ok.g($CLJS.js.g(a))],null):null,new $CLJS.p(null,1,[$CLJS.Gk,b],null)]))},oH=function(a,b){function c(d){var e=$CLJS.M(d,0,null);
d=$CLJS.M(d,1,null);return"string"===typeof e?new $CLJS.S(null,2,5,$CLJS.T,[b.g?b.g(e):b.call(null,e),d],null):new $CLJS.S(null,2,5,$CLJS.T,[e,d],null)}return nH(function(d){return $CLJS.ze(d)?$CLJS.gh.h($CLJS.R,$CLJS.wi.h(c,d)):d},a)},pH=function(a){if($CLJS.q(!0)){var b=$CLJS.$D();var c=$CLJS.N.h($CLJS.LD,$CLJS.JD);a=b.read(a);c=oH(a,c)}else c=$CLJS.lE(a);return c},qH=function(){},rH=function(a,b){if(null!=a&&null!=a.zg)a=a.zg(a,b);else{var c=rH[$CLJS.n(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):
c.call(null,a,b);else if(c=rH._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.gc("HttpClient.request!",a);}return a},sH=function(a){return function(b){var c=b.target.getResponseText(),d=b.target.getStatus();b=b.target.getResponseHeader("content-type");d=new $CLJS.p(null,2,[$CLJS.Yw,d,$CLJS.Hl,""],null);switch($CLJS.q($CLJS.q(b)?!$CLJS.Ob(null==b?"":String(b)):b)?$CLJS.Yu(b):null){case "application/json; charset\x3dutf-8":return c=$CLJS.Bi.j(d,$CLJS.Nk,pH(c)),a.g?a.g(c):a.call(null,c);case "text/plain;charset\x3dutf-8":return c=
$CLJS.Bi.j(d,$CLJS.Nk,c),a.g?a.g(c):a.call(null,c);default:return c=$CLJS.Bi.j(d,$CLJS.Nk,c),a.g?a.g(c):a.call(null,c)}}},tH=function(a){var b=new $CLJS.bi(null,new $CLJS.p(null,1,["user-agent",null],null),null);return $CLJS.gh.h($CLJS.R,HG(function(c){var d=$CLJS.M(c,0,null);$CLJS.M(c,1,null);return $CLJS.Ie(b,$CLJS.Yu((0,$CLJS.rb)(d)))},a))},uH=function(a,b,c){this.Nb=a;this.Ba=b;this.H=c;this.B=2230716170;this.F=139264},vH=function(a,b,c){var d=$CLJS.N.h(a,$CLJS.Cl);$CLJS.q(d)||(d=$CLJS.hi(c),
d=$CLJS.xi.h(d,"scheme"),d=$CLJS.N.h(b,d),d=$CLJS.q(d)?d:$CLJS.Ak);var e=$CLJS.N.h(a,$CLJS.Vj);$CLJS.q(e)||(e=$CLJS.hi(c),e=$CLJS.xi.h(e,"host"),e=$CLJS.N.h(b,e),e=$CLJS.q(e)?e:"127.0.0.1");var f=$CLJS.N.h(a,$CLJS.il);$CLJS.q(f)?c=f:(c=$CLJS.hi(c),c=$CLJS.xi.h(c,"port"),c=$CLJS.N.h(b,c),c=$CLJS.q(c)?c:5001);f=$CLJS.N.h(b,$CLJS.mA);b=$CLJS.N.h(b,$CLJS.fA);return $CLJS.Bi.j($CLJS.Bi.j($CLJS.Bi.j($CLJS.Bi.j($CLJS.Bi.j(a,$CLJS.Cl,d),$CLJS.Vj,e),$CLJS.il,c),$CLJS.yF,f),$CLJS.vF,b)},wH=function(a,b,c){b=
vH($CLJS.wk.g((0,$CLJS.PE)()),b,c);return rH(a,b).then(function(d){var e=$CLJS.N.h(d,$CLJS.Jl);e=$CLJS.q(e)?e:$CLJS.sl;var f=$CLJS.N.h(d,$CLJS.pF);f=$CLJS.q(f)?f:$CLJS.sl;var g=$CLJS.N.h(d,$CLJS.sF);$CLJS.q(g)?(g=$CLJS.H($CLJS.av(g,/\//)),g=$CLJS.xi.g(g)):g=$CLJS.sl;var h=$CLJS.N.h(d,$CLJS.sF);$CLJS.q(h)?(h=$CLJS.H($CLJS.I($CLJS.av(h,/\//))),h=$CLJS.av(h,/\./),h=$CLJS.qv(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EF,$CLJS.HF,$CLJS.GF],null),h)):h=$CLJS.sl;var l=$CLJS.N.h(d,$CLJS.CF);l=$CLJS.q(l)?l:$CLJS.sl;
var m=$CLJS.N.h(d,$CLJS.tF);m=$CLJS.q(m)?m:$CLJS.sl;d=$CLJS.N.h(d,$CLJS.zF);return new $CLJS.p(null,7,[$CLJS.qF,e,$CLJS.KF,f,$CLJS.BF,g,$CLJS.AF,h,$CLJS.xF,m,$CLJS.DF,l,$CLJS.JF,$CLJS.q(d)?d:$CLJS.sl],null)})},xH=function(a){var b=$CLJS.co([$CLJS.mA,$CLJS.sA,$CLJS.kA,$CLJS.jA,$CLJS.lA,$CLJS.nA,$CLJS.fA,$CLJS.gA,$CLJS.pA],[!0,$CLJS.Ak,5E3,$CLJS.Ak,"127.0.0.1",5001,!0,"127.0.0.1",5001]),c=$CLJS.nm.A($CLJS.ke([b,a]));if($CLJS.cc($CLJS.iB($CLJS.AA,c)))return mH($CLJS.AA,c);a=$CLJS.Ie(c,$CLJS.Hk)?function(){var e=
$CLJS.N.h(c,$CLJS.Hk);return new $CLJS.p(null,1,[$CLJS.Hk,e],null)}():new $CLJS.p(null,1,[$CLJS.Hk,new uH(null,null,null)],null);a=$CLJS.Lf(a);var d=$CLJS.N.h(a,$CLJS.Hk);return(null!=d?$CLJS.C===d.Zh||(d.Nc?0:$CLJS.ec(qH,d)):$CLJS.ec(qH,d))?(b=$CLJS.N.j(c,$CLJS.bA,!0),a=$CLJS.cc(b)?$CLJS.Zz($CLJS.R):wH(d,c,$CLJS.wF),b=$CLJS.cc(b)?$CLJS.Zz($CLJS.R):wH(d,c,$CLJS.IF),$CLJS.Tz($CLJS.Zz($CLJS.$z.all($CLJS.kc(new $CLJS.S(null,2,5,$CLJS.T,[a,b],null)))),$CLJS.Eg).then(function(e){var f=$CLJS.M(e,0,null);
e=$CLJS.M(e,1,null);return $CLJS.Bi.j($CLJS.Bi.j(new $CLJS.p(null,2,[$CLJS.lj,$CLJS.rF,$CLJS.Hk,d],null),$CLJS.uF,f),$CLJS.FF,e)})):new $CLJS.p(null,2,[$CLJS.lj,$CLJS.tl,$CLJS.Gk,"invalid HTTP client"],null)},yH=function(a){return jH(function(b){var c=$CLJS.kE(a);window.localStorage.setItem("com.kubelt.lib.storage.browser.db",c);c=new $CLJS.p(null,1,[$CLJS.fj,c],null);return b.g?b.g(c):b.call(null,c)})},zH=function(){return jH(function(a){var b=window.localStorage.getItem("com.kubelt.lib.storage.browser.db");
b=$CLJS.lE(b);return a.g?a.g(b):a.call(null,b)})};$CLJS.Ma.prototype.Qc=$CLJS.ca(2,function(){if(this.sd)for(;this.sd.length;)this.sd.shift()()});$CLJS.lb.prototype.Qc=$CLJS.ca(1,function(){$CLJS.lb.Zd.Qc.call(this);if(this.Wb){var a=this.Wb,b=0,c;for(c in a.Jb){for(var d=a.Jb[c],e=0;e<d.length;e++)++b,$CLJS.Sa(d[e]);delete a.Jb[c];a.$d--}}this.Uf=null});var TF={},jG="",gG=/^https?$/i,AH=["POST","PUT"],BH=[];$CLJS.ka(kG,$CLJS.lb);$CLJS.k=kG.prototype;
$CLJS.k.Jh=function(){if(!this.Ed&&(this.Ed=!0,this.Qc(),0!=$CLJS.Ka)){var a=$CLJS.ia(this);if(0!=$CLJS.Ka&&this.sd&&0<this.sd.length)throw Error(this+" did not empty its onDisposeCallbacks queue. This probably means it overrode dispose() or disposeInternal() without calling the superclass' method.");delete $CLJS.La[a]}$CLJS.Ea(BH,this)};$CLJS.k.setTrustToken=function(a){this.$f=a};
$CLJS.k.send=function(a,b,c,d){if(this.ga)throw Error("[goog.net.XhrIo] Object is active with another request\x3d"+this.Kf+"; newUri\x3d"+a);b=b?b.toUpperCase():"GET";this.Kf=a;this.ne="";this.Ef=!1;this.Ac=!0;this.ga=this.kf?$F(this.kf):$F($CLJS.Lb);this.jf=this.kf?ZF(this.kf):ZF($CLJS.Lb);this.ga.onreadystatechange=SF(this.Og,this);this.sj&&"onprogress"in this.ga&&(this.ga.onprogress=SF(function(f){this.Ng(f,!0)},this),this.ga.upload&&(this.ga.upload.onprogress=SF(this.Ng,this)));try{this.getStatus(),
this.Jf=!0,this.ga.open(b,String(a),!0),this.Jf=!1}catch(f){this.getStatus();eG(this,f);return}a=c||"";c=new Map(this.headers);if(d)if(Object.getPrototypeOf(d)===Object.prototype)for(var e in d)c.set(e,d[e]);else if("function"===typeof d.keys&&"function"===typeof d.get)for(const f of d.keys())c.set(f,d.get(f));else throw Error("Unknown input type for opt_headers: "+String(d));d=Array.from(c.keys()).find(f=>"content-type"==f.toLowerCase());e=$CLJS.qb.FormData&&a instanceof $CLJS.qb.FormData;!(0<=(0,$CLJS.Da)(AH,
b))||d||e||c.set("Content-Type","application/x-www-form-urlencoded;charset\x3dutf-8");for(const [f,g]of c)this.ga.setRequestHeader(f,g);this.Yg&&(this.ga.responseType=this.Yg);"withCredentials"in this.ga&&this.ga.withCredentials!==this.dh&&(this.ga.withCredentials=this.dh);if("setTrustToken"in this.ga&&this.$f)try{this.ga.setTrustToken(this.$f)}catch(f){this.getStatus()}try{aG(this),0<this.df&&(this.cg=bG(this.ga),this.getStatus(),this.cg?(this.ga.timeout=this.df,this.ga.ontimeout=SF(this.$g,this)):
this.cf=XF(this.$g,this.df,this)),this.getStatus(),this.me=!0,this.ga.send(a),this.me=!1}catch(f){this.getStatus(),eG(this,f)}};$CLJS.k.$g=function(){"undefined"!=typeof $CLJS.pb&&this.ga&&(this.ne="Timed out after "+this.df+"ms, aborting",this.getStatus(),this.dispatchEvent("timeout"),this.abort(8))};$CLJS.k.abort=function(){this.ga&&this.Ac&&(this.getStatus(),this.Ac=!1,this.Uc=!0,this.ga.abort(),this.Uc=!1,this.dispatchEvent("complete"),this.dispatchEvent("abort"),dG(this))};
$CLJS.k.Qc=function(){this.ga&&(this.Ac&&(this.Ac=!1,this.Uc=!0,this.ga.abort(),this.Uc=!1),dG(this,!0));kG.Zd.Qc.call(this)};$CLJS.k.Og=function(){this.Ed||(this.Jf||this.me||this.Uc?hG(this):this.gj())};$CLJS.k.gj=function(){hG(this)};$CLJS.k.Ng=function(a,b){this.dispatchEvent(iG(a,"progress"));this.dispatchEvent(iG(a,b?"downloadprogress":"uploadprogress"))};$CLJS.k.getStatus=function(){try{return 2<fG(this)?this.ga.status:-1}catch(a){return-1}};
$CLJS.k.getResponseText=function(){try{return this.ga?this.ga.responseText:""}catch(a){return""}};$CLJS.k.getResponseHeader=function(a){if(this.ga&&4==fG(this))return a=this.ga.getResponseHeader(a),null===a?void 0:a};$CLJS.k.getAllResponseHeaders=function(){return this.ga&&4==fG(this)?this.ga.getAllResponseHeaders()||"":""};
var CH={jh:["BC","AD"],ih:["Before Christ","Anno Domini"],lh:"JFMAMJJASOND".split(""),rh:"JFMAMJJASOND".split(""),kh:"January February March April May June July August September October November December".split(" "),qh:"January February March April May June July August September October November December".split(" "),nh:"Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec".split(" "),th:"Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec".split(" "),wh:"Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),
vh:"Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),ph:"Sun Mon Tue Wed Thu Fri Sat".split(" "),uh:"Sun Mon Tue Wed Thu Fri Sat".split(" "),Mj:"SMTWTFS".split(""),sh:"SMTWTFS".split(""),oh:["Q1","Q2","Q3","Q4"],mh:["1st quarter","2nd quarter","3rd quarter","4th quarter"],fh:["AM","PM"],eg:["EEEE, MMMM d, y","MMMM d, y","MMM d, y","M/d/yy"],ig:["h:mm:ss a zzzz","h:mm:ss a z","h:mm:ss a","h:mm a"],gh:["{1} 'at' {0}","{1} 'at' {0}","{1}, {0}","{1}, {0}"],fg:6,Oj:[5,6],gg:5},tG=CH;
tG=CH;var xG=[/^'(?:[^']|'')*('|$)/,/^(?:G+|y+|Y+|M+|k+|S+|E+|a+|h+|K+|H+|c+|L+|Q+|d+|m+|s+|v+|V+|w+|z+|Z+)/,/^[^'GyYMkSEahKHcLQdmsvVwzZ]+/];
wG.prototype.format=function(a,b){if(!a)throw Error("The date to format must be non-null.");var c=b?6E4*(a.getTimezoneOffset()-(b.bf-pG(b,a))):0;let d=c?new Date(a.getTime()+c):a,e=d;b&&d.getTimezoneOffset()!=a.getTimezoneOffset()&&(d=new Date(d.getTime()+6E4*(d.getTimezoneOffset()-a.getTimezoneOffset())),e=new Date(a.getTime()+(c+(0<c?-864E5:864E5))));c=[];for(let f=0;f<this.$e.length;++f){const g=this.$e[f].text;1==this.$e[f].type?c.push(zG(this,g,a,d,e,b)):c.push(g)}return c.join("")};var AG=null;
FG.prototype.add=function(a){return this.l.push(a)};FG.prototype.size=function(){return this.l.length};FG.prototype.clear=function(){return this.l=[]};FG.prototype.Md=function(){return 0===this.l.length};
var KG=null,nH=function nH(a,b){return IG($CLJS.fg(nH,a),a,b)},cH=function cH(a){switch(arguments.length){case 3:return cH.j(arguments[0],arguments[1],arguments[2]);case 2:return cH.h(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return cH.A(arguments[0],arguments[1],arguments[2],new $CLJS.Jd(c.slice(3),0,null))}};cH.j=function(a,b,c){return $CLJS.Ie(a,b)?a:$CLJS.Bi.j(a,b,c)};cH.A=function(a,b,c,d){return NG(cH,cH.j(a,b,c),d)};
cH.N=function(a){var b=$CLJS.H(a),c=$CLJS.I(a);a=$CLJS.H(c);var d=$CLJS.I(c);c=$CLJS.H(d);d=$CLJS.I(d);return this.A(b,a,c,d)};cH.h=function(a,b){return $CLJS.Re(function(c,d,e){return $CLJS.Ie(c,d)?c:$CLJS.Bi.j(c,d,e)},null==a?$CLJS.R:a,b)};cH.I=3;var PG={};$CLJS.k=uH.prototype;$CLJS.k.Ga=function(a,b){return this.Z(null,b,null)};$CLJS.k.Z=function(a,b,c){return $CLJS.N.j(this.Ba,b,c)};
$CLJS.k.Ec=function(a,b,c){return $CLJS.jc.j(function(d,e){var f=$CLJS.M(e,0,null);e=$CLJS.M(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};$CLJS.k.Y=function(a,b,c){return $CLJS.qi(b,function(d){return $CLJS.qi(b,$CLJS.vi,""," ","",c,d)},"#com.kubelt.lib.http.browser.HttpClient{",", ","}",c,$CLJS.Xl.h($CLJS.Dg,this.Ba))};$CLJS.k.vb=function(){return new $CLJS.Pw(this,0,$CLJS.Dg,$CLJS.q(this.Ba)?$CLJS.qd(this.Ba):$CLJS.Qf())};$CLJS.k.J=function(){return this.Nb};
$CLJS.k.ta=function(){return new uH(this.Nb,this.Ba,this.H)};$CLJS.k.pa=function(){return 0+$CLJS.J(this.Ba)};$CLJS.k.fa=function(){var a=this.H;return null!=a?a:this.H=a=-1681191703^$CLJS.Sd(this)};$CLJS.k.P=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.ee.h(this.Ba,b.Ba)};$CLJS.k.Zh=$CLJS.C;
$CLJS.k.zg=function(a,b){return jH(function(c,d){if($CLJS.cc($CLJS.iB($CLJS.jx,b)))return c=mH($CLJS.jx,b),d.g?d.g(c):d.call(null,c);d=$CLJS.hi($CLJS.N.j(b,$CLJS.Cl,""));var e=$CLJS.Vj.g(b),f=$CLJS.il.g(b);f=$CLJS.q(f)?[":",$CLJS.v.g(f)].join(""):null;var g=$CLJS.Dj.g(b);d=$CLJS.Xu("",HG($CLJS.Bm,new $CLJS.S(null,5,5,$CLJS.T,[d,"://",e,f,g],null)));if($CLJS.ze(d)&&$CLJS.ee.h($CLJS.tl,$CLJS.N.h(d,$CLJS.lj)))return d;e=$CLJS.hi($CLJS.Zk.g(b)).toUpperCase();f=$CLJS.Nk.g(b);g=tH($CLJS.N.j(b,$CLJS.Hl,
$CLJS.R));g=$CLJS.ZD(g);c=sH(c);const h=new kG;BH.push(h);c&&h.Wb.add("complete",c,!1,void 0,void 0);h.Wb.add("ready",h.Jh,!0,void 0,void 0);h.send(d,e,f,g);return h})};$CLJS.k.jd=function(a,b){return $CLJS.Ie($CLJS.ci,b)?$CLJS.Vl.h($CLJS.Nc($CLJS.gh.h($CLJS.R,this),this.Nb),b):new uH(this.Nb,$CLJS.Mf($CLJS.Vl.h(this.Ba,b)),null)};$CLJS.k.Qb=function(a,b){return $CLJS.Ie(this.Ba,b)};$CLJS.k.ub=function(a,b,c){return new uH(this.Nb,$CLJS.Bi.j(this.Ba,b,c),null)};
$CLJS.k.ia=function(){return $CLJS.G($CLJS.Xl.h($CLJS.Dg,this.Ba))};$CLJS.k.K=function(a,b){return new uH(b,this.Ba,this.H)};$CLJS.k.oa=function(a,b){return $CLJS.Be(b)?this.ub(null,$CLJS.oe(b,0),$CLJS.oe(b,1)):$CLJS.jc.j($CLJS.qc,this,b)};
var DH=new $CLJS.P("log","msg","log/msg",-1386006224),EH=new $CLJS.P(null,"mock","mock",-383657858),FH=new $CLJS.P("com.kubelt.lib.init","mocking-ipfs-client","com.kubelt.lib.init/mocking-ipfs-client",1650930594),GH=new $CLJS.P("com.kubelt.lib.init","error","com.kubelt.lib.init/error",-1609149095),HH=new $CLJS.P("oort","address","oort/address",573877380);$CLJS.Zi($CLJS.Bw,$CLJS.Jk,function(a,b){$CLJS.q(b)&&fH(new $CLJS.p(null,1,[$CLJS.Cy,b],null));return b});$CLJS.Zi($CLJS.Cw,$CLJS.Jk,function(){return dH($CLJS.rl,51,new $CLJS.Ii(function(){return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.p(null,1,[DH,"halt logging"],null)],null)}),-579703902)});$CLJS.Zi($CLJS.Bw,$CLJS.xl,function(a,b){return b});$CLJS.Zi($CLJS.Bw,$CLJS.Ij,function(a,b){return b});$CLJS.Zi($CLJS.Bw,$CLJS.pl,function(a,b){return b});$CLJS.Zi($CLJS.Bw,$CLJS.Al,function(a,b){return b});
$CLJS.Zi($CLJS.Bw,$CLJS.gj,function(a,b){return b});$CLJS.Zi($CLJS.Bw,$CLJS.Tj,function(a,b){return b});$CLJS.Zi($CLJS.Bw,$CLJS.oj,function(a,b){return b});$CLJS.Zi($CLJS.Bw,$CLJS.ql,function(a,b){return b});$CLJS.Zi($CLJS.Bw,$CLJS.ml,function(a,b){return b});$CLJS.Zi($CLJS.Bw,$CLJS.vk,function(a,b){return b});$CLJS.Zi($CLJS.Bw,$CLJS.jk,function(a,b){return b});
$CLJS.Zi($CLJS.Bw,$CLJS.xv,function(){return function(){dH($CLJS.rl,125,new $CLJS.Ii(function(){return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.p(null,1,[DH,"init HTTP client"],null)],null)}),-269169874);return new uH(null,null,null)}()});$CLJS.Zi($CLJS.Cw,$CLJS.xv,function(){return dH($CLJS.rl,132,new $CLJS.Ii(function(){return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.p(null,1,[DH,"halt HTTP client"],null)],null)}),-1486996300)});
$CLJS.Zi($CLJS.Bw,$CLJS.Sw,function(a,b){function c(){return[$CLJS.hi(d),"://",$CLJS.v.g(e),":",$CLJS.v.g(f)].join("")}var d=$CLJS.rv(b,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Qw,$CLJS.wv],null)),e=$CLJS.rv(b,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Qw,$CLJS.vv],null)),f=$CLJS.rv(b,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Qw,$CLJS.uv],null));a=$CLJS.rv(b,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rw,$CLJS.wv],null));var g=$CLJS.rv(b,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rw,$CLJS.vv],null)),h=$CLJS.rv(b,new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.Rw,$CLJS.uv],null));b=$CLJS.N.h(b,$CLJS.xv);var l=c(d,e,f),m=c(a,g,h);dH($CLJS.rl,163,new $CLJS.Ii(function(){return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.p(null,3,[DH,"init IPFS client",$CLJS.Qw,l,$CLJS.Rw,m],null)],null)}),-1867387766);return lH(kH(xH(new $CLJS.p(null,7,[$CLJS.Hk,b,$CLJS.jA,d,$CLJS.lA,e,$CLJS.pA,f,$CLJS.sA,a,$CLJS.gA,g,$CLJS.nA,h],null)),function(r){return $CLJS.Zz(r)}),function(r){dH($CLJS.Lk,179,new $CLJS.Ii(function(){return new $CLJS.S(null,2,5,$CLJS.T,
[GH,r],null)}),1323770463);dH($CLJS.Lk,180,new $CLJS.Ii(function(){return new $CLJS.S(null,2,5,$CLJS.T,[FH,"TODO: FIX IN CI"],null)}),-1188731136);return $CLJS.Zz(new $CLJS.p(null,4,[$CLJS.lj,$CLJS.rF,$CLJS.Hk,EH,$CLJS.uF,"http:///ip4/127.0.0.1/tcp/5001",$CLJS.FF,"http:///ip4/127.0.0.1/tcp/5001"],null))})});$CLJS.Zi($CLJS.Cw,$CLJS.Sw,function(){return dH($CLJS.rl,188,new $CLJS.Ii(function(){return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.p(null,1,[DH,"halt IPFS client"],null)],null)}),-1033070285)});
$CLJS.Zi($CLJS.Bw,$CLJS.sv,function(a,b){a=$CLJS.Lf(b);b=$CLJS.N.h(a,$CLJS.wv);var c=$CLJS.N.h(a,$CLJS.vv),d=$CLJS.N.h(a,$CLJS.uv),e=[$CLJS.hi(b),"://",$CLJS.v.g(c),":",$CLJS.v.g(d)].join("");dH($CLJS.rl,197,new $CLJS.Ii(function(){return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.p(null,2,[DH,"init oort client",HH,e],null)],null)}),-800947489);return a});
$CLJS.Zi($CLJS.Cw,$CLJS.sv,function(){return dH($CLJS.rl,201,new $CLJS.Ii(function(){return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.p(null,1,[DH,"halt oort client"],null)],null)}),1844812370)});
$CLJS.Zi($CLJS.Bw,$CLJS.hl,function(a,b){a=$CLJS.Lf(b);a=$CLJS.N.h(a,$CLJS.Tw);dH($CLJS.rl,207,new $CLJS.Ii(function(){return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.p(null,1,[DH,"init session"],null)],null)}),-1026588441);a=$CLJS.jc.j(function(c,d){var e=$CLJS.M(d,0,null);d=$CLJS.M(d,1,null);if("string"!==typeof d)d=new $CLJS.p(null,2,[$CLJS.lj,$CLJS.tl,$CLJS.Gk,"token is not a string"],null),d=$CLJS.q(null)?$CLJS.Bi.j(d,$CLJS.fj,null):d,d=$CLJS.q(null)?$CLJS.Bi.j(d,$CLJS.Jw,null):d;else{var f=$CLJS.av(d,
/\./),g=$CLJS.M(f,0,null);d=$CLJS.M(f,1,null);f=$CLJS.M(f,2,null);var h=[$CLJS.v.g(g),".",$CLJS.v.g(d)].join("");g=pH(DG(g));d=pH(DG(d));d=new $CLJS.p(null,4,[$CLJS.OF,g,$CLJS.NF,d,$CLJS.Aj,h,$CLJS.Ck,f],null)}return $CLJS.Bi.j(c,e,d)},$CLJS.R,a);return new $CLJS.p(null,2,[$CLJS.lj,$CLJS.Ej,$CLJS.Bj,a],null)});$CLJS.Zi($CLJS.Cw,$CLJS.hl,function(){return dH($CLJS.rl,218,new $CLJS.Ii(function(){return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.p(null,1,[DH,"halt session"],null)],null)}),319288404)});
$CLJS.Zi($CLJS.Bw,$CLJS.kk,function(a,b){dH($CLJS.rl,224,new $CLJS.Ii(function(){return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.p(null,1,[DH,"init wallet"],null)],null)}),1741694028);return $CLJS.cc(MG(b))?null:b});$CLJS.Zi($CLJS.Cw,$CLJS.kk,function(){return dH($CLJS.rl,230,new $CLJS.Ii(function(){return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.p(null,1,[DH,"halt wallet"],null)],null)}),-928947575)});
$CLJS.Zi($CLJS.Bw,$CLJS.rk,function(a,b){a=$CLJS.Lf(b);var c=$CLJS.N.h(a,$CLJS.xl);dH($CLJS.rl,236,new $CLJS.Ii(function(){return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.p(null,2,[DH,"init storage",$CLJS.xl,c],null)],null)}),-643544055);LG(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.PF,c],null));return new $CLJS.p(null,3,[$CLJS.lj,$CLJS.kl,$CLJS.wl,yH,$CLJS.Fl,zH],null)});
$CLJS.Zi($CLJS.Cw,$CLJS.rk,function(){return dH($CLJS.rl,240,new $CLJS.Ii(function(){return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.p(null,1,[DH,"halt storage"],null)],null)}),-212037817)});