var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./com.cognitect.transit.util.js");require("./com.cognitect.transit.types.js");
'use strict';var IC,LC,MC,NC,OC,PC,QC,YC,ZC,$C,aD,bD,cD,dD,eD,fD,gD,hD,iD,jD,kD,KC,mD,JC;IC=function(){return Math.round(15*Math.random()).toString(16)};LC=function(a){if(null==a)return"null";if(a===String)return"string";if(a===Boolean)return"boolean";if(a===Number)return"number";if(a===Array)return"array";if(a===Object)return"map";var b=a[JC];null==b&&("undefined"!=typeof Object.defineProperty?(b=++KC,Object.defineProperty(a,JC,{value:b,enumerable:!1})):a[JC]=b=++KC);return b};
MC=function(a,b){a=a.toString();for(var c=a.length;c<b;c++)a="0"+a;return a};NC=function(){};OC=function(){};PC=function(){};QC=function(){};YC=function(){};ZC=function(){};$C=function(){};aD=function(){};bD=function(){};cD=function(){};dD=function(){};eD=function(){};fD=function(){};gD=function(){};hD=function(){};iD=function(){};jD=function(){};kD=function(){};
$CLJS.lD=function(){this.jb={};this.set(null,new NC);this.set(String,new OC);this.set(Number,new PC);this.set($CLJS.ma,new QC);this.set(Boolean,new YC);this.set(Array,new ZC);this.set(Object,new $C);this.set(Date,new bD);this.set($CLJS.lC,new cD);this.set($CLJS.iC,new dD);this.set($CLJS.jC,new eD);this.set($CLJS.gC,new fD);this.set($CLJS.xC,new gD);this.set($CLJS.qC,new hD);this.set($CLJS.pC,new iD);"undefined"!=typeof $CLJS.qb.lf&&this.set($CLJS.qb.lf,new jD);"undefined"!=typeof Uint8Array&&this.set(Uint8Array,
new kD)};KC=0;mD=(8|3&Math.round(14*Math.random())).toString(16);JC="transit$guid$"+(IC()+IC()+IC()+IC()+IC()+IC()+IC()+IC()+"-"+IC()+IC()+IC()+IC()+"-4"+IC()+IC()+IC()+"-"+mD+IC()+IC()+IC()+"-"+IC()+IC()+IC()+IC()+IC()+IC()+IC()+IC()+IC()+IC()+IC()+IC());NC.prototype.tag=function(){return"_"};NC.prototype.rep=function(){return null};NC.prototype.stringRep=function(){return"null"};OC.prototype.tag=function(){return"s"};OC.prototype.rep=function(a){return a};OC.prototype.stringRep=function(a){return a};
PC.prototype.tag=function(){return"i"};PC.prototype.rep=function(a){return a};PC.prototype.stringRep=function(a){return a.toString()};QC.prototype.tag=function(){return"i"};QC.prototype.rep=function(a){return a.toString()};QC.prototype.stringRep=function(a){return a.toString()};YC.prototype.tag=function(){return"?"};YC.prototype.rep=function(a){return a};YC.prototype.stringRep=function(a){return a.toString()};ZC.prototype.tag=function(){return"array"};ZC.prototype.rep=function(a){return a};
ZC.prototype.stringRep=function(){return null};$C.prototype.tag=function(){return"map"};$C.prototype.rep=function(a){return a};$C.prototype.stringRep=function(){return null};aD.prototype.tag=function(){return"t"};aD.prototype.rep=function(a){return a.getUTCFullYear()+"-"+MC(a.getUTCMonth()+1,2)+"-"+MC(a.getUTCDate(),2)+"T"+MC(a.getUTCHours(),2)+":"+MC(a.getUTCMinutes(),2)+":"+MC(a.getUTCSeconds(),2)+"."+MC(a.getUTCMilliseconds(),3)+"Z"};aD.prototype.stringRep=function(a,b){return b.rep(a)};
bD.prototype.tag=function(){return"m"};bD.prototype.rep=function(a){return a.valueOf()};bD.prototype.stringRep=function(a){return a.valueOf().toString()};bD.prototype.getVerboseHandler=function(){return new aD};cD.prototype.tag=function(){return"u"};cD.prototype.rep=function(a){return a.toString()};cD.prototype.stringRep=function(a){return a.toString()};dD.prototype.tag=function(){return":"};dD.prototype.rep=function(a){return a.La};dD.prototype.stringRep=function(a,b){return b.rep(a)};
eD.prototype.tag=function(){return"$"};eD.prototype.rep=function(a){return a.La};eD.prototype.stringRep=function(a,b){return b.rep(a)};fD.prototype.tag=function(a){return a.tag};fD.prototype.rep=function(a){return a.rep};fD.prototype.stringRep=function(){return null};gD.prototype.tag=function(){return"set"};gD.prototype.rep=function(a){var b=[];a.forEach(function(c){b.push(c)});return $CLJS.hC("array",b)};gD.prototype.stringRep=function(){return null};hD.prototype.tag=function(){return"map"};
hD.prototype.rep=function(a){return a};hD.prototype.stringRep=function(){return null};iD.prototype.tag=function(){return"map"};iD.prototype.rep=function(a){return a};iD.prototype.stringRep=function(){return null};jD.prototype.tag=function(){return"b"};jD.prototype.rep=function(a){return a.toString("base64")};jD.prototype.stringRep=function(){return null};kD.prototype.tag=function(){return"b"};
kD.prototype.rep=function(a){for(var b,c=0,d=a.length,e="",f;c<d;)f=a.subarray(c,Math.min(c+32768,d)),e+=String.fromCharCode.apply(null,f),c+=32768;if("undefined"!=typeof btoa)b=btoa(e);else{a=String(e);d=0;e="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/\x3d";for(f="";a.charAt(d|0)||(e="\x3d",d%1);f+=e.charAt(63&b>>8-d%1*8)){c=a.charCodeAt(d+=.75);if(255<c)throw Error("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.");b=b<<8|c}b=f}return b};
kD.prototype.stringRep=function(){return null};$CLJS.lD.prototype.get=function(a){a="string"===typeof a?this.jb[a]:this.jb[LC(a)];return null!=a?a:this.jb["default"]};$CLJS.lD.prototype.get=$CLJS.lD.prototype.get;$CLJS.lD.prototype.set=function(a,b){var c;if(c="string"===typeof a)a:{switch(a){case "null":case "string":case "boolean":case "number":case "array":case "map":c=!1;break a}c=!0}c?this.jb[a]=b:this.jb[LC(a)]=b};