var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./com.kubelt.ipfs.spec.js");require("./com.kubelt.ipfs.util.js");require("./com.kubelt.ipfs.v0.js");
'use strict';var yE=new $CLJS.P("key","new-name","key/new-name",1288215123),zE=new $CLJS.P("key","force","key/force",781325835),AE=new $CLJS.P("key","old-name","key/old-name",1289216448),BE=new $CLJS.P(null,"verbose","verbose",1694226060),CE=new $CLJS.P("ipns","base","ipns/base",179943726),DE=new $CLJS.P("key","name","key/name",1843781258);$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Create a new keypair.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/key/gen",$CLJS.oA,new $CLJS.p(null,4,[DE,new $CLJS.p(null,4,[$CLJS.X,"arg",$CLJS.W,"The name of the create to create.",$CLJS.Y,!0,$CLJS.Z,$CLJS.HA],null),new $CLJS.P("key","type","key/type",1175544555),new $CLJS.p(null,4,[$CLJS.X,"type",$CLJS.W,"The type of key to create: rsa, ed25519. Default ed25519.",$CLJS.Y,!1,$CLJS.Z,$CLJS.JA],null),new $CLJS.P("key",
"size","key/size",1098828592),new $CLJS.p(null,4,[$CLJS.X,"size",$CLJS.W,"The size of the key to generate.",$CLJS.Y,!1,$CLJS.Z,$CLJS.KA],null),CE,new $CLJS.p(null,4,[$CLJS.X,"ipns-base",$CLJS.W,"Encoding used for keys. Can either be a multibase encoded CID or a base58btc encoded multihash. Takes b58mh|base36|k|base32|b.... Default: base36.",$CLJS.Y,!1,$CLJS.Z,$CLJS.LA],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,
2,5,$CLJS.T,["Name",$CLJS.HA],null),new $CLJS.S(null,2,5,$CLJS.T,["Id",$CLJS.IA],null)],null)],null));
var EE=$CLJS.co([$CLJS.vA,$CLJS.qA,$CLJS.cA,$CLJS.iA,$CLJS.eA,$CLJS.lj,$CLJS.oA,$CLJS.aA,$CLJS.tA],[new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),"/key/import",new $CLJS.p(null,1,[$CLJS.kj,new $CLJS.p(null,4,[$CLJS.X,"key",$CLJS.W,"PEM-encoded key data to send.",$CLJS.Y,!0,$CLJS.Z,$CLJS.Rk],null)],null),"Import a key and print imported key id.",$CLJS.wA,new $CLJS.p(null,2,[DE,new $CLJS.p(null,4,[$CLJS.X,"arg",$CLJS.W,"Name to associate with key in keychain.",
$CLJS.Y,!0,$CLJS.Z,$CLJS.HA],null),CE,new $CLJS.p(null,4,[$CLJS.X,"ipns-base",$CLJS.W,"Encoding used for keys. Can either be a multibase encoded CID or a base58btc encoded multihash. Takes b58mh|base36|k|base32|b.... Default: base36.",$CLJS.Y,!1,$CLJS.Z,$CLJS.LA],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Name",$CLJS.HA],null),new $CLJS.S(null,2,5,$CLJS.T,["Id",$CLJS.IA],null)],null)],null),function(a,b){return new $CLJS.p(null,
2,[$CLJS.lj,$CLJS.$w,$CLJS.Xw,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.p(null,3,[$CLJS.Zw,a,$CLJS.cx,b,$CLJS.ax,"application/octet-stream"],null)],null)],null)}]);$CLJS.uB(EE);
$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"List all local keypairs.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/key/list",$CLJS.oA,new $CLJS.p(null,2,[BE,new $CLJS.p(null,4,[$CLJS.X,"l",$CLJS.W,"Show extra information about keys.",$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null),CE,new $CLJS.p(null,4,[$CLJS.X,"ipns-base",$CLJS.W,"Encoding used for keys. Can either be a multibase encoded CID or a base58btc encoded multihash. Takes b58mh|base36|k|base32|b.... Default: base36.",
$CLJS.Y,!1,$CLJS.Z,$CLJS.LA],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Keys",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Name",$CLJS.HA],null),new $CLJS.S(null,2,5,$CLJS.T,["Id",$CLJS.IA],null)],null)],null)],null)],null)],null));
$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Rename a keypair.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/key/rename",$CLJS.oA,new $CLJS.p(null,4,[AE,new $CLJS.p(null,4,[$CLJS.X,"arg",$CLJS.W,"The name of the key to rename.",$CLJS.Y,!0,$CLJS.Z,$CLJS.HA],null),yE,new $CLJS.p(null,4,[$CLJS.X,"arg",$CLJS.W,"The new name of the key being renamed.",$CLJS.Y,!0,$CLJS.Z,$CLJS.HA],null),zE,new $CLJS.p(null,4,[$CLJS.X,"force",$CLJS.W,"Allow an existing key to be overwritten.",
$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null),CE,new $CLJS.p(null,4,[$CLJS.X,"ipns-base",$CLJS.W,"Encoding used for keys. Can either be a multibase encoded CID or a base58btc encoded multihash. Takes b58mh|base36|k|base32|b.... Default: base36.",$CLJS.Y,!1,$CLJS.Z,$CLJS.LA],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Was",$CLJS.HA],null),new $CLJS.S(null,2,5,$CLJS.T,["Now",$CLJS.HA],null),new $CLJS.S(null,
2,5,$CLJS.T,["Id",$CLJS.IA],null),new $CLJS.S(null,2,5,$CLJS.T,["Overwrite",$CLJS.jj],null)],null)],null));
$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Remove a keypair.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/key/rm",$CLJS.oA,new $CLJS.p(null,3,[DE,new $CLJS.p(null,4,[$CLJS.X,"arg",$CLJS.W,"The names of the keys to remove.",$CLJS.Y,!0,$CLJS.Z,$CLJS.HA],null),BE,new $CLJS.p(null,4,[$CLJS.X,"l",$CLJS.W,"Show extra information about keys.",$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null),CE,new $CLJS.p(null,4,[$CLJS.X,"ipns-base",$CLJS.W,"Encoding used for keys. Can either be a multibase encoded CID or a base58btc encoded multihash. Takes b58mh|base36|k|base32|b.... Default: base36.",
$CLJS.Y,!1,$CLJS.Z,$CLJS.LA],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Keys",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Name",$CLJS.HA],null),new $CLJS.S(null,2,5,$CLJS.T,["Id",$CLJS.IA],null)],null)],null)],null)],null)],null));