var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./malli.error.js");require("./malli.util.js");require("./com.kubelt.ipfs.spec.js");
'use strict';var YA,ZA,$A,aB,cB,dB,eB,fB,jB,lB,mB,pB,sB,qB,rB;YA=function(){};ZA=function(a,b){if(null!=a&&null!=a.Nh)a=a.Nh(a,b);else{var c=ZA[$CLJS.n(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=ZA._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.gc("IEncodeClojure.-js-\x3eclj",a);}return a};
$A=function(a){if(null!=a&&null!=a.ba)a=a.ba(a);else{var b=$A[$CLJS.n(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=$A._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.gc("IntoSchema.-type-properties",a);}return a};aB=function(a){if(null!=a&&null!=a.ea)a=a.cache;else{var b=aB[$CLJS.n(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=aB._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.gc("Cached.-cache",a);}return a};
$CLJS.bB=function(a){a:for(var b=a;;)if(b=$CLJS.G(b))b=$CLJS.I(b);else break a;return a};cB=function(a,b){return $CLJS.ze(a)?$CLJS.N.h(a,b):a};dB=function(a,b,c){if(null!=a&&$CLJS.C===a.kb){var d=aB(a);var e=$CLJS.w(d);e=e.g?e.g(b):e.call(null,b);if($CLJS.q(e))return e;a=$CLJS.Ki.R(d,$CLJS.Bi,b,c.g?c.g(a):c.call(null,a));return a.g?a.g(b):a.call(null,b)}return c.g?c.g(a):c.call(null,a)};
eB=function(a){var b=$CLJS.ke([$CLJS.Uj,!0]),c=$CLJS.Lf(b);c=$CLJS.N.h(c,$CLJS.Lj);var d=$CLJS.q(c)?$CLJS.xi:$CLJS.v;return function g(f){return(null!=f?$CLJS.C===f.Rj||(f.Nc?0:$CLJS.ec(YA,f)):$CLJS.ec(YA,f))?ZA(f,$CLJS.Cf($CLJS.Np,b)):$CLJS.Rm(f)?$CLJS.bB($CLJS.wi.h(g,f)):$CLJS.Ag(f)?new $CLJS.$g(g($CLJS.Dc(f)),g($CLJS.Ec(f)),null):$CLJS.Mm(f)?$CLJS.gh.j($CLJS.ne(f),$CLJS.wi.g(g),f):$CLJS.ac(f)?$CLJS.fd($CLJS.jc.j(function(h,l){return $CLJS.yg.h(h,g(l))},$CLJS.dd($CLJS.Dg),f)):$CLJS.fc(f)===Object?
$CLJS.fd($CLJS.jc.j(function(h,l){var m=d.g?d.g(l):d.call(null,l);l=g($CLJS.Pb(f,l));return $CLJS.gd(h,m,l)},$CLJS.dd($CLJS.R),$CLJS.Aa(f))):f}(a)};
fB=function(a){var b=$CLJS.rp(a,null),c=dB(b,$CLJS.Lu,function(d){return $CLJS.zo(d,$CLJS.Dg)});return function(){function d(g,h,l){return(h=$CLJS.G(c.j?c.j(g,h,l):c.call(null,g,h,l)))?new $CLJS.p(null,3,[$CLJS.Pk,b,$CLJS.bk,g,$CLJS.pk,h],null):null}function e(g){return f.j(g,$CLJS.Dg,$CLJS.Dg)}var f=null;f=function(g,h,l){switch(arguments.length){case 1:return e.call(this,g);case 3:return d.call(this,g,h,l)}throw Error("Invalid arity: "+arguments.length);};f.g=e;f.j=d;return f}()};
$CLJS.gB=function(a,b){return fB(a)(b,$CLJS.Dg,$CLJS.Dg)};$CLJS.iB=function(a,b){a=hB.h(a,null);return a.g?a.g(b):a.call(null,b)};jB=function(a,b,c,d){d=$CLJS.q(d)?d:$CLJS.Bo($CLJS.rp($CLJS.Pk.g(a),null));if($CLJS.q(b)){var e=cB($CLJS.fv.g(b),c);$CLJS.q(e)?(e=$CLJS.Zq(e,d),a=e.h?e.h(a,d):e.call(null,a,d)):a=null;return $CLJS.q(a)?a:cB($CLJS.ev.g(b),c)}return null};
lB=function(a,b){a=$CLJS.Lf(a);var c=$CLJS.N.h(a,$CLJS.Pk),d=$CLJS.N.h(a,$CLJS.sj);b=$CLJS.Lf(b);var e=$CLJS.N.j(b,$CLJS.pk,$CLJS.pv),f=$CLJS.N.j(b,$CLJS.sl,!0),g=$CLJS.N.h(b,$CLJS.lv),h=$CLJS.N.j(b,$CLJS.kv,$CLJS.ov),l=jB(a,$CLJS.js.g(c),g,b);if($CLJS.q(l))return l;l=jB(a,kB.g(c),g,b);if($CLJS.q(l))return l;l=jB(a,e.g?e.g(d):e.call(null,d),g,b);if($CLJS.q(l))return l;l=jB(a,function(){var m=$CLJS.ap.g(c);return e.g?e.g(m):e.call(null,m)}(),g,b);if($CLJS.q(l))return l;l=jB(a,$CLJS.js.g(c),h,b);if($CLJS.q(l))return l;
l=jB(a,kB.g(c),h,b);if($CLJS.q(l))return l;d=jB(a,e.g?e.g(d):e.call(null,d),h,b);if($CLJS.q(d))return d;d=jB(a,function(){var m=$CLJS.ap.g(c);return e.g?e.g(m):e.call(null,m)}(),h,b);if($CLJS.q(d))return d;g=$CLJS.q(f)?jB(a,e.g?e.g($CLJS.nv):e.call(null,$CLJS.nv),g,b):f;return $CLJS.q(g)?g:$CLJS.q(f)?jB(a,e.g?e.g($CLJS.nv):e.call(null,$CLJS.nv),h,b):f};
mB=function(a,b,c){a=$CLJS.T;var d=$CLJS.gh.h;var e=$CLJS.Il.g(b);var f=$CLJS.Lf(b);var g=$CLJS.N.h(f,$CLJS.Pk),h=$CLJS.Lf(c);f=$CLJS.N.h(h,$CLJS.lv);h=$CLJS.N.j(h,$CLJS.kv,$CLJS.ov);g=$CLJS.js.g(g);f=cB($CLJS.iv.g(g),f);f=$CLJS.q(f)?f:cB($CLJS.iv.g(g),h);d=d.call($CLJS.gh,e,f);return new $CLJS.S(null,2,5,a,[d,lB(b,c)],null)};
$CLJS.oB=function(a){var b=$CLJS.Lf(a),c=$CLJS.N.h(b,$CLJS.bk);a=$CLJS.N.h(b,$CLJS.pk);var d=$CLJS.Lf(null),e=$CLJS.N.j(d,$CLJS.dv,$CLJS.Mj),f=$CLJS.N.j(d,$CLJS.jv,mB);return $CLJS.q(a)?$CLJS.jc.j(function(g,h){var l=f.j?f.j(b,h,d):f.call(null,b,h,d),m=$CLJS.M(l,0,null);l=$CLJS.M(l,1,null);h=$CLJS.Bi.j(h,$CLJS.Mj,l);h=e.g?e.g(h):e.call(null,h);return nB(g,c,m,h)},null,a):null};
pB=function(a,b){var c=$CLJS.Yh(a);b=$CLJS.ro(b,c);return $CLJS.Re(function(d,e,f){e=$CLJS.N.h(a,e);e=$CLJS.N.h(e,$CLJS.X);var g=$CLJS.N.h(d,e);if(null==g)g=$CLJS.Bi.j,f=$CLJS.Qm(f)?$CLJS.v.g(f):f,d=g.call($CLJS.Bi,d,e,f);else if($CLJS.Be(g)){g=$CLJS.Bi.j;var h=$CLJS.N.h(d,e);f=$CLJS.O.h?$CLJS.O.h(h,f):$CLJS.O.call(null,h,f);d=g.call($CLJS.Bi,d,e,f)}else d=$CLJS.Bi.j(d,e,new $CLJS.S(null,2,5,$CLJS.T,[g,f],null));return d},$CLJS.R,b)};
sB=function(a,b){var c=new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.gk,new $CLJS.p(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,3,5,$CLJS.T,[qB,new $CLJS.p(null,2,[$CLJS.W,"Response callback.",$CLJS.yj,!0],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dl,$CLJS.gk],null),$CLJS.Rk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[rB,new $CLJS.p(null,2,[$CLJS.W,"Error callback.",$CLJS.yj,!0],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dl,$CLJS.gk],
null),$CLJS.Rk],null)],null)],null);a=$CLJS.nm.A($CLJS.ke([a,b]));return $CLJS.Re(function(d,e,f){var g=$CLJS.N.h(f,$CLJS.Z);f=$CLJS.N.h(f,$CLJS.Y);f=$CLJS.cc(f)?$CLJS.Bi.j($CLJS.R,$CLJS.yj,!0):$CLJS.R;e=$CLJS.we(f)?new $CLJS.S(null,2,5,$CLJS.T,[e,g],null):new $CLJS.S(null,3,5,$CLJS.T,[e,f,g],null);return $CLJS.O.h(d,e)},c,a)};
$CLJS.uB=function(a){var b=$CLJS.tB;if(!$CLJS.q($CLJS.iB($CLJS.xA,b)))throw a=$CLJS.oB($CLJS.gB($CLJS.xA,b)),new $CLJS.cj("invalid api base configuration",a);if(!$CLJS.q($CLJS.iB($CLJS.zA,a)))throw a=$CLJS.oB($CLJS.gB($CLJS.zA,a)),new $CLJS.cj("invalid resource configuration",a);var c=$CLJS.N.h(a,$CLJS.eA),d=$CLJS.N.h(a,$CLJS.qA),e=[$CLJS.v.g($CLJS.N.h(b,$CLJS.Kj)),$CLJS.v.g($CLJS.N.h(a,$CLJS.cA))].join(""),f=$CLJS.N.h(a,$CLJS.oA),g=$CLJS.N.j(a,$CLJS.iA,$CLJS.R),h=$CLJS.H($CLJS.Yh(g)),l=$CLJS.X.g($CLJS.H($CLJS.$h(g))),
m=$CLJS.N.h(a,$CLJS.tA),r=$CLJS.N.h(a,$CLJS.uA),t=$CLJS.N.j(a,$CLJS.hA,$CLJS.Dg),u=$CLJS.N.h(a,$CLJS.vA),x=$CLJS.N.h(a,$CLJS.aA),y=sB(f,g);return function(){function z(B){B=$CLJS.ze(B)?B:eB(B);if($CLJS.cc($CLJS.iB(y,B)))return B=$CLJS.oB($CLJS.gB(y,B)),new $CLJS.p(null,2,[$CLJS.lj,$CLJS.tl,$CLJS.Gk,B],null);var E=$CLJS.N.j(B,$CLJS.Zk,$CLJS.lk),K=pB(f,B),Q=$CLJS.N.h(B,h);Q=$CLJS.q($CLJS.q(m)?Q:m)?m.h?m.h(l,Q):m.call(null,l,Q):Q;E=new $CLJS.p(null,4,[$CLJS.lj,$CLJS.nl,$CLJS.Zk,E,$CLJS.Dj,e,$CLJS.bx,
K],null);E=null!=Q?$CLJS.Bi.j(E,$CLJS.Nk,Q):E;E=$CLJS.co([$CLJS.vA,$CLJS.qA,$CLJS.cA,$CLJS.wk,$CLJS.iA,$CLJS.eA,$CLJS.lj,$CLJS.rA,$CLJS.oA,$CLJS.hA,$CLJS.aA,$CLJS.dA],[u,d,e,E,g,c,$CLJS.wA,y,f,t,x,B]);E=$CLJS.qe(r)?$CLJS.Bi.j(E,$CLJS.uA,r):E;E=$CLJS.Ie(B,qB)?$CLJS.Bi.j(E,qB,$CLJS.N.h(B,qB)):E;return $CLJS.Ie(B,rB)?$CLJS.Bi.j(E,rB,$CLJS.N.h(B,rB)):E}function A(){return D.g($CLJS.R)}var D=null;D=function(B){switch(arguments.length){case 0:return A.call(this);case 1:return z.call(this,B)}throw Error("Invalid arity: "+
arguments.length);};D.v=A;D.g=z;return D}()};$CLJS.Qu.prototype.ea=$CLJS.ca(64,function(){return this.cache});$CLJS.lq.prototype.ea=$CLJS.ca(63,function(){return this.cache});$CLJS.qq.prototype.ea=$CLJS.ca(62,function(){return this.cache});$CLJS.uq.prototype.ea=$CLJS.ca(61,function(){return this.cache});$CLJS.yq.prototype.ea=$CLJS.ca(60,function(){return this.cache});$CLJS.Dq.prototype.ea=$CLJS.ca(59,function(){return this.cache});$CLJS.Fq.prototype.ea=$CLJS.ca(58,function(){return this.cache});
$CLJS.Kq.prototype.ea=$CLJS.ca(57,function(){return this.cache});$CLJS.Su.prototype.ea=$CLJS.ca(56,function(){return this.cache});$CLJS.Oq.prototype.ea=$CLJS.ca(55,function(){return this.cache});$CLJS.Sq.prototype.ea=$CLJS.ca(54,function(){return this.cache});$CLJS.Wq.prototype.ea=$CLJS.ca(53,function(){return this.cache});$CLJS.ar.prototype.ea=$CLJS.ca(52,function(){return this.cache});$CLJS.dr.prototype.ea=$CLJS.ca(51,function(){return this.cache});$CLJS.mr.prototype.ea=$CLJS.ca(50,function(){return this.cache});
$CLJS.sr.prototype.ea=$CLJS.ca(49,function(){return this.cache});$CLJS.wr.prototype.ea=$CLJS.ca(48,function(){return this.cache});$CLJS.Cr.prototype.ea=$CLJS.ca(47,function(){return this.cache});$CLJS.Hr.prototype.ea=$CLJS.ca(46,function(){return this.cache});$CLJS.Rr.prototype.ea=$CLJS.ca(45,function(){return this.cache});$CLJS.Ur.prototype.ea=$CLJS.ca(44,function(){return this.cache});$CLJS.Pu.prototype.ba=$CLJS.ca(43,function(){return this.ec});$CLJS.kq.prototype.ba=$CLJS.ca(42,function(){return null});
$CLJS.pq.prototype.ba=$CLJS.ca(41,function(){return null});$CLJS.sq.prototype.ba=$CLJS.ca(40,function(){return null});$CLJS.wq.prototype.ba=$CLJS.ca(39,function(){return null});$CLJS.Bq.prototype.ba=$CLJS.ca(38,function(){return null});$CLJS.Eq.prototype.ba=$CLJS.ca(37,function(){return null});$CLJS.Jq.prototype.ba=$CLJS.ca(36,function(){return null});$CLJS.Ru.prototype.ba=$CLJS.ca(35,function(){return $CLJS.qr.g($CLJS.w(this.Xc))});$CLJS.Nq.prototype.ba=$CLJS.ca(34,function(){return null});
$CLJS.Rq.prototype.ba=$CLJS.ca(33,function(){return null});$CLJS.Vq.prototype.ba=$CLJS.ca(32,function(){return null});$CLJS.Yq.prototype.ba=$CLJS.ca(31,function(){return null});$CLJS.cr.prototype.ba=$CLJS.ca(30,function(){return null});$CLJS.ir.prototype.ba=$CLJS.ca(29,function(){return $CLJS.qr.g(this.Aa)});$CLJS.rr.prototype.ba=$CLJS.ca(28,function(){return this.ec});$CLJS.vr.prototype.ba=$CLJS.ca(27,function(){return null});$CLJS.yr.prototype.ba=$CLJS.ca(26,function(){return null});
$CLJS.Fr.prototype.ba=$CLJS.ca(25,function(){return null});$CLJS.Qr.prototype.ba=$CLJS.ca(24,function(){return null});$CLJS.Tr.prototype.ba=$CLJS.ca(23,function(){return null});
var nB=function nB(a,b,c,d){var f=$CLJS.G(c);c=$CLJS.H(f);var g=$CLJS.I(f),h=$CLJS.xe(b)||$CLJS.Nm(b)?$CLJS.N.h(b,c):null;f=$CLJS.q(a)?a:$CLJS.ye(b)?$CLJS.Dg:$CLJS.Ae(b)?$CLJS.R:$CLJS.ne(b);return $CLJS.q($CLJS.q(c)?$CLJS.cv.g($CLJS.te(f)):c)?a:$CLJS.q(c)?(b=$CLJS.xe(f)||$CLJS.Nm(f)?$CLJS.N.h(f,c):null,d=nB.R?nB.R(b,h,g,d):nB.call(null,b,h,g,d),b="number"===typeof c&&($CLJS.xe(f)||$CLJS.ye(f))?$CLJS.Eg(f):null,$CLJS.q(b)&&(b=$CLJS.J(b),c>b&&(b=c+1-b,f=$CLJS.gh.h(f,0<b?new $CLJS.lg(null,b,null,null,
null):$CLJS.Md))),$CLJS.xe(f)?$CLJS.O.h(f,d):$CLJS.Bi.j(f,c,d)):$CLJS.ze(a)?(c=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.gv],null),nB.R?nB.R(f,b,c,d):nB.call(null,f,b,c,d)):$CLJS.q($CLJS.cv.g($CLJS.te(f)))?$CLJS.O.h(f,d):$CLJS.Be($CLJS.Mf(f))?f:$CLJS.se(new $CLJS.S(null,1,5,$CLJS.T,[d],null),new $CLJS.p(null,1,[$CLJS.cv,!0],null))},hB=function hB(a){switch(arguments.length){case 1:return hB.g(arguments[0]);case 2:return hB.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.v.g(arguments.length)].join(""));
}};hB.g=function(a){return hB.h(a,null)};hB.h=function(a,b){return dB($CLJS.rp(a,b),$CLJS.Oj,$CLJS.yo)};hB.I=2;var kB=function kB(a){switch(arguments.length){case 1:return kB.g(arguments[0]);case 2:return kB.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.v.g(arguments.length)].join(""));}};kB.g=function(a){return kB.h(a,null)};kB.h=function(a,b){return $A($CLJS.Do($CLJS.rp?$CLJS.rp(a,b):$CLJS.sp.call(null,a,b)))};kB.I=2;qB=new $CLJS.P("on","response","on/response",-1068412383);
$CLJS.vB=new $CLJS.P("peer","id-base","peer/id-base",-730711478);$CLJS.wB=new $CLJS.P("dag","root","dag/root",-448821435);$CLJS.xB=new $CLJS.P("ipfs","path","ipfs/path",-191186412);$CLJS.yB=new $CLJS.P("peer","id","peer/id",-1375524622);rB=new $CLJS.P("on","error","on/error",-978963433);$CLJS.zB=new $CLJS.P("service","name","service/name",-266365196);$CLJS.AB=new $CLJS.P(null,"stream","stream",1534941648);