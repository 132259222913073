var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");
'use strict';var Uz,Wz,Yz;
Uz=function(){function a(d,e,f){var g=$CLJS.Tz[$CLJS.n(null==d?null:d)];if(null!=g)return g.j?g.j(d,e,f):g.call(null,d,e,f);g=$CLJS.Tz._;if(null!=g)return g.j?g.j(d,e,f):g.call(null,d,e,f);throw $CLJS.gc("IPromise.-map",d);}function b(d,e){var f=$CLJS.Tz[$CLJS.n(null==d?null:d)];if(null!=f)return f.h?f.h(d,e):f.call(null,d,e);f=$CLJS.Tz._;if(null!=f)return f.h?f.h(d,e):f.call(null,d,e);throw $CLJS.gc("IPromise.-map",d);}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,
e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();$CLJS.Tz=function Tz(a){switch(arguments.length){case 2:return Tz.h(arguments[0],arguments[1]);case 3:return Tz.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.v.g(arguments.length)].join(""));}};$CLJS.Tz.h=function(a,b){return null!=a&&null!=a.Rg?a.Rg(a,b):Uz(a,b)};$CLJS.Tz.j=function(a,b,c){return null!=a&&null!=a.Sg?a.Sg(a,b,c):Uz(a,b,c)};
$CLJS.Tz.I=3;
Wz=function(){function a(d,e,f){var g=$CLJS.Vz[$CLJS.n(null==d?null:d)];if(null!=g)return g.j?g.j(d,e,f):g.call(null,d,e,f);g=$CLJS.Vz._;if(null!=g)return g.j?g.j(d,e,f):g.call(null,d,e,f);throw $CLJS.gc("IPromise.-then",d);}function b(d,e){var f=$CLJS.Vz[$CLJS.n(null==d?null:d)];if(null!=f)return f.h?f.h(d,e):f.call(null,d,e);f=$CLJS.Vz._;if(null!=f)return f.h?f.h(d,e):f.call(null,d,e);throw $CLJS.gc("IPromise.-then",d);}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,
e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();$CLJS.Vz=function Vz(a){switch(arguments.length){case 2:return Vz.h(arguments[0],arguments[1]);case 3:return Vz.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.v.g(arguments.length)].join(""));}};$CLJS.Vz.h=function(a,b){return null!=a&&null!=a.Tg?a.Tg(a,b):Wz(a,b)};$CLJS.Vz.j=function(a,b,c){return null!=a&&null!=a.Ug?a.Ug(a,b,c):Wz(a,b,c)};
$CLJS.Vz.I=3;
Yz=function(){function a(d,e,f){var g=$CLJS.Xz[$CLJS.n(null==d?null:d)];if(null!=g)return g.j?g.j(d,e,f):g.call(null,d,e,f);g=$CLJS.Xz._;if(null!=g)return g.j?g.j(d,e,f):g.call(null,d,e,f);throw $CLJS.gc("IPromise.-thenErr",d);}function b(d,e){var f=$CLJS.Xz[$CLJS.n(null==d?null:d)];if(null!=f)return f.h?f.h(d,e):f.call(null,d,e);f=$CLJS.Xz._;if(null!=f)return f.h?f.h(d,e):f.call(null,d,e);throw $CLJS.gc("IPromise.-thenErr",d);}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,
e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();$CLJS.Xz=function Xz(a){switch(arguments.length){case 2:return Xz.h(arguments[0],arguments[1]);case 3:return Xz.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.v.g(arguments.length)].join(""));}};$CLJS.Xz.h=function(a,b){return null!=a&&null!=a.Vg?a.Vg(a,b):Yz(a,b)};$CLJS.Xz.j=function(a,b,c){return null!=a&&null!=a.Wg?a.Wg(a,b,c):Yz(a,b,c)};
$CLJS.Xz.I=3;