var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./com.cognitect.transit.util.js");require("./com.cognitect.transit.caching.js");require("./com.cognitect.transit.handlers.js");require("./com.cognitect.transit.types.js");require("./com.cognitect.transit.delimiters.js");
'use strict';var nD,oD,qD,rD,sD,tD,uD;nD=function(a){var b=Math.floor(a/44);a=String.fromCharCode(a%44+48);return 0===b?"^"+a:"^"+String.fromCharCode(b+48)+a};oD=function(){this.Hh=this.Hd=this.Ca=0;this.cache={}};qD=function(a,b,c){var d=[];if((0,$CLJS.VB)(b))for(var e=0;e<b.length;e++)d.push($CLJS.pD(a,b[e],!1,c));else b.forEach(function(f){d.push($CLJS.pD(a,f,!1,c))});return d};rD=function(a,b){return"string"!==typeof b?(a=a.Gc(b))&&1===a.tag(b).length:!0};
sD=function(a,b){var c=a.ff(b),d=!0;if(c){for(b=0;b<c.length&&(d=rD(a,c[b]),d);b+=2);return d}if(b.keys){c=b.keys();var e=null;if(c.next){for(e=c.next();!e.done;){d=rD(a,e.value);if(!d)break;e=c.next()}return d}}if(b.forEach)return b.forEach(function(f,g){d=d&&rD(a,g)}),d;throw Error("Cannot walk keys of object type "+(null==b?null:b.constructor).name);};
tD=function(a){if(a.constructor.transit$isObject)return!0;var b=a.constructor.toString();b=b.substr(9);b=b.substr(0,b.indexOf("("));b="Object"==b;"undefined"!=typeof Object.defineProperty?Object.defineProperty(a.constructor,"transit$isObject",{value:b,enumerable:!1}):a.constructor.transit$isObject=b;return b};
uD=function(a,b,c){var d=null,e=null,f=null;d=null;var g=0;if(b.constructor===Object||null!=b.forEach||a.Id&&tD(b)){if(a.be){if(null!=b.forEach)if(sD(a,b)){var h={};b.forEach(function(l,m){h[$CLJS.pD(a,m,!0,!1)]=$CLJS.pD(a,l,!1,c)})}else{d=a.ff(b);e=[];f=a.wb("~#","cmap","",!0,c);if(d)for(;g<d.length;g+=2)e.push($CLJS.pD(a,d[g],!1,!1)),e.push($CLJS.pD(a,d[g+1],!1,c));else b.forEach(function(l,m){e.push($CLJS.pD(a,m,!1,!1));e.push($CLJS.pD(a,l,!1,c))});h={};h[f]=e}else for(d=(0,$CLJS.UB)(b),h={};g<
d.length;g++)h[$CLJS.pD(a,d[g],!0,!1)]=$CLJS.pD(a,b[d[g]],!1,c);return h}if(null!=b.forEach){if(sD(a,b)){d=a.ff(b);h=["^ "];if(d)for(;g<d.length;g+=2)h.push($CLJS.pD(a,d[g],!0,c)),h.push($CLJS.pD(a,d[g+1],!1,c));else b.forEach(function(l,m){h.push($CLJS.pD(a,m,!0,c));h.push($CLJS.pD(a,l,!1,c))});return h}d=a.ff(b);e=[];f=a.wb("~#","cmap","",!0,c);if(d)for(;g<d.length;g+=2)e.push($CLJS.pD(a,d[g],!1,c)),e.push($CLJS.pD(a,d[g+1],!1,c));else b.forEach(function(l,m){e.push($CLJS.pD(a,m,!1,c));e.push($CLJS.pD(a,
l,!1,c))});return[f,e]}h=["^ "];for(d=(0,$CLJS.UB)(b);g<d.length;g++)h.push($CLJS.pD(a,d[g],!0,c)),h.push($CLJS.pD(a,b[d[g]],!1,c));return h}if(null!=a.Mg)return a.Mg(b,function(l){return $CLJS.pD(a,l,!0,c)},function(l){return $CLJS.pD(a,l,!1,c)});g=(null==b?null:b.constructor).name;d=Error("Cannot write "+g);d.data={Sf:b,type:g};throw d;};
$CLJS.pD=function(a,b,c,d){null!==a.transform&&(b=a.transform(b));var e=a.Gc(b)||(a.Id?a.Id(b,a.jb):null),f=e?e.tag(b):null,g=e?e.rep(b):null;if(null!=e&&null!=f)switch(f){case "_":return a.fi(c,d);case "s":return 0<g.length?(b=g.charAt(0),b="~"===b||"^"===b||"`"===b?"~"+g:g):b=g,a.wb("","",b,c,d);case "?":return a.ci(g,c,d);case "i":return a.ei(g,c,d);case "d":return a.di(g,c,d);case "b":return a.bi(g,c,d);case "'":return a.gi(a,g,d);case "array":return qD(a,g,d);case "map":return uD(a,g,d);default:a:{if(1===
f.length){if("string"===typeof g){a=a.wb("~",f,g,c,d);break a}if(c||a.af){(g=a.be&&e.getVerboseHandler())?(f=g.tag(b),g=g.stringRep(b,g)):g=e.stringRep(b,e);if(null!==g){a=a.wb("~",f,g,c,d);break a}a=Error('Tag "'+f+'" cannot be encoded as string');a.data={tag:f,rep:g,Sf:b};throw a;}}c=f;b=g;a.be?(e={},e[a.wb("~#",c,"",!0,d)]=$CLJS.pD(a,b,!1,d),a=e):a=[a.wb("~#",c,"",!0,d),$CLJS.pD(a,b,!1,d)]}return a}else throw a=(null==b?null:b.constructor).name,d=Error("Cannot write "+a),d.data={Sf:b,type:a},d;
};$CLJS.vD=function(a,b){this.td=a;this.options=b||{};this.cache=!1===this.options.cache?null:this.options.cache?this.options.cache:new oD};oD.prototype.write=function(a,b){return $CLJS.WB(a,b)?(4096===this.Hh?(this.clear(),this.Hd=0,this.cache={}):1936===this.Ca&&this.clear(),b=this.cache[a],null==b?(this.cache[a]=[nD(this.Ca),this.Hd],this.Ca++,a):b[1]!=this.Hd?(b[1]=this.Hd,b[0]=nD(this.Ca),this.Ca++,a):b[0]):a};oD.prototype.clear=function(){this.Ca=0;this.Hd++};$CLJS.vD.prototype.yi=function(){return this.td};
$CLJS.vD.prototype.marshaller=$CLJS.vD.prototype.yi;$CLJS.vD.prototype.write=function(a,b){var c=b||{};b=c.asMapKey||!1;var d=this.td.be?!1:this.cache;if(!1===c.marshalTop)a=$CLJS.pD(this.td,a,b,d);else{c=this.td;var e=JSON,f=e.stringify;var g=c.Gc(a)||(c.Id?c.Id(a,c.jb):null);if(null!=g)a=1===g.tag(a).length?$CLJS.hC("'",a):a;else throw b=(null==a?null:a.constructor).name,d=Error("Cannot write "+b),d.data={Sf:a,type:b},d;a=f.call(e,$CLJS.pD(c,a,b,d))}null!=this.cache&&this.cache.clear();return a};
$CLJS.vD.prototype.write=$CLJS.vD.prototype.write;$CLJS.vD.prototype.register=function(a,b){this.td.wj(a,b)};$CLJS.vD.prototype.register=$CLJS.vD.prototype.register;