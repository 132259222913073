var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./com.kubelt.ipfs.spec.js");require("./com.kubelt.ipfs.util.js");require("./com.kubelt.ipfs.v0.js");
'use strict';var cF=new $CLJS.P("stats","size-only?","stats/size-only?",589966566),dF=new $CLJS.P("stats","proto","stats/proto",1255941229),eF=new $CLJS.P("stats","human?","stats/human?",-2140509321),fF=new $CLJS.P("stats","interval","stats/interval",-1690642720),gF=new $CLJS.P("table","name","table/name",1603533819),hF=new $CLJS.P("stats","poll?","stats/poll?",-160399796);$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Show some diagnostic information on the bitswap agent.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/stats/bitswap",$CLJS.oA,new $CLJS.p(null,2,[new $CLJS.P("stats","verbose?","stats/verbose?",1346105405),new $CLJS.p(null,4,[$CLJS.X,"verbose",$CLJS.W,"Return extra information.",$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null),eF,new $CLJS.p(null,4,[$CLJS.X,"human",$CLJS.W,"Print sizes in human-readable format (e.g. 1K, 234M, 2G).",$CLJS.Y,
!1,$CLJS.Z,$CLJS.jj],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,11,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["BlocksReceived",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["BlocksSent",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["DataReceived",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["DataSent",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["DupBlksReceived",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["DupDataReceived",$CLJS.V],
null),new $CLJS.S(null,2,5,$CLJS.T,["MessagesReceiveed",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["Peers",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dk,$CLJS.V],null)],null),new $CLJS.S(null,2,5,$CLJS.T,["ProvideBufLen",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["Wantlist",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["/",$CLJS.V],null)],null)],null)],null)],null)],null));
$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Return IPFS bandwidth information.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/stats/bw",$CLJS.oA,new $CLJS.p(null,4,[$CLJS.yB,new $CLJS.p(null,4,[$CLJS.X,"peer",$CLJS.W,"Specify a peer to return bandwidth for.",$CLJS.Y,!1,$CLJS.Z,$CLJS.V],null),dF,new $CLJS.p(null,4,[$CLJS.X,"proto",$CLJS.W,"Specify a protocol to return bandwidth for.",$CLJS.Y,!1,$CLJS.Z,$CLJS.V],null),hF,new $CLJS.p(null,4,[$CLJS.X,"poll",$CLJS.W,"Print bandwidth at an interval.",
$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null),fF,new $CLJS.p(null,4,[$CLJS.X,"interval",$CLJS.W,"Time interval to wait between updating output, if :stats/poll? is true. Accepts durations such as '300s', '1.5h', or '2h45m'. Valid time units are 'ns', 'us' (or 'µs'), 'ms', 's', 'm', 'h'. Default: 1s.",$CLJS.Y,!1,$CLJS.Z,$CLJS.V],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["RateIn",$CLJS.V],null),new $CLJS.S(null,
2,5,$CLJS.T,["RateOut",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["TotalIn",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["TotalOut",$CLJS.V],null)],null)],null));
$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Return statistics about the node's DHT(s).",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/stats/dht",$CLJS.oA,new $CLJS.p(null,1,[gF,new $CLJS.p(null,4,[$CLJS.X,"arg",$CLJS.W,"The DHT whose table should be listed (wanserver, lanserver, wan, lan). 'wan' and 'lan' refer to client routing tables. When using the experimental DHT client only WAN is supported. Default: 'wan', 'lan'.",$CLJS.Y,!1,$CLJS.Z,$CLJS.UA],null)],null),$CLJS.vA,
new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Buckets",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["LastRefresh",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["Peers",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dk,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["AgentVersion",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["Connected",$CLJS.V],
null),new $CLJS.S(null,2,5,$CLJS.T,["ID",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["LastQueriedAt",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["LastUsefulAt",$CLJS.V],null)],null)],null)],null)],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,["Name",$CLJS.V],null)],null)],null));
$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Return statistics about the node's (re)provider system.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/stats/provide",$CLJS.oA,$CLJS.R,$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["AvgProvideDuration",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["LastReprovideBatchSize",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["LastReprovideDuration",
$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["TotalProvides",$CLJS.V],null)],null)],null));
$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Return statistics for the currently used repo.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/stats/repo",$CLJS.oA,new $CLJS.p(null,2,[cF,new $CLJS.p(null,4,[$CLJS.X,"size-only",$CLJS.W,"Only report RepoSize and StorageMax.",$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null),eF,new $CLJS.p(null,4,[$CLJS.X,"human",$CLJS.W,"Print sizes in human-readable format (e.g. 1K, 234M, 2G).",$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null)],null),$CLJS.vA,new $CLJS.S(null,
1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["NumObjects",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["RepoPath",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["SizeStat",new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["RepoSize",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["StorageMax",$CLJS.V],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,["Version",$CLJS.V],null)],null)],null));