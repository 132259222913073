var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./com.kubelt.ipfs.spec.js");require("./com.kubelt.ipfs.util.js");require("./com.kubelt.ipfs.v0.js");
'use strict';var QE=new $CLJS.P("ipfs","path-old","ipfs/path-old",1640733356),RE=new $CLJS.P("pin","type","pin/type",1175420465),SE=new $CLJS.P("pin","broken?","pin/broken?",513733842),TE=new $CLJS.P("ipfs","path-new","ipfs/path-new",-1115134439),UE=new $CLJS.P("pin","unpin","pin/unpin",-1276128145),VE=new $CLJS.P("pin","verbose?","pin/verbose?",1244980587),WE=new $CLJS.P("pin","recursive","pin/recursive",718735163),XE=new $CLJS.P(null,"quiet","quiet",-1217179100);$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Pin objects to local storage.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/pin/add",$CLJS.oA,new $CLJS.p(null,3,[$CLJS.xB,new $CLJS.p(null,4,[$CLJS.X,"arg",$CLJS.W,"Path to the object(s) to be pinned.",$CLJS.Y,!0,$CLJS.Z,$CLJS.OA],null),WE,new $CLJS.p(null,4,[$CLJS.X,"recursive",$CLJS.W,"Recursively pin the object linked to by the specified object(s). Default: true.",$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null),new $CLJS.P("out",
"progress","out/progress",244737449),new $CLJS.p(null,4,[$CLJS.X,"progress",$CLJS.W,"Show progress.",$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Pins",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dk,$CLJS.V],null)],null),new $CLJS.S(null,2,5,$CLJS.T,["Progress",$CLJS.V],null)],null)],null));
$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"List objects pinned to local storage.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/pin/ls",$CLJS.oA,new $CLJS.p(null,4,[$CLJS.xB,new $CLJS.p(null,4,[$CLJS.X,"arg",$CLJS.W,"Path to the object(s) to be listed.",$CLJS.Y,!1,$CLJS.Z,$CLJS.OA],null),RE,new $CLJS.p(null,4,[$CLJS.X,"type",$CLJS.W,"The type of pinned keys to list. Can be 'direct', 'indirect', 'recursive', or 'all'. Default: all.",$CLJS.Y,!1,$CLJS.Z,new $CLJS.S(null,
5,5,$CLJS.T,[$CLJS.Tk,"direct","indirect","recursive","all"],null)],null),XE,new $CLJS.p(null,4,[$CLJS.X,"quiet",$CLJS.W,"Write just hashes of objects.",$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null),$CLJS.AB,new $CLJS.p(null,4,[$CLJS.X,"stream",$CLJS.W,"Enable streaming of pins as they are discovered.",$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["PinLsList",new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Keys",new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Hj,$CLJS.V,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Type",$CLJS.V],null)],null)],null)],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,["PinLsObject",new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Cid",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["Type",$CLJS.V],null)],null)],null)],null)],null));
$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Remove pinned objects from local storage.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/pin/rm",$CLJS.oA,new $CLJS.p(null,2,[$CLJS.xB,new $CLJS.p(null,4,[$CLJS.X,"arg",$CLJS.W,"Path to the object(s) to be unpinned.",$CLJS.Y,!0,$CLJS.Z,$CLJS.OA],null),WE,new $CLJS.p(null,4,[$CLJS.X,"recursive",$CLJS.W,"Recursively unpin the object linked to by the specified object(s). Default: true.",$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null)],null),
$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Pins",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dk,$CLJS.V],null)],null)],null)],null));
$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Update a recursive pin.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/pin/update",$CLJS.oA,new $CLJS.p(null,3,[QE,new $CLJS.p(null,4,[$CLJS.X,"arg",$CLJS.W,"Path to the old object.",$CLJS.Y,!0,$CLJS.Z,$CLJS.OA],null),TE,new $CLJS.p(null,4,[$CLJS.X,"arg",$CLJS.W,"Path to the new object to be pinned.",$CLJS.Y,!0,$CLJS.Z,$CLJS.OA],null),UE,new $CLJS.p(null,4,[$CLJS.X,"unpin",$CLJS.W,"Remove the old pin. Default: true.",$CLJS.Y,
!1,$CLJS.Z,$CLJS.jj],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Pins",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dk,$CLJS.V],null)],null)],null)],null));
$CLJS.uB(new $CLJS.p(null,8,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Verify that recursive pins are complete.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/pin/verify",$CLJS.hA,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.bi(null,new $CLJS.p(null,2,[VE,null,SE,null],null),null)],null),$CLJS.oA,new $CLJS.p(null,2,[VE,new $CLJS.p(null,4,[$CLJS.X,"verbose",$CLJS.W,"Also write the hashes of non-broken pins.",$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null),SE,new $CLJS.p(null,4,[$CLJS.X,"quiet",$CLJS.W,"Write just hashes of broken pins.",
$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Cid",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["PinStatus",new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["BadNodes",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Cid",$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,["Err",$CLJS.V],
null)],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,["Ok",$CLJS.jj],null)],null)],null)],null)],null));