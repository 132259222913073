var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./com.kubelt.ipfs.spec.js");require("./com.kubelt.ipfs.util.js");require("./com.kubelt.ipfs.v0.js");require("./com.kubelt.lib.json.js");
'use strict';var MD,ND,OD,PD,QD,RD,SD,TD,UD,VD,WD,XD,YD,aE,bE,cE,dE,eE,fE,gE,hE,iE,jE,mE;MD=function(a){if(null!=a&&null!=a.ta)a=a.ta(a);else{var b=MD[$CLJS.n(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=MD._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.gc("ICloneable.-clone",a);}return a};ND=function(){};
OD=function(a){if(null!=a&&null!=a.Ph)a=a.Ph(a);else{var b=OD[$CLJS.n(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=OD._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.gc("IEncodeJS.-clj-\x3ejs",a);}return a};PD=function(a,b,c){a=b?a:$CLJS.ic(a);if(!c){c=[];for(b=0;;)if(b<a.length){var d=a[b],e=a[b+1];-1===$CLJS.Zg(c,d)&&(c.push(d),c.push(e));b+=2}else break;a=c}return new $CLJS.p(null,a.length/2,a,null)};
QD=function(){var a=$CLJS.Vl.h(null,$CLJS.xD),b=new $CLJS.p(null,4,[$CLJS.zD,$CLJS.DD,$CLJS.ED,$CLJS.wD,$CLJS.AD,$CLJS.CD,$CLJS.yD,$CLJS.BD],null);return $CLJS.jc.j(function(c,d){var e=$CLJS.M(d,0,null);d=$CLJS.M(d,1,null);return $CLJS.Ie(a,e)?$CLJS.Bi.j(c,d,$CLJS.N.h(a,e)):c},$CLJS.Df($CLJS.Vl,a,$CLJS.Yh(b)),b)};
RD=function(a,b){return(null!=a?$CLJS.C===a.Oh||(a.Nc?0:$CLJS.ec(ND,a)):$CLJS.ec(ND,a))?OD(a):"string"===typeof a||"number"===typeof a||a instanceof $CLJS.P||a instanceof $CLJS.F?b.g?b.g(a):b.call(null,a):$CLJS.Di($CLJS.ke([a]),$CLJS.Xb())};SD=function(a){this.$h=new $CLJS.DC(a)};
TD=function(a){this.Aa=a||{};this.af=null!=this.Aa.preferStrings?this.Aa.preferStrings:!0;this.Mg=this.Aa.objectBuilder||null;this.transform=this.Aa.transform||null;this.jb=new $CLJS.lD;if(a=this.Aa.handlers){if((0,$CLJS.VB)(a)||!a.forEach)throw Error('transit writer "handlers" option must be a map');var b=this;a.forEach(function(c,d){if(void 0!==d)b.jb.set(d,c);else throw Error("Cannot create handler for JavaScript undefined");})}this.Id=this.Aa.handlerForForeign;this.ff=this.Aa.unpack||function(c){return c instanceof
$CLJS.qC&&null===c.va?c.wa:!1};this.be=this.Aa&&this.Aa.verbose||!1};UD=function(a,b){if("json"===a||"json-verbose"===a||null==a)return a=new SD(b),new $CLJS.HC(a,b);throw Error("Cannot create reader of type "+a);};VD=function(a,b){if("json"===a||"json-verbose"===a||null==a)return"json-verbose"===a&&(null==b&&(b={}),b.verbose=!0),a=new TD(b),new $CLJS.vD(a,b);b=Error('Type must be "json"');b.data={type:a};throw b;};
WD=function(a,b){for(var c=$CLJS.G($CLJS.Aa(b)),d=null,e=0,f=0;;)if(f<e){var g=d.ha(null,f);a[g]=$CLJS.Pb(b,g);f+=1}else if(c=$CLJS.G(c))d=c,$CLJS.Ce(d)?(c=$CLJS.kd(d),f=$CLJS.ld(d),d=c,e=$CLJS.J(c),c=f):(c=$CLJS.H(d),a[c]=$CLJS.Pb(b,c),c=$CLJS.I(d),d=null,e=0),f=0;else break;return a};XD=function(){};YD=function(){};
$CLJS.$D=function(){return UD($CLJS.hi($CLJS.Fj),WD({handlers:$CLJS.ZD($CLJS.nm.A($CLJS.ke([new $CLJS.p(null,6,["$",function(a){return $CLJS.Ei.g(a)},":",function(a){return $CLJS.xi.g(a)},"set",function(a){return $CLJS.gh.h($CLJS.ci,a)},"list",function(a){return $CLJS.gh.h($CLJS.Md,a.reverse())},"cmap",function(a){for(var b=0,c=$CLJS.dd($CLJS.R);;)if(b<a.length){var d=b+2;c=$CLJS.gd(c,a[b],a[b+1]);b=d}else return $CLJS.fd(c)},"with-meta",function(a){return $CLJS.se(a[0],a[1])}],null),$CLJS.Vl.h($CLJS.xD.g(null),
$CLJS.Xi)]))),defaultHandler:$CLJS.Xi.g($CLJS.xD.g(null)),mapBuilder:new XD,arrayBuilder:new YD,preferStrings:!1,preferBuffers:!1},$CLJS.ZD(QD())))};aE=function(){};bE=function(){};cE=function(){};dE=function(){};eE=function(){};fE=function(){};gE=function(){};hE=function(a,b){this.value=a;this.D=b};iE=function(){};
jE=function(){var a=new cE,b=new dE,c=new eE,d=new fE,e=$CLJS.nm.A($CLJS.ke([$CLJS.co([$CLJS.Jh,$CLJS.df,$CLJS.p,$CLJS.Ah,$CLJS.Tg,$CLJS.Jd,$CLJS.P,$CLJS.Qh,$CLJS.bf,$CLJS.mf,$CLJS.Jg,$CLJS.Sg,$CLJS.Eh,$CLJS.Zh,hE,$CLJS.ah,$CLJS.S,$CLJS.$e,$CLJS.le,$CLJS.bi,$CLJS.Vh,$CLJS.Xh,$CLJS.Fg,$CLJS.fi,$CLJS.rf,$CLJS.F,$CLJS.bj,$CLJS.ji,$CLJS.Nh,$CLJS.Ph],[b,a,b,a,a,a,new aE,d,a,a,d,a,a,a,new iE,a,d,a,a,c,b,a,a,c,a,new bE,new gE,a,a,d]),"undefined"!==typeof $CLJS.Nf&&"undefined"!==typeof $CLJS.Of&&"undefined"!==
typeof $CLJS.Ji?$CLJS.Kf([$CLJS.Ji,a]):null,"undefined"!==typeof $CLJS.Nf&&"undefined"!==typeof $CLJS.Of&&"undefined"!==typeof $CLJS.lg?$CLJS.Kf([$CLJS.lg,a]):null,"undefined"!==typeof $CLJS.Nf&&"undefined"!==typeof $CLJS.Of&&"undefined"!==typeof $CLJS.$g?$CLJS.Kf([$CLJS.$g,d]):null,$CLJS.xD.g(null)]));return VD($CLJS.hi($CLJS.KD),WD({objectBuilder:function(f,g,h){return $CLJS.Re(function(l,m,r){l.push(g.g?g.g(m):g.call(null,m),h.g?h.g(r):h.call(null,r));return l},["^ "],f)},handlers:function(){var f=
MD(e);f.forEach=function(g){for(var h=$CLJS.G(this),l=null,m=0,r=0;;)if(r<m){var t=l.ha(null,r),u=$CLJS.M(t,0,null);t=$CLJS.M(t,1,null);$CLJS.ee.h($CLJS.Xi,u)?g.h?g.h(t,"default"):g.call(null,t,"default"):g.h?g.h(t,u):g.call(null,t,u);r+=1}else if(h=$CLJS.G(h))$CLJS.Ce(h)?(l=$CLJS.kd(h),h=$CLJS.ld(h),u=l,m=$CLJS.J(l),l=u):(l=$CLJS.H(h),u=$CLJS.M(l,0,null),t=$CLJS.M(l,1,null),$CLJS.ee.h($CLJS.Xi,u)?g.h?g.h(t,"default"):g.call(null,t,"default"):g.h?g.h(t,u):g.call(null,t,u),h=$CLJS.I(h),l=null,m=0),
r=0;else return null};return f}(),unpack:function(f){return f instanceof $CLJS.p?f.l:!1}},$CLJS.ZD($CLJS.Vl.h(null,$CLJS.xD))))};$CLJS.kE=function(a){return jE().write(a)};$CLJS.lE=function(a){return $CLJS.$D().read(a)};mE=function(a,b){b=$CLJS.Mm(b)?$CLJS.kE(b):b;return new $CLJS.p(null,2,[$CLJS.lj,$CLJS.$w,$CLJS.Xw,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.p(null,3,[$CLJS.Zw,a,$CLJS.cx,b,$CLJS.ax,"string"===typeof b?"text/plain":"application/octet-stream"],null)],null)],null)};
$CLJS.Jd.prototype.ta=$CLJS.ca(16,function(){return new $CLJS.Jd(this.l,this.G,this.D)});$CLJS.le.prototype.ta=$CLJS.ca(15,function(){return new $CLJS.le(this.wd,this.G,this.D)});$CLJS.$e.prototype.ta=$CLJS.ca(14,function(){return new $CLJS.$e(this.D,this.first,this.dc,this.count,this.H)});$CLJS.bf.prototype.ta=$CLJS.ca(13,function(){return new $CLJS.bf(this.D)});$CLJS.df.prototype.ta=$CLJS.ca(12,function(){return new $CLJS.df(this.D,this.first,this.dc,this.H)});
$CLJS.S.prototype.ta=$CLJS.ca(11,function(){return new $CLJS.S(this.D,this.C,this.shift,this.root,this.tb,this.H)});$CLJS.Jg.prototype.ta=$CLJS.ca(10,function(){return new $CLJS.Jg(this.D,this.Ka,this.start,this.end,this.H)});$CLJS.Tg.prototype.ta=$CLJS.ca(9,function(){return new $CLJS.Tg(this.D,this.count,this.yb,this.Hb,this.H)});$CLJS.p.prototype.ta=$CLJS.ca(8,function(){return new $CLJS.p(this.D,this.C,this.l,this.H)});
$CLJS.Jh.prototype.ta=$CLJS.ca(7,function(){return new $CLJS.Jh(this.D,this.C,this.root,this.Sa,this.pb,this.H)});$CLJS.Vh.prototype.ta=$CLJS.ca(6,function(){return new $CLJS.Vh(this.Mb,this.kc,this.C,this.D,this.H)});$CLJS.bi.prototype.ta=$CLJS.ca(5,function(){return new $CLJS.bi(this.D,this.wc,this.H)});$CLJS.fi.prototype.ta=$CLJS.ca(4,function(){return new $CLJS.fi(this.D,this.lc,this.H)});
$CLJS.ji.prototype.ta=$CLJS.ca(3,function(){return new $CLJS.ji(this.D,this.start,this.end,this.step,this.Ra,this.vd,this.H)});$CLJS.ZD=function ZD(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return ZD.A(arguments[0],1<c.length?new $CLJS.Jd(c.slice(1),0,null):null)};
$CLJS.ZD.A=function(a,b){b=$CLJS.Lf(b);var c=$CLJS.N.j(b,$CLJS.Ik,$CLJS.hi),d=function g(f){if(null==f)return null;if(null!=f?$CLJS.C===f.Oh||(f.Nc?0:$CLJS.ec(ND,f)):$CLJS.ec(ND,f))return OD(f);if(f instanceof $CLJS.P)return c.g?c.g(f):c.call(null,f);if(f instanceof $CLJS.F)return $CLJS.v.g(f);if($CLJS.ze(f)){var h={};f=$CLJS.G(f);for(var l=null,m=0,r=0;;)if(r<m){var t=l.ha(null,r),u=$CLJS.M(t,0,null);t=$CLJS.M(t,1,null);u=RD(u,d);t=g(t);h[u]=t;r+=1}else if(f=$CLJS.G(f))$CLJS.Ce(f)?(m=$CLJS.kd(f),
f=$CLJS.ld(f),l=m,m=$CLJS.J(m)):(m=$CLJS.H(f),l=$CLJS.M(m,0,null),m=$CLJS.M(m,1,null),l=RD(l,d),m=g(m),h[l]=m,f=$CLJS.I(f),l=null,m=0),r=0;else break;return h}if($CLJS.Mm(f)){h=[];f=$CLJS.G($CLJS.wi.h(g,f));l=null;for(r=m=0;;)if(r<m)u=l.ha(null,r),h.push(u),r+=1;else if(f=$CLJS.G(f))l=f,$CLJS.Ce(l)?(f=$CLJS.kd(l),r=$CLJS.ld(l),l=f,m=$CLJS.J(f),f=r):(f=$CLJS.H(l),h.push(f),f=$CLJS.I(l),l=null,m=0),r=0;else break;return h}return f};return d(a)};$CLJS.ZD.I=1;
$CLJS.ZD.N=function(a){var b=$CLJS.H(a);a=$CLJS.I(a);return this.A(b,a)};SD.prototype.Aj=function(a,b){return this.$h.decode(JSON.parse(a),b)};$CLJS.k=TD.prototype;$CLJS.k.Gc=function(a){var b=this.jb.get(null==a?null:a.constructor);return null!=b?b:(a=a&&a.transitTag)?this.jb.get(a):null};$CLJS.k.wj=function(a,b){this.jb.set(a,b)};$CLJS.k.fi=function(a,b){return a?this.wb("~","_","",a,b):null};$CLJS.k.wb=function(a,b,c,d,e){a=a+b+c;return e?e.write(a,d):a};
$CLJS.k.ci=function(a,b,c){return b?this.wb("~","?",a.toString()[0],b,c):a};$CLJS.k.ei=function(a,b,c){return Infinity===a?this.wb("~","z","INF",b,c):-Infinity===a?this.wb("~","z","-INF",b,c):isNaN(a)?this.wb("~","z","NaN",b,c):b||"string"===typeof a||a instanceof $CLJS.ma?this.wb("~","i",a.toString(),b,c):a};$CLJS.k.di=function(a,b,c){return b?this.wb(a.Lj,"d",a,b,c):a};$CLJS.k.bi=function(a,b,c){return this.wb("~","b",a,b,c)};
$CLJS.k.gi=function(a,b,c){if(a.be){a={};var d=this.wb("~#","'","",!0,c);a[d]=$CLJS.pD(this,b,!1,c);return a}return[this.wb("~#","'","",!0,c),$CLJS.pD(this,b,!1,c)]};XD.prototype.init=function(){return $CLJS.dd($CLJS.R)};XD.prototype.add=function(a,b,c){return $CLJS.gd(a,b,c)};XD.prototype.finalize=function(a){return $CLJS.fd(a)};XD.prototype.fromArray=function(a){return PD.j?PD.j(a,!0,!0):PD.call(null,a,!0,!0)};YD.prototype.init=function(){return $CLJS.dd($CLJS.Dg)};
YD.prototype.add=function(a,b){return $CLJS.yg.h(a,b)};YD.prototype.finalize=function(a){return $CLJS.fd(a)};YD.prototype.fromArray=function(a){return $CLJS.zg.h?$CLJS.zg.h(a,!0):$CLJS.zg.call(null,a,!0)};aE.prototype.tag=function(){return":"};aE.prototype.rep=function(a){return a.xb};aE.prototype.stringRep=function(a){return a.xb};bE.prototype.tag=function(){return"$"};bE.prototype.rep=function(a){return a.Bb};bE.prototype.stringRep=function(a){return a.Bb};cE.prototype.tag=function(){return"list"};
cE.prototype.rep=function(a){var b=[];a=$CLJS.G(a);for(var c=null,d=0,e=0;;)if(e<d){var f=c.ha(null,e);b.push(f);e+=1}else if(a=$CLJS.G(a))c=a,$CLJS.Ce(c)?(a=$CLJS.kd(c),e=$CLJS.ld(c),c=a,d=$CLJS.J(a),a=e):(a=$CLJS.H(c),b.push(a),a=$CLJS.I(c),c=null,d=0),e=0;else break;return $CLJS.hC("array",b)};cE.prototype.stringRep=function(){return null};dE.prototype.tag=function(){return"map"};dE.prototype.rep=function(a){return a};dE.prototype.stringRep=function(){return null};eE.prototype.tag=function(){return"set"};
eE.prototype.rep=function(a){var b=[];a=$CLJS.G(a);for(var c=null,d=0,e=0;;)if(e<d){var f=c.ha(null,e);b.push(f);e+=1}else if(a=$CLJS.G(a))c=a,$CLJS.Ce(c)?(a=$CLJS.kd(c),e=$CLJS.ld(c),c=a,d=$CLJS.J(a),a=e):(a=$CLJS.H(c),b.push(a),a=$CLJS.I(c),c=null,d=0),e=0;else break;return $CLJS.hC("array",b)};eE.prototype.stringRep=function(){return null};fE.prototype.tag=function(){return"array"};
fE.prototype.rep=function(a){var b=[];a=$CLJS.G(a);for(var c=null,d=0,e=0;;)if(e<d){var f=c.ha(null,e);b.push(f);e+=1}else if(a=$CLJS.G(a))c=a,$CLJS.Ce(c)?(a=$CLJS.kd(c),e=$CLJS.ld(c),c=a,d=$CLJS.J(a),a=e):(a=$CLJS.H(c),b.push(a),a=$CLJS.I(c),c=null,d=0),e=0;else break;return b};fE.prototype.stringRep=function(){return null};gE.prototype.tag=function(){return"u"};gE.prototype.rep=function(a){return a.uuid};gE.prototype.stringRep=function(a){return this.rep(a)};iE.prototype.tag=function(){return"with-meta"};
iE.prototype.rep=function(a){return $CLJS.hC("array",[a.value,a.D])};iE.prototype.stringRep=function(){return null};
var nE=new $CLJS.P("codec","store","codec/store",1538907296),oE=new $CLJS.P("dag","pin-roots?","dag/pin-roots?",-41266120),pE=new $CLJS.P("dag","stats?","dag/stats?",1768044643),qE=new $CLJS.P("codec","input","codec/input",727305103),rE=new $CLJS.P("dag","hash","dag/hash",-13684182),sE=new $CLJS.P("dag","data","dag/data",-232766539),tE=new $CLJS.P("dag","pin?","dag/pin?",-1347468007),uE=new $CLJS.P("output","codec","output/codec",791877585),vE=new $CLJS.P("dag","silent?","dag/silent?",-1639312557);$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Streams the selected DAG as a .car stream on stdout.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/dag/export",$CLJS.oA,new $CLJS.p(null,2,[$CLJS.wB,new $CLJS.p(null,4,[$CLJS.X,"arg",$CLJS.W,"CID of a root to recursively export.",$CLJS.Y,!0,$CLJS.Z,$CLJS.V],null),new $CLJS.P("dag","progress?","dag/progress?",374420282),new $CLJS.p(null,4,[$CLJS.X,"progress",$CLJS.W,"Display progress on CLI. Defaults to true when STDERR is a TTY.",
$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["text/plain"],null),$CLJS.aA,$CLJS.Dg],null));
$CLJS.uB(new $CLJS.p(null,8,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Get a DAG node from IPFS.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/dag/get",$CLJS.oA,new $CLJS.p(null,2,[$CLJS.wB,new $CLJS.p(null,4,[$CLJS.X,"arg",$CLJS.W,"The object to get.",$CLJS.Y,!0,$CLJS.Z,$CLJS.V],null),uE,new $CLJS.p(null,4,[$CLJS.X,"output-codec",$CLJS.W,"Format the object will be encoded as. Default: dag-json.",$CLJS.Y,!1,$CLJS.Z,$CLJS.PA],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["text/plain"],
null),$CLJS.aA,$CLJS.V,$CLJS.uA,function(a,b){a=$CLJS.rv(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dA,uE],null));return $CLJS.ee.h("dag-cbor",a)?b:$CLJS.lE(b)}],null));
var wE=$CLJS.co([$CLJS.vA,$CLJS.qA,$CLJS.cA,$CLJS.iA,$CLJS.eA,$CLJS.lj,$CLJS.oA,$CLJS.aA,$CLJS.tA],[new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),"/dag/import",new $CLJS.p(null,1,[sE,new $CLJS.p(null,4,[$CLJS.X,"file",$CLJS.W,"DAG data to send.",$CLJS.Y,!0,$CLJS.Z,$CLJS.Rk],null)],null),"Import the contents of .car files.",$CLJS.wA,new $CLJS.p(null,3,[oE,new $CLJS.p(null,4,[$CLJS.X,"pin-roots",$CLJS.W,"Pin optional roots listed in the .car headers after importing. Default: true.",
$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null),vE,new $CLJS.p(null,4,[$CLJS.X,"silent",$CLJS.W,"No output.",$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null),pE,new $CLJS.p(null,4,[$CLJS.X,"stats",$CLJS.W,"Output stats.",$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Pins",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dk,$CLJS.V],null)],null)],null),mE]);$CLJS.uB(wE);
var xE=$CLJS.co([$CLJS.vA,$CLJS.qA,$CLJS.cA,$CLJS.iA,$CLJS.eA,$CLJS.lj,$CLJS.oA,$CLJS.aA,$CLJS.tA],[new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),"/dag/put",new $CLJS.p(null,1,[sE,new $CLJS.p(null,4,[$CLJS.X,"file",$CLJS.W,"DAG data to send.",$CLJS.Y,!0,$CLJS.Z,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Dk,$CLJS.RA,$CLJS.QA],null)],null)],null),"Add a DAG node to IPFS.",$CLJS.wA,new $CLJS.p(null,4,[nE,new $CLJS.p(null,4,[$CLJS.X,"store-codec",$CLJS.W,
"Codec that the stored object will be encoded with. Default: dag-cbor.",$CLJS.Y,!1,$CLJS.Z,$CLJS.PA],null),qE,new $CLJS.p(null,4,[$CLJS.X,"input-codec",$CLJS.W,"Codec that the input object is encoded in. Default: dag-json.",$CLJS.Y,!1,$CLJS.Z,$CLJS.PA],null),tE,new $CLJS.p(null,4,[$CLJS.X,"pin",$CLJS.W,"Pin this object when adding.",$CLJS.Y,!1,$CLJS.Z,$CLJS.jj],null),rE,new $CLJS.p(null,4,[$CLJS.X,"hash",$CLJS.W,"Hash function to use.",$CLJS.Y,!1,$CLJS.Z,$CLJS.SA],null)],null),new $CLJS.S(null,2,
5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Cid",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["/",$CLJS.V],null)],null)],null)],null),mE]);$CLJS.uB(xE);
$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Resolve IPLD block.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/dag/resolve",$CLJS.oA,new $CLJS.p(null,1,[$CLJS.xB,new $CLJS.p(null,4,[$CLJS.X,"arg",$CLJS.W,"The path to resolve.",$CLJS.Y,!0,$CLJS.Z,$CLJS.OA],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["Cid",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,
2,5,$CLJS.T,["/",$CLJS.V],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,["RemPath",$CLJS.V],null)],null)],null));
$CLJS.uB(new $CLJS.p(null,7,[$CLJS.lj,$CLJS.wA,$CLJS.eA,"Get stats for a DAG.",$CLJS.qA,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lk],null),$CLJS.cA,"/dag/stat",$CLJS.oA,new $CLJS.p(null,1,[$CLJS.wB,new $CLJS.p(null,4,[$CLJS.X,"arg",$CLJS.W,"CID of a DAG root to get statistics for.",$CLJS.Y,!0,$CLJS.Z,$CLJS.TA],null)],null),$CLJS.vA,new $CLJS.S(null,1,5,$CLJS.T,["application/json"],null),$CLJS.aA,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,["NumBlocks",$CLJS.Sj],null),new $CLJS.S(null,
2,5,$CLJS.T,["Size",$CLJS.Sj],null)],null)],null));