var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./com.kubelt.spec.http.js");
'use strict';$CLJS.aA=new $CLJS.P("response","spec","response/spec",-530861736);$CLJS.bA=new $CLJS.P("client","node-info?","client/node-info?",851507106);$CLJS.cA=new $CLJS.P("resource","path","resource/path",18653738);$CLJS.dA=new $CLJS.P("parameter","data","parameter/data",1688408984);$CLJS.eA=new $CLJS.P("resource","description","resource/description",-683595378);$CLJS.fA=new $CLJS.P("client","validate?","client/validate?",611233491);$CLJS.gA=new $CLJS.P("write","host","write/host",-1453539404);
$CLJS.hA=new $CLJS.P("resource","conflicts","resource/conflicts",-1422904650);$CLJS.iA=new $CLJS.P("resource","body","resource/body",-1853335955);$CLJS.jA=new $CLJS.P("read","scheme","read/scheme",88911019);$CLJS.kA=new $CLJS.P("client","timeout","client/timeout",-1105905755);$CLJS.lA=new $CLJS.P("read","host","read/host",-1563573013);$CLJS.mA=new $CLJS.P("client","keywordize?","client/keywordize?",1098118753);$CLJS.nA=new $CLJS.P("write","port","write/port",633282643);
$CLJS.oA=new $CLJS.P("resource","params","resource/params",389411349);$CLJS.pA=new $CLJS.P("read","port","read/port",1534074396);$CLJS.qA=new $CLJS.P("resource","methods","resource/methods",658647880);$CLJS.rA=new $CLJS.P("parameter","spec","parameter/spec",1765019632);$CLJS.sA=new $CLJS.P("write","scheme","write/scheme",-1121866140);$CLJS.tA=new $CLJS.P("resource","body-fn","resource/body-fn",-739634881);$CLJS.uA=new $CLJS.P("response","body-fn","response/body-fn",-742350844);
$CLJS.vA=new $CLJS.P("response","types","response/types",249705186);$CLJS.wA=new $CLJS.P("kubelt.type","api-resource","kubelt.type/api-resource",1565953884);var yA;$CLJS.xA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yk,$CLJS.Ll],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kj,$CLJS.V],null)],null);
yA=new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Hj,new $CLJS.p(null,1,[$CLJS.Wj,!0],null),$CLJS.Ll,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.gk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.X,$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Y,$CLJS.jj],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Z,$CLJS.Rk],null)],null)],null);
$CLJS.zA=new $CLJS.S(null,16,5,$CLJS.T,[$CLJS.gk,new $CLJS.p(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Tk,$CLJS.wA],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.eA,$CLJS.V],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qA,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dk,$CLJS.ex],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cA,$CLJS.V],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.iA,new $CLJS.p(null,1,[$CLJS.yj,!0],null),yA],null),new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.tA,new $CLJS.p(null,1,[$CLJS.yj,!0],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.dl,$CLJS.V,$CLJS.Rk],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Dk,$CLJS.ix],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hA,new $CLJS.p(null,1,[$CLJS.yj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Fk,$CLJS.Ll],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.oA,yA],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vA,
new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dk,$CLJS.V],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.aA,$CLJS.Rk],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.uA,new $CLJS.p(null,1,[$CLJS.yj,!0],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.dl,$CLJS.gk,$CLJS.Rk],null),$CLJS.Rk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wk,new $CLJS.p(null,1,[$CLJS.yj,!0],null),$CLJS.gk],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.rA,new $CLJS.p(null,1,[$CLJS.yj,!0],null),new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.dk,$CLJS.Rk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.dA,new $CLJS.p(null,1,[$CLJS.yj,!0],null),$CLJS.gk],null)],null);
$CLJS.AA=new $CLJS.S(null,13,5,$CLJS.T,[$CLJS.gk,new $CLJS.p(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.mA,new $CLJS.p(null,2,[$CLJS.yj,!0,$CLJS.W,"Convert response keys to keywords?"],null),$CLJS.jj],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.fA,new $CLJS.p(null,2,[$CLJS.yj,!0,$CLJS.W,"Validate the parsed response body?"],null),$CLJS.jj],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.kA,new $CLJS.p(null,2,[$CLJS.yj,!0,$CLJS.W,"Request timeout in milliseconds."],null),$CLJS.Sj],null),
new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.bA,new $CLJS.p(null,2,[$CLJS.yj,!0,$CLJS.W,"Pre-fetch and store node information."],null),$CLJS.jj],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Hk,new $CLJS.p(null,2,[$CLJS.yj,!0,$CLJS.W,"An existing HTTP client to use."],null),$CLJS.Rk],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.jA,new $CLJS.p(null,3,[$CLJS.yj,!0,$CLJS.W,"The protocol scheme to use to talk to IPFS",$CLJS.Bk,$CLJS.Ak],null),$CLJS.fx],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.lA,new $CLJS.p(null,2,
[$CLJS.yj,!0,$CLJS.W,"An IP address for the IPFS server"],null),$CLJS.gx],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.pA,new $CLJS.p(null,2,[$CLJS.yj,!0,$CLJS.W,"A TCP port for the IPFS server"],null),$CLJS.hx],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.sA,new $CLJS.p(null,3,[$CLJS.yj,!0,$CLJS.W,"The protocol scheme to use to talk to IPFS",$CLJS.Bk,$CLJS.Ak],null),$CLJS.fx],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gA,new $CLJS.p(null,2,[$CLJS.yj,!0,$CLJS.W,"An IP address for the IPFS server"],null),
$CLJS.gx],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nA,new $CLJS.p(null,2,[$CLJS.yj,!0,$CLJS.W,"A TCP port for the IPFS server"],null),$CLJS.hx],null)],null);$CLJS.BA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,new $CLJS.p(null,1,[$CLJS.Bk,"12D3KooWGn8esyGN8DB1UCsVTZPWxkr6ELGBP9AffLNQqbrfejv7"],null),$CLJS.V],null);$CLJS.CA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,new $CLJS.p(null,1,[$CLJS.Bk,"CAESIGdvXZHqB6Sbr9gEF9Z1XaWEYNOjC6iW1PFl+jpHE/E4"],null),$CLJS.V],null);
$CLJS.DA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,new $CLJS.p(null,1,[$CLJS.Bk,new $CLJS.S(null,8,5,$CLJS.T,"/ip4/127.0.0.1/tcp/4001/p2p/12D3KooWGn8esyGN8DB1UCsVTZPWxkr6ELGBP9AffLNQqbrfejv7 /ip4/127.0.0.1/udp/4001/quic/p2p/12D3KooWGn8esyGN8DB1UCsVTZPWxkr6ELGBP9AffLNQqbrfejv7 /ip4/192.168.68.105/tcp/4001/p2p/12D3KooWGn8esyGN8DB1UCsVTZPWxkr6ELGBP9AffLNQqbrfejv7 /ip4/192.168.68.105/udp/4001/quic/p2p/12D3KooWGn8esyGN8DB1UCsVTZPWxkr6ELGBP9AffLNQqbrfejv7 /ip4/206.248.184.135/tcp/63760/p2p/12D3KooWGn8esyGN8DB1UCsVTZPWxkr6ELGBP9AffLNQqbrfejv7 /ip4/206.248.184.135/udp/63760/quic/p2p/12D3KooWGn8esyGN8DB1UCsVTZPWxkr6ELGBP9AffLNQqbrfejv7 /ip6/::1/tcp/4001/p2p/12D3KooWGn8esyGN8DB1UCsVTZPWxkr6ELGBP9AffLNQqbrfejv7 /ip6/::1/udp/4001/quic/p2p/12D3KooWGn8esyGN8DB1UCsVTZPWxkr6ELGBP9AffLNQqbrfejv7".split(" "),
null)],null),$CLJS.dk],null);$CLJS.EA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,new $CLJS.p(null,1,[$CLJS.Bk,"go-ipfs/0.11.0/"],null),$CLJS.V],null);$CLJS.FA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,new $CLJS.p(null,1,[$CLJS.Bk,"ipfs/0.1.0"],null),$CLJS.V],null);
$CLJS.GA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,new $CLJS.p(null,1,[$CLJS.Bk,new $CLJS.S(null,15,5,$CLJS.T,"/ipfs/bitswap /ipfs/bitswap/1.0.0 /ipfs/bitswap/1.1.0 /ipfs/bitswap/1.2.0 /ipfs/id/1.0.0 /ipfs/id/push/1.0.0 /ipfs/kad/1.0.0 /ipfs/lan/kad/1.0.0 /ipfs/ping/1.0.0 /libp2p/autonat/1.0.0 /libp2p/circuit/relay/0.1.0 /libp2p/circuit/relay/0.2.0/hop /libp2p/circuit/relay/0.2.0/stop /p2p/id/delta/1.0.0 /x/".split(" "),null)],null),$CLJS.dk],null);
$CLJS.HA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,new $CLJS.p(null,1,[$CLJS.Bk,"self"],null),$CLJS.V],null);$CLJS.IA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,new $CLJS.p(null,1,[$CLJS.Bk,"k51qzi5uqu5direb9al94reti397oxnp93oeucvf6hkte8nebtj5otqr7d8k6w"],null),$CLJS.V],null);$CLJS.JA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,new $CLJS.p(null,1,[$CLJS.Bk,"ed25519"],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Tk,"rsa","ed25519"],null)],null);
$CLJS.KA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,new $CLJS.p(null,1,[$CLJS.Bk,128],null),$CLJS.Sj],null);$CLJS.LA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,new $CLJS.p(null,1,[$CLJS.Bk,"b58mh"],null),new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Tk,"b58mh","base36","base32","k"],null)],null);$CLJS.MA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,new $CLJS.p(null,1,[$CLJS.Bk,"base36"],null),new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Tk,"b58mh","base36","base32","k"],null)],null);
$CLJS.NA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,new $CLJS.p(null,1,[$CLJS.Bk,"k51qzi5uqu5direb9al94reti397oxnp93oeucvf6hkte8nebtj5otqr7d8k6w"],null),$CLJS.V],null);$CLJS.OA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,new $CLJS.p(null,1,[$CLJS.Bk,"/ipfs/bafyreid7jv35hibnc3fq2nzacom57gihwfh5e4yp5onoyf2vosybfjf4ua"],null),$CLJS.V],null);$CLJS.PA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,new $CLJS.p(null,1,[$CLJS.Bk,"dag-cbor"],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Tk,"dag-cbor","dag-json"],null)],null);
$CLJS.QA=new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Dk,new $CLJS.p(null,2,[$CLJS.W,"DAG-JSON data",$CLJS.Bk,new $CLJS.p(null,1,[new $CLJS.P(null,"foo","foo",1268894036),new $CLJS.S(null,2,5,$CLJS.T,["bar",new $CLJS.P(null,"baz","baz",-1134894686)],null)],null)],null),$CLJS.Be,$CLJS.ze],null);$CLJS.RA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Dk,new $CLJS.p(null,1,[$CLJS.W,"CBOR-encoded binary data."],null),$CLJS.dx.zj],null);
$CLJS.SA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,new $CLJS.p(null,1,[$CLJS.Bk,"sha2-256"],null),new $CLJS.S(null,13,5,$CLJS.T,[$CLJS.Tk,"keccak-256","keccak-512","md5","sha1","sha2-256","sha2-512","sha3-224","sha3-256","sha3-384","sha3-512","shake-128","shake-256"],null)],null);$CLJS.TA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,new $CLJS.p(null,2,[$CLJS.W,"A multiformat content identifier.",$CLJS.Bk,"bagiacgzah24drzou2jlkixpblbgbg6nxfrasoklzttzoht5hixhxz3rlncyq"],null),$CLJS.V],null);
$CLJS.UA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,new $CLJS.p(null,2,[$CLJS.W,"An IPFS DHT table name.",$CLJS.Bk,"lan"],null),new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Tk,"wanserver","lanserver","wan","lan"],null)],null);$CLJS.VA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,new $CLJS.p(null,2,[$CLJS.W,"A remote pinning service endpoint.",$CLJS.Bk,"https://api.pinata.cloud/psa"],null),$CLJS.V],null);
$CLJS.WA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,new $CLJS.p(null,2,[$CLJS.W,"A CID multibase code identifier",$CLJS.Bk,70],null),$CLJS.Sj],null);$CLJS.XA=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,new $CLJS.p(null,2,[$CLJS.W,"A CID multibase code name",$CLJS.Bk,"base58btc"],null),$CLJS.V],null);