var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./com.cognitect.transit.util.js");require("./com.cognitect.transit.delimiters.js");require("./com.cognitect.transit.caching.js");require("./com.cognitect.transit.types.js");
'use strict';var CC,EC,FC;CC=function(a){this.Bb=a};
$CLJS.DC=function(a){this.options=a||{};this.jb={};for(var b in this.Dd.jb)this.jb[b]=this.Dd.jb[b];for(b in this.options.handlers){a:{switch(b){case "_":case "s":case "?":case "i":case "d":case "b":case "'":case "array":case "map":a=!0;break a}a=!1}if(a)throw Error('Cannot override handler for ground type "'+b+'"');this.jb[b]=this.options.handlers[b]}this.af=null!=this.options.preferStrings?this.options.preferStrings:this.Dd.af;this.Vf=null!=this.options.preferBuffers?this.options.preferBuffers:
this.Dd.Vf;this.Df=this.options.defaultHandler||this.Dd.Df;this.Tb=this.options.mapBuilder;this.fd=this.options.arrayBuilder};
EC=function(a,b,c,d,e){if(e){var f=[];for(e=0;e<b.length;e++)f.push(a.decode(b[e],c,d,!1));return f}f=c&&c.Ca;if(2===b.length&&"string"===typeof b[0]&&(e=a.decode(b[0],c,!1,!1))&&e instanceof CC)return b=b[1],f=a.jb[e.Bb],null!=f?f=f(a.decode(b,c,d,!0),a):$CLJS.hC(e.Bb,a.decode(b,c,d,!1));c&&f!=c.Ca&&(c.Ca=f);if(a.fd){if(32>=b.length&&a.fd.fromArray){f=[];for(e=0;e<b.length;e++)f.push(a.decode(b[e],c,d,!1));return a.fd.fromArray(f,b)}f=a.fd.init(b);for(e=0;e<b.length;e++)f=a.fd.add(f,a.decode(b[e],
c,d,!1),b);return a.fd.finalize(f,b)}f=[];for(e=0;e<b.length;e++)f.push(a.decode(b[e],c,d,!1));return f};FC=function(a,b){if("~"===b.charAt(0)){var c=b.charAt(1);if("~"===c||"^"===c||"`"===c)return b.substring(1);if("#"===c)return new CC(b.substring(2));var d=a.jb[c];return null==d?a.Df(c,b.substring(2)):d(b.substring(2),a)}return b};
$CLJS.DC.prototype.Dd={jb:{_:function(){return null},"?":function(a){return"t"===a},b:function(a,b){if(b&&!1===b.Vf||"undefined"==typeof $CLJS.qb.lf)if("undefined"!=typeof Uint8Array){if("undefined"!=typeof atob)var c=atob(a);else{a=String(a).replace(/=+$/,"");if(1==a.length%4)throw Error("'atob' failed: The string to be decoded is not correctly encoded.");b=0;for(var d,e=0,f="";d=a.charAt(e++);~d&&(c=b%4?64*c+d:d,b++%4)?f+=String.fromCharCode(255&c>>(-2*b&6)):0)d="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/\x3d".indexOf(d);
c=f}a=c.length;b=new Uint8Array(a);for(d=0;d<a;d++)b[d]=c.charCodeAt(d);c=b}else c=$CLJS.hC("b",a);else c=new $CLJS.qb.lf(a,"base64");return c},i:function(a){"number"===typeof a||a instanceof $CLJS.ma||(a=$CLJS.fC(a,10),a=0<a.compare($CLJS.AC)||0>a.compare($CLJS.BC)?a:$CLJS.ta(a));return a},n:function(a){return $CLJS.hC("n",a)},d:function(a){return parseFloat(a)},f:function(a){return $CLJS.hC("f",a)},c:function(a){return a},":":function(a){return new $CLJS.iC(a)},$:function(a){return new $CLJS.jC(a)},
r:function(a){return $CLJS.hC("r",a)},z:function(a){a:switch(a){case "-INF":a=-Infinity;break a;case "INF":a=Infinity;break a;case "NaN":a=NaN;break a;default:throw Error("Invalid special double value "+a);}return a},"'":function(a){return a},m:function(a){a="number"===typeof a?a:parseInt(a,10);return new Date(a)},t:function(a){return new Date(a)},u:function(a){a=a.replace(/-/g,"");var b,c;var d=b=0;for(c=24;8>d;d+=2,c-=8)b|=parseInt(a.substring(d,d+2),16)<<c;var e=0;d=8;for(c=24;16>d;d+=2,c-=8)e|=
parseInt(a.substring(d,d+2),16)<<c;var f=$CLJS.na(e,b);b=0;d=16;for(c=24;24>d;d+=2,c-=8)b|=parseInt(a.substring(d,d+2),16)<<c;e=0;for(c=d=24;32>d;d+=2,c-=8)e|=parseInt(a.substring(d,d+2),16)<<c;return new $CLJS.lC(f,$CLJS.na(e,b))},set:function(a){return $CLJS.yC(a)},list:function(a){return $CLJS.hC("list",a)},link:function(a){return $CLJS.hC("link",a)},cmap:function(a){return $CLJS.vC(a,!1)}},Df:function(a,b){return $CLJS.hC(a,b)},af:!0,Vf:!0};
$CLJS.DC.prototype.decode=function(a,b,c,d){if(null==a)return null;switch(typeof a){case "string":return $CLJS.WB(a,c)?(a=FC(this,a),b&&b.write(a,c),b=a):b="^"===a.charAt(0)&&" "!==a.charAt(1)?b.read(a,c):FC(this,a),b;case "object":if((0,$CLJS.VB)(a))if("^ "===a[0])if(this.Tb)if(17>a.length&&this.Tb.fromArray){d=[];for(c=1;c<a.length;c+=2)d.push(this.decode(a[c],b,!0,!1)),d.push(this.decode(a[c+1],b,!1,!1));b=this.Tb.fromArray(d,a)}else{d=this.Tb.init(a);for(c=1;c<a.length;c+=2)d=this.Tb.add(d,this.decode(a[c],
b,!0,!1),this.decode(a[c+1],b,!1,!1),a);b=this.Tb.finalize(d,a)}else{d=[];for(c=1;c<a.length;c+=2)d.push(this.decode(a[c],b,!0,!1)),d.push(this.decode(a[c+1],b,!1,!1));b=$CLJS.vC(d,!1)}else b=EC(this,a,b,c,d);else{c=(0,$CLJS.UB)(a);var e=c[0];if((d=1==c.length?this.decode(e,b,!1,!1):null)&&d instanceof CC)a=a[e],c=this.jb[d.Bb],b=null!=c?c(this.decode(a,b,!1,!0),this):$CLJS.hC(d.Bb,this.decode(a,b,!1,!1));else if(this.Tb)if(16>c.length&&this.Tb.fromArray){var f=[];for(d=0;d<c.length;d++)e=c[d],f.push(this.decode(e,
b,!0,!1)),f.push(this.decode(a[e],b,!1,!1));b=this.Tb.fromArray(f,a)}else{f=this.Tb.init(a);for(d=0;d<c.length;d++)e=c[d],f=this.Tb.add(f,this.decode(e,b,!0,!1),this.decode(a[e],b,!1,!1),a);b=this.Tb.finalize(f,a)}else{f=[];for(d=0;d<c.length;d++)e=c[d],f.push(this.decode(e,b,!0,!1)),f.push(this.decode(a[e],b,!1,!1));b=$CLJS.vC(f,!1)}}return b}return a};$CLJS.DC.prototype.decode=$CLJS.DC.prototype.decode;